import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { PhieuXuat, ThongTinThuoc } from "../models/XuatKhoModel";
import { formatDateToDDMMYYYY, formatDateAdvanceToString, formatMoney } from "../../utils/FormatUtils";
import { EXPORT_CODE } from "./constantsXuatKho";
import {DanhSachPhieuInterface} from "../../phan-he-nhap-kho/models/NhapKhoModel";

export const DSPhieuXuatKhachHangColumns: ReadonlyArray<Column<PhieuXuat>> = [
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"STT"}
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="TT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "custom",
    Cell: ({ ...props }) => (
      <div className="text-center">
        <i className="bi bi-circle-fill" style={{ color: props.data[props.row.index]?.color }}></i>
      </div>
    )
    ,
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Ngày lập"}
        className=" text-white text-center align-middle bg-pri min-w-100px"
      />
    ),
    id: "doCreation",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={formatDateAdvanceToString(props.data[props.row.index].doCreation)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Mã phiếu"}
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "code",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Loại phiếu"}
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={"Xuất lẻ"}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Kho"}
        className=" text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "name",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={props.data[props.row.index]?.mateWhName}
        className="text-left"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Tên BN"}
        className=" text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "patientName",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.patientName}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Giới tính"}
        className=" text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "patientGender",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={
          props.data[props.row.index]?.patientGenderName ??
            props.data[props.row.index]?.patientGender === 'G001' ? 'Nam' : 'Nữ'
        }
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Tuổi"}
        className=" text-center text-white align-middle bg-pri min-w-90px"
      />
    ),
    id: "age",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={props.data[props.row.index]?.age}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Thành tiền"}
        className=" text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "amount",
    Cell: ({ ...props }) => {
      const data = props.data[props.row.index];
      return (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.amount}
        />
      )
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Số tiền nợ"}
        className=" text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "notCollected",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.notCollected || props.data[props.row.index]?.amount}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Nội dung"}
        className=" text-center text-white align-middle bg-pri min-w-300px"
      />
    ),
    id: "notes",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.notes}
      />
    ),
  },
];
export const DSPhieuXuatNhaCungCapColumns: ReadonlyArray<Column<PhieuXuat>> = [
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="STT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="TT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "custom",
    Cell: ({ ...props }) => (
      <div className="text-center">
        <i className="bi bi-circle-fill" style={{ color: props.data[props.row.index]?.color }}></i>
      </div>
    )
    ,
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Ngày tạo"}
        className="text-white text-center align-middle bg-pri min-w-150px"
      />
    ),
    id: "doCreation",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={formatDateAdvanceToString(props.data[props.row.index].doCreation)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Mã phiếu"}
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "code",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Loại phiếu"}
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={"Xuất NCC"}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Phòng chỉ định"}
        className="text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "mateWhName",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={props.data[props.row.index]?.mateWhName}
        className="text-left"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Nhà cung cấp"
        className="text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "supplierName",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.supplierName}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Số hóa đơn"
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "patientName",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-right"
        data={props.data[props.row.index]?.invoiceNum}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Ngày hóa đơn"
        className="text-center text-white align-middle bg-pri min-w-120px"
      />
    ),
    id: "patientGender",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center s-2"
        data={formatDateToDDMMYYYY(props.data[props.row.index]?.invoiceDate)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Ghi chú"
        className="text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "content",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.content}
      />
    ),
  },
];
export const DSPhieuXuatKhacColumns: ReadonlyArray<Column<PhieuXuat>> = [
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="STT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="TT"
        className="text-center text-light max-w-100"
      />
    ),
    id: "custom",
    Cell: ({ ...props }) => (
      <div className="text-center">
        <i className="bi bi-circle-fill" style={{ color: props.data[props.row.index]?.color }}></i>
      </div>
    )
    ,
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Ngày tạo"}
        className="text-white text-center align-middle bg-pri max-w-100"
      />
    ),
    id: "doCreation",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={formatDateAdvanceToString(props.data[props.row.index].doCreation)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Mã phiếu"}
        className="text-center text-white align-middle bg-pri sapces w-10"
      />
    ),
    id: "code",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Nội dung"
        className="text-center text-white align-middle bg-pri min-w-500px"
      />
    ),
    id: "content",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.content}
      />
    ),
  },
];
export const DSPhieuXuatHuyColumns: ReadonlyArray<Column<PhieuXuat>> = [
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="STT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="TT"
        className="text-center text-light max-w-100"
      />
    ),
    id: "custom",
    Cell: ({ ...props }) => (
      <div className="text-center">
        <i className="bi bi-circle-fill" style={{ color: props.data[props.row.index]?.color }}></i>
      </div>
    )
    ,
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Ngày tạo"}
        className="text-white text-center align-middle bg-pri max-w-100"
      />
    ),
    id: "doCreation",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={formatDateAdvanceToString(props.data[props.row.index].doCreation)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Mã phiếu"}
        className="text-center text-white align-middle bg-pri sapces w-10"
      />
    ),
    id: "code",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Nội dung"
        className="text-center text-white align-middle bg-pri min-w-500px"
      />
    ),
    id: "content",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.content}
      />
    ),
  },
];
export const DanhSachXuatThuocColumns: ReadonlyArray<Column<ThongTinThuoc>> = [
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"STT"}
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Mã thuốc"}
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "maThuoc",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index]?.item?.code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Tên thuốc"}
        className=" text-white text-center align-middle bg-pri min-w-200px"
      />
    ),
    id: "tenThuoc",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.item?.name}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Số lượng"}
        className=" text-white text-center align-middle bg-pri min-w-50px"
      />
    ),
    id: "soLuong",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-end"
        data={formatMoney(parseFloat(props.data[props.row.index]?.totalQuantity || "0"))}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Đơn vị"}
        className="text-center text-white align-middle bg-pri min-w-50px"
      />
    ),
    id: "donViTinh",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={props.data[props.row.index]?.item?.unitofMeasure}
        className=""
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"SL khả dụng"}
        className=" text-center text-white align-middle bg-pri min-w-50px"
      />
    ),
    id: "soLuongKhaDung",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={props.data[props.row.index]?.soLuongKhaDung}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Giá nhập"}
        className=" text-center text-white align-middle bg-pri min-w-50px"
      />
    ),
    id: "giaNhap",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-end"
        data={formatMoney(parseFloat(props.data[props.row.index]?.item?.hospitalFeePrice || "0"))}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"% VAT"}
        className=" text-center text-white align-middle bg-pri min-w-50px"
      />
    ),
    id: "phanTramVAT",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={props.data[props.row.index]?.phanTramVAT}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Thành tiền"}
        className="text-center text-white align-middle bg-pri min-w-75px"
      />
    ),
    id: "thanhTien",
    Cell: ({ ...props }) => {
      let data = props.data[props.row.index] || {};
      let vat = parseFloat(data.item?.vat || 0) * parseFloat(data.item?.hospitalFeePrice || 0) / 100
      let total = vat + (parseFloat(data.totalQuantity || "0") * parseFloat(data.item?.hospitalFeePrice || 0))
      return (
        <TableCustomCell
          className="s-2 text-end"
          data={formatMoney(total)}
        />
      )
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Số ĐK"}
        className="text-center text-white align-middle bg-pri min-w-75px"
      />
    ),
    id: "soDK",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={props.data[props.row.index]?.soDK}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Số lô"}
        className="text-center text-white align-middle bg-pri min-w-75px"
      />
    ),
    id: "consignment",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={props.data[props.row.index]?.consignment}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<ThongTinThuoc>
        tableProps={props}
        title={"Hạn sử dụng"}
        className="text-center text-white align-middle bg-pri min-w-50px"
      />
    ),
    id: "expiryDate",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={props.data[props.row.index]?.expiryDate}
      />
    ),
  },
];

export const DSPhieuXuatHaoPhiColumns: ReadonlyArray<Column<PhieuXuat>> = [
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="STT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="TT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "custom",
    Cell: ({ ...props }) => (
      <div className="text-center">
        <i className="bi bi-circle-fill" style={{ color: props.data[props.row.index]?.color }}></i>
      </div>
    )
    ,
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Ngày tạo"}
        className="text-white text-center align-middle bg-pri min-w-150px"
      />
    ),
    id: "doCreation",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={formatDateAdvanceToString(props.data[props.row.index].doCreation)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Mã phiếu"}
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "code",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Loại phiếu"}
        className="text-center text-white align-middle bg-pri min-w-100px"
      />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data="Xuất hao phí khoa/phòng"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Nơi lập phiếu"
        className="text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "mateWhName",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.mateWhName}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Nội dung"
        className="text-center text-white align-middle bg-pri min-w-200px"
      />
    ),
    id: "content",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.content}
      />
    ),
  },
];

export const DSPhieuXuatBaoHiemColumns: ReadonlyArray<Column<PhieuXuat>> = [
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="STT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="TT"
        className="text-center text-light max-w-100"
      />
    ),
    id: "custom",
    Cell: ({ ...props }) => (
      <div className="text-center">
        <i className="bi bi-circle-fill" style={{ color: props.data[props.row.index]?.color }}></i>
      </div>
    )
    ,
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Ngày tạo"}
        className="text-white text-center align-middle bg-pri max-w-100"
      />
    ),
    id: "doCreation",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2 text-center"
        data={formatDateAdvanceToString(props.data[props.row.index].doCreation)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Mã phiếu"}
        className="text-center text-white align-middle bg-pri sapces w-10"
      />
    ),
    id: "code",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].code}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Mã bệnh nhân"}
        className="text-center text-white align-middle bg-pri sapces w-10"
      />
    ),
    id: "patientCode",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].patientCode}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title={"Tên bệnh nhân"}
        className="text-center text-white align-middle bg-pri sapces w-10"
      />
    ),
    id: "patientName",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className=" s-2"
        data={props.data[props.row.index].patientName}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<PhieuXuat>
        tableProps={props}
        title="Kho duyệt"
        className="text-center text-white align-middle bg-pri min-w-300px"
      />
    ),
    id: "mateWhName",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.mateWhName}
      />
    ),
  },
];

export const DanhSachHoanTra: ReadonlyArray<
    Column<DanhSachPhieuInterface>
> = [
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="STT"
            className="text-center text-light max-w-35"
        />
    ),
    id: "stt",
    Cell: ({...props}) => (
        <TableCustomCell
            className="text-center "
            data={String(props?.row?.index + 1)}
        />
    ),
  },
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="TT"
            className="text-center text-light max-w-35"
        />
    ),
    id: "custom",
    Cell: ({...props}) => (
        <div className="text-center">
          <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
        </div>
    )
    ,
  },
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="Ngày tạo"
            className="text-center text-white align-middle bg-pri min-w-100px"
        />
    ),
    id: "doCreation",
    Cell: ({...props}) => (
        <TableCustomCell
            className="text-center s-2"
            data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
        />
    ),
  },
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="Mã phiếu"
            className="text-center text-white align-middle bg-pri min-w-80px"
        />
    ),
    id: "code",
    Cell: ({...props}) => (
        <TableCustomCell
            className="text-left s-2"
            data={props.data[props.row.index]?.code}
        />
    ),
  },
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="Ngày y lệnh"
            className="text-center text-white align-middle bg-pri min-w-100px"
        />
    ),
    id: "loaiPhieu",
    Cell: ({...props}) => (
        <TableCustomCell
            className="text-center s-2"
            data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
        />
    ),
  },
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="Ngày lập"
            className="text-center text-white align-middle bg-pri min-w-200px"
        />
    ),
    id: "receWarehouseName",
    Cell: ({...props}) => (
        <TableCustomCell
            className="text-left s-2"
            data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
        />
    ),
  },
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="Khoa lập phiếu"
            className="text-center text-white align-middle bg-pri min-w-200px"
        />
    ),
    id: "department",
    Cell: ({...props}) => (
        <TableCustomCell
            className="s-2"
            data={props.data[props.row.index]?.content}
        />
    ),
  },
  {
    Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
            tableProps={props}
            title="Nội dung"
            className="text-center text-white align-middle bg-pri min-w-200px"
        />
    ),
    id: "content",
    Cell: ({...props}) => (
        <TableCustomCell
            className="s-2"
            data={props.data[props.row.index]?.content}
        />
    ),
  },
];

export const EXPORT_COLUMNS = {
  [EXPORT_CODE.NCC]: DSPhieuXuatNhaCungCapColumns,
  [EXPORT_CODE.KHACH_HANG]: DSPhieuXuatKhachHangColumns,
  [EXPORT_CODE.KHAC]: DSPhieuXuatKhacColumns,
  [EXPORT_CODE.HUY]: DSPhieuXuatHuyColumns,
  [EXPORT_CODE.HAO_PHI]: DSPhieuXuatHaoPhiColumns,
  [EXPORT_CODE.XUAT_HOA_DON]: DSPhieuXuatBaoHiemColumns,
  [EXPORT_CODE.HOAN_TRA]: DanhSachHoanTra,
};