import { localStorageItem } from '../../../../app/modules/utils/LocalStorage';


export interface SearchObject {
    pageIndex: number;
    pageSize: number;
    keyword?: string;
    type?: string;
    id?: string;
}
export type PhieuXuat = {
    code?: string;
    address?: string;
    bidPackage?: any;
    createdDate?: any;
    amount?: any;
    patientGender?: any;
    patientGenderName?: string;
    age?: any;
    notes?: any;
    collected?: any;
    id?: string;
    regisNum?: any;
    notCollected?: any;
    name?: any;
    patientCode?: any;
    doCreation?: any;
    exportDate?: any;
    khoaLapPhieu?: string;
    phong?: string
    nguoiLap?: string;
    content?: string;
    patientName?: string;
    dsThuoc?: any;
    phieuXuatKhoAdd: any;
    color?: string;
    supplierName?:string;
    suppName?: string;
    invoiceNum?: string;
    invoiceDate?: string;
    mateWhName?: string;
}

export type ThongTinThuoc = {
    id?: string;
    fakeID?: string;
    maThuoc?: string;
    tenThuoc?: string;
    totalQuantity?: string;
    donViTinh?: string;
    soLuongKhaDung?: string;
    hospitalFeePrice?: string;
    phanTramVAT?: string;
    thanhTien?: string;
    soDK?: string;
    consignment?: string;
    expiryDate?:string;
    item: any;
}
const dataUser = localStorageItem.get("current-user") ? localStorageItem.get("current-user") : {};
export const initialPhieuXuat: PhieuXuat = {
    code: "",
    doCreation:"",
    khoaLapPhieu: "",
    phong: "",
    nguoiLap: "",
    content: "",
    dsThuoc: [],
    phieuXuatKhoAdd: {
        mateWh: "DR01",
        mateWhId: 32,
        mateWhName: "Kho nhà Thuốc",
        doCreation: new Date(),
        creator: dataUser?.sub,
        patientName: '',
        age: '',
        guardian: '',
        guardianPhoneNumber: '',
        patientGender: '',
        invoiceDate: '',
        supplier:'',
        suppName: '',
        content: '',
    },
}

export const initialThongTinThuoc: ThongTinThuoc = {
    maThuoc: "",
    tenThuoc: "",
    totalQuantity: "",
    donViTinh: "",
    soLuongKhaDung: "",
    hospitalFeePrice: "",
    phanTramVAT: "",
    thanhTien: "",
    soDK: "",
    consignment: "",
    expiryDate: "",
    item: {},
}