import axios from 'axios';


function wfAxiosGenerator(baseEndpoint : string, configString: string) {
  function convertToJSON(configString : string) {
    try {
      const jsonObject = JSON.parse(configString);
      return jsonObject;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  }

  const getValueFromTemplate = (keyStr: string, props: any) => {
    return keyStr.split('.').reduce((acc, currKey) => acc?.[currKey], props);
  };

  const fillTemplate = (templates: any, props: any) => {
    const result: Record<string, any> = {};

    templates?.forEach((template: any) => {
      const [key, value] = template.includes('=') ? 
        template.split('=') : 
        template.split('-');

      result[key] = template.includes('=') ? value : getValueFromTemplate(value, props);
    });

    return result;
  };

  const replaceTemplateValues = (template: any, data: any): any => {
    if (typeof template === 'string' && template.includes("${")) {
      return template.replace(/\${(.*?)}/g, (_: any, key: any) => {
        const value = getValueFromTemplate(key, data);
        return value !== undefined ? value : '';
      });
    } else if (Array.isArray(template)) {
      return template.map((item) => replaceTemplateValues(item, data));
    } else if (typeof template === 'object' && template !== null) {
      const result: Record<string, any> = {};
      Object.entries(template).forEach(([key, value]) => {
        result[key] = replaceTemplateValues(value, data);
      });
      return result;
    }
    return template;
  };

  const replaceUrlTemplates = (template: any, data: any) => {
    return template.replace(/\${(.*?)}/g, (_: any, key: any) => {
      const value = getValueFromTemplate(key, data);
      return value !== undefined ? value : '';
    });
  };

  const generateAxiosRequest = (buttonConfig: any) => {
    const { code, name, componentName, method, url, payloadTemplate, paramsTemplate, pathVariables } = buttonConfig;

    const handleClick = async (props:any) => {
      try {
        const payload = Array.isArray(payloadTemplate) ? fillTemplate(payloadTemplate, props) : replaceTemplateValues(payloadTemplate, props);
        const params = replaceTemplateValues(paramsTemplate, props); // Chưa cần sử dụng
        let finalUrl = url;
        if (pathVariables) {
          for (const [key, value] of Object.entries(pathVariables)) {
            finalUrl = finalUrl.replace(`{${key}}`, value);
          }
        }

        if (typeof method === 'string' && "POST".toLowerCase() === method.toLowerCase()) {
          await axios.post(`${baseEndpoint}${finalUrl}`, payload);
        }
        if (typeof method === 'string' && "PUT".toLowerCase() === method.toLowerCase()) {
          await axios.put(`${baseEndpoint}${finalUrl}`, payload);
        }

        if (buttonConfig.actions) {
          const lstActions = generateActions(buttonConfig);
          const actions = lstActions?.[code];
          if (actions) {
            actions.forEach((func: (props: any) => void) => func(props));
          }
        }
        
      } catch (error) {
        console.error(`[${code}] ${name} - Error:`, error);
      }
    };
   
    return { code, name, componentName, handleClick };
  };

  const generateActions = (actionsConfig: any) => {
    let { code, actions } = actionsConfig;
    const newActions: any[] = [];
  
    for (const action of actions) {
      const { method, url, payloadTemplate, pathVariables } = action;
      const handleClick = async (props: any) => {
        let response;
        try {
          let finalUrl = url;
          let payload = Array.isArray(payloadTemplate) ? fillTemplate(payloadTemplate, props) : replaceTemplateValues(payloadTemplate, props);
  
          if (pathVariables) {
            for (const pathInfo of pathVariables) {
              const pathKey = pathInfo.split('-');
              finalUrl = finalUrl.replace(`{${pathKey[0]}}`, getValueFromTemplate(pathKey[1], props));
            }
          } else {
            finalUrl = replaceUrlTemplates(url, props);
          }
          
          if (typeof method === 'string' && method.toLowerCase() === "post") {
            response = await axios.post(`${baseEndpoint}${finalUrl}`, payload);
          }
          
          if (typeof method === 'string' && method.toLowerCase() === "put") {
            response = await axios.put(`${baseEndpoint}${finalUrl}`, payload);
          }
          return response?.data;
        } catch (error) {
          console.error(`[${code}] - Error:`, error);
        }
      };
  
      newActions.push(handleClick);
    }
    return { [code]: newActions };
  };  

  if (convertToJSON(configString)) {
    const axiosRequests = convertToJSON(configString)?.additionalButton?.map(generateAxiosRequest);
    let actions = {};
    convertToJSON(configString)?.additionalAction?.forEach((actionConfig: any) => {
      const action = generateActions(actionConfig);
      actions = { ...actions, ...action}
    });
    return { axiosRequests, actions };
  }
}

export default wfAxiosGenerator; 