import axios from "axios";
import { IInPhieuParams, IParamsSearchLocation, IParamsSimpleCategory, IParamsTermClasses } from "../models/params";
import { CODE, DEFAULT_PAGE_INDEX, INDEX_DB_NAME, SEARCH_OBJECT_MAX_SIZE } from "./Constant";
import { localStorageItem } from '../../modules/utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../modules/auth/core/_consts";
import { addIndexedDBItem, getIndexedDBItem } from "./IndexedDB";

const API_PATH_EMR = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_PATH_TERM_CLASSES = API_PATH_EMR + "/term-classes";
const API_PATH_CATEGORY = process.env.REACT_APP_XADMIN_API_URL + "/simple-categories";
const API_PATH_PROVINCES = process.env.REACT_APP_XADMIN_API_URL + "/provinces";
const API_PATH_DISTRICTS = process.env.REACT_APP_XADMIN_API_URL + "/districts";
const API_PATH_SUB_DISTRICTS = process.env.REACT_APP_XADMIN_API_URL + "/subdistricts";

export const getSimpleCategory = (params: IParamsSimpleCategory) => {
    let url = API_PATH_CATEGORY + "/search";
    return axios.get(url, { params: {
        ...params,
        ...SEARCH_OBJECT_MAX_SIZE
    } });
};

export const getTermByClassId = (params: {
    classId: number
}) => {
    let url = API_PATH_EMR + "/terms";
    return axios.get(url, { params });
};

export const getTermClasses = (params: IParamsTermClasses) => {
    let url = API_PATH_TERM_CLASSES + "/terms";
    return axios.get(url, { params });
};

export const getAllProvinces = () => {
    const MAX_PAGE_SIZE_PROVINCE = 63;
    let params = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE_PROVINCE
    };
    let url = API_PATH_PROVINCES + "/search";
    return axios.get(url, { params });
};

export const getAllDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const getAllSubDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_SUB_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const getDataByCategory = (classId: number) => {
    let url = `${API_PATH_EMR}/category`;
    return axios.get(url, { params: { classId } });
};

export const getListWarehouse = async () => {
    const key = INDEX_DB_NAME.LST_WAREHOUSE;
    let url = `${API_PATH_EMR}/category/warehouses`;

    try {
        // Kiểm tra dữ liệu trong IndexedDB
        const cachedData = await getIndexedDBItem(key);                
        if (cachedData) {
            return cachedData;
        }

        // Nếu không có, gọi API
        const { data } = await axios.get(url, { params: { level: 1 } });
        if (CODE.SUCCESS === data.code) {
            // Lưu dữ liệu vào IndexedDB
            await addIndexedDBItem(key, data.data.content);
            return data.data.content;
        }

        return [];
    } catch (error) {
        console.error(error);
        return [];
    }
};
