import React, { useCallback } from 'react';
import { FunctionComponent, memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getWfWorkflows, updateWfWorkflows } from "../services/WfWorkflowsService";
import { CODE, RESPONSE_MESSAGE, TYPE_AUTHOR, WF_TRACKER } from "../../utils/Constant";
import { toast } from "react-toastify";
import { WfWorkflowsReq, WfWorkflowsRes } from "../models/WfWorkflows";
import wfAxiosGenerator from "../utils/WfAxiosGenerator";
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';

interface WfAction {
  componentName: string,
  reqName?: string,
  code?: string,
  submit: () => void,
}
interface WfProps {
  tracker: string,
  objectId: string,
  additionalFunc?: (data?: any) => void,
  handleClick?: (props: WfAction) => void;
  additionalProps?: any;
  setLoading?: (loading: boolean) => void;
  type?: number, // chỉ dùng với Bổ sung cơ số tủ trực 1 tủ trực 2 kho xuất
}

const BASE_ENDPOINT = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL || "";

const WfButton: FunctionComponent<WfProps> = memo((wfProps: WfProps) => {
  const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.authorities);
  const [wfWorkflowsRes, setWfWorkflowsRes] = useState<any>([]);
  const updatePageData = useCallback(async () => {
    try {
      let { data } = await getWfWorkflows(wfProps.tracker, wfProps.objectId, wfProps.type || 0);
      if (data?.code === CODE.SUCCESS) {
        setWfWorkflowsRes(data?.data);
        wfProps.additionalFunc &&
          wfProps.additionalFunc({
            currCode: data?.data[0]?.currStatusCode,
            currId: data?.data[0]?.currStatusId,
            firstTime: true,
          });
      }
    } catch (e) {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    }
  }, [wfProps.tracker, wfProps.objectId]);

  useEffect(() => {
    wfProps.tracker && wfProps.objectId && updatePageData().catch(console.error);
  }, [wfProps.tracker, wfProps.objectId, updatePageData]);

  const changeStatus = async (wfWorkflowsReq: WfWorkflowsReq | undefined) => {
    if (!wfWorkflowsReq) return;
    let { data } = await updateWfWorkflows(wfProps.tracker, wfWorkflowsReq);
    setWfWorkflowsRes(data?.data || []);
  };

  const handleButtonClick = (additionalButton: any) => {
    const submit = () => {
      additionalButton.handleClick(wfProps);
      wfProps.additionalFunc?.();
    };
    if (additionalButton?.componentName && wfProps.handleClick) {
      wfProps.handleClick({
        code: additionalButton.code,
        componentName: additionalButton.componentName,
        submit: submit,
      });
    } else {
      submit();
    }
  };

  const handleReq = (workFlow: WfWorkflowsRes, wfAxiosRequests: any) => {
    try {
      wfProps.setLoading && wfProps.setLoading(true);
      const actions = wfAxiosRequests?.actions?.[workFlow.newStatusCode];
      const config = JSON.parse(workFlow.additionalConfiguration || "{}");

      const submit = async () => {
        if (actions) {
          for (const func of actions) {
            const data = await func(wfProps);
            if (CODE.SUCCESS !== data?.code) {
              toast.error(data?.message);
              return data?.code;
            };
          }
        }
        await changeStatus({
          objectId: wfProps.objectId,
          status: workFlow.newStatusId,
          type: wfProps.type,
        });        
        if (wfProps.additionalFunc) wfProps.additionalFunc({ currCode: workFlow.newStatusCode, currId: workFlow.newStatusId });
      };

      if (config?.componentNameReq && wfProps.handleClick) {
        wfProps.handleClick({
          componentName: config.componentNameReq,
          reqName: workFlow.newStatusName,
          submit: submit,
        });
      } else {
        submit();
      }
    } catch (error) {
      console.error(error);
    } finally {
      wfProps.setLoading && wfProps.setLoading(false);
    }
  };

  return (
    <>
      {(Array.isArray(wfWorkflowsRes) ? wfWorkflowsRes : [])?.map((workFlow: any) => {
        if (!workFlow.author || currentUser[workFlow.author]) {
          const wfAxiosRequests = wfAxiosGenerator(BASE_ENDPOINT, workFlow.additionalConfiguration);
          return (
            <React.Fragment key={workFlow.newStatusId}>
              {wfAxiosRequests && wfAxiosRequests.axiosRequests && wfProps.objectId && wfAxiosRequests.axiosRequests.map((additionalButton: any) => (
                <Button
                  className="spaces min-w-120 mx-3 btn btn-fill"
                  key={additionalButton.code}
                  onClick={() => handleButtonClick(additionalButton)}
                >
                  {additionalButton.name}
                </Button>
              ))}
              {workFlow.newStatusName && (
                <Button
                  className="min-w-120 spaces mx-3 btn btn-fill"
                  onClick={() => handleReq(workFlow, wfAxiosRequests)}
                >
                  <span>{workFlow?.newStatusName}</span>
                </Button>
              )}
            </React.Fragment>
          );
        };

        return <></>
      }
      )}
    </>
  );
});

export default WfButton;
