import { Formik, Form } from "formik";
import { Button, Modal } from "react-bootstrap";
import GenerateFormComponent from "../generate-form/GenerateFormHTMLNoValid";
import { useContext, useEffect, useState } from "react";
import { generateForm, generateInitValues, generateValidationSchema } from "../../utils/AppFunction";
import { AppContext } from "../../appContext/AppContext";

interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  idForm: string;
  handleSearch: (data: any) => void;
  downloadReport?: boolean; 
  messageValidDownloadReport?: string;
  handleDownloadReport?: (values?: any) => void;
}

const AdvancedSearch = (props: Props) => {
  const {
    show,
    onHide,
    initialValues,
    idForm,
    handleSearch,
    downloadReport = false,
    messageValidDownloadReport = "Chọn đủ các trường bắt buộc để tải báo cáo",
    handleDownloadReport
  } = props;

  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const { setIsLoading } = useContext(AppContext);
  const [dataSearch, setDataSearch] = useState(initialValues);

  const handleAdvancedSearch = (values: any) => {
    handleSearch(values[idForm]);
    onHide();
  }

  const updateForm = async () => {
    const data = await generateForm(idForm, setIsLoading);
    setListJsonGenerate(data || []);
  };

  useEffect(() => {
    idForm && updateForm();
  }, [idForm]);

  const handleSubmit = (values: any) => {
    handleDownloadReport && handleDownloadReport(values[idForm]);
  };

  useEffect(() => {
    const newData = {...generateInitValues(listJsonGenerate), ...initialValues};
    setDataSearch(newData);
  }, [listJsonGenerate, initialValues]);

  return (
    <Modal centered show={show} onHide={onHide} size="lg" className="spaces">
      <Formik
        enableReinitialize
        initialValues={{ [idForm]: dataSearch }}
        validationSchema={generateValidationSchema(listJsonGenerate, idForm)}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Header className="title">Tìm kiếm nâng cao</Modal.Header>
              <Modal.Body className="spaces pb-24">
                <GenerateFormComponent
                  values={values}
                  setFieldValue={setFieldValue}
                  listFieldAuto={listJsonGenerate}
                  modelID={idForm}
                  touched={touched}
                  errors={errors}
                />
              </Modal.Body>
              <Modal.Footer>
                {downloadReport && (<>
                  <span className="text-italic absolute left-20">{messageValidDownloadReport}</span>
                  <Button type="submit" className="btn btn-fill">Tải báo cáo</Button>
                </>)}
                <Button onClick={() => handleAdvancedSearch(values)} className="btn btn-fill">Tìm kiếm</Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AdvancedSearch;
