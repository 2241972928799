import clsx from "clsx"
import "./CustomIconButton.scss"

type Props = {
  children: JSX.Element
  onClick: () => void
  variant: "delete" | "edit" | "print"
  disabled?: boolean
  tooltip?: string
  hideTooltip?: boolean
}

const CustomIconButton = ({ children, onClick, variant, disabled, tooltip, hideTooltip = false }: Props) => {
  const tooltipText =
    tooltip ||
    (variant === "delete"
      ? "Xóa"
      : variant === "edit"
      ? "Sửa"
      : variant === "print"
      ? "In phiếu"
      : null);

  return (
    <div className="tooltip-container">
      <div
        className={clsx("border-0 bg-transparent px-0", variant, { "disabled-icon": disabled })}
        onClick={() => {
          if (disabled) return;
          onClick();
        }}
      >
        {children}
      </div>
      {(tooltipText && !hideTooltip) && <span className="tooltip-text text-navy">{tooltipText}</span>}
    </div>
  )
}

export default CustomIconButton;
