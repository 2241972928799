import { Formik } from "formik";
import CustomTabMenu from "../component/CustomTabMenu";
import { danhSachTabKhoBaoHiem } from "./Constant";

export const KhoBaoHiem: React.FC = () => {

  return (
    <Formik<any> initialValues={{}} onSubmit={() => { }}>
      <CustomTabMenu
        danhsachTabs={danhSachTabKhoBaoHiem}
        className="spaces pl-0"
      />
    </Formik>
  );
};

export default KhoBaoHiem;
