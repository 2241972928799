export const listModule = [
    {
        name: "Thống kê",
        to: "/system/warehouse/statistics",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    // {
    //     name: "Quản lý thầu",
    //     to: "/quan-ly-thau",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    // {
    //     name: "Nhập kho",
    //     to: "/phan-he-nhap-kho",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    // {
    //     name: "Xuất kho",
    //     to: "/phan-he-xuat-kho",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    // {
    //     name: "Điều chuyển kho",
    //     to: "/phan-he-dieu-chuyen-kho",
    //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
    // },
    {
        name: "Kho vật tư",
        to: "/system/warehouse/materials-storage",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Kho nhà thuốc",
        to: "/system/warehouse/pharmacy",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Nhà thuốc - thu ngân",
        to: "/system/warehouse/cashier",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Thẻ kho",
        to: "/system/warehouse/card",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Tủ trực",
        to: "/system/warehouse/cabinet",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Kiểm kê",
        to: "/system/warehouse/stocktaking",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
    {
        name: "Danh mục",
        to: "/system/warehouse/catalog",
        imgSrc: "media/svg/dashboard-icons/appointment.svg"
    },
];