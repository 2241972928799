import axios from "axios";
import { localStorageItem } from "../../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../../auth/core/_consts";
import { Slip } from "../models/XuatSuDungModel";
import { toast } from "react-toastify";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_XUAT_SU_DUNG = `${API}/slip/themPhieuXuatSuDung`;

export const searchPhieuTuTruc = (searchData: any) => {
  const url = `${API}/slip/search`;
  return axios.post(url, searchData);
};

export const getDsVatTuPhieu = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};

export const getListMedicine = (searchData: any) => {
  const url = `${API}/slip/search-detail`
  return axios.post(url, searchData);
};

export const createPhieuXuatSuDung = (data: any) => {
  const url = API_XUAT_SU_DUNG;
  return axios.post(url, data);
};

export const updatePhieuXuatSuDung = (id: string, data: any) => {
  const url = API_XUAT_SU_DUNG + `/${id}`;
  return axios.put(url, data);
};

export const updateSlPhat = (data: any, callback: () => {}) => {
  if(!Object.keys(data).length) return;
  const url = `${API}/slip/update-quantity-accept`;
  return axios.put(url, data).then(() => {callback && callback()}).then(() => toast.success("Lưu thành công!"));
}

export const printPhieu = (params: any) => {
  const url = `${API}/api/download/cabinet/word/export-for-use`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};
