import React, { useContext } from "react";
import FilterSidebar from "../../component/filter-sidebar/FilterSidebar";
import LazyLoadTable from "../../component/table/lazy-load-table/LazyLoadTable";
import { formatDateDTO,formatDateFromDTOCustom,formatDateToDTOCustom } from "../../utils/FormatUtils";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { DSBenhNhanColumns } from "../const/ThuNganColumns";
import { TYPE_VT } from "../const/ThuNganConstant";
import { searchDSBenhNhan } from "../services/PhanHeThuNganServices";
import { AppContext } from "../../appContext/AppContext";
import "../PhanHeThuNgan.scss";

interface Props {
  searchData: any;
  setSearchData: React.Dispatch<any>;
  summaryStatus: any;
  handleRefreshData: (row: any, isUpdateSummaryStatus?: boolean, isUpdateDsBenhNhan?: boolean) => void;
  selectedRow?: any;
}

const TableDanhSachBenhNhan = (props: Props) => {
  const { searchData, setSearchData, handleRefreshData, summaryStatus, selectedRow } = props;
  const { setIsLoading } = useContext(AppContext);
  const TYPE_SEARCH = [
    {
      type: TYPE_VT.THUOC,
      value: TYPE_VT.THUOC,
      label: "Thuốc",
    },
    {
      type: TYPE_VT.VAT_TU,
      value: TYPE_VT.VAT_TU,
      label: "Vật tư",
    },
  ];

  const handleSearch = (data: any) => {
    const convertData = {
      keyword: data.keyword?.trim() || null,
      type: data.typeRadio,
      fromDate: formatDateFromDTOCustom(data.period?.fromDate) || null,
      toDate: formatDateToDTOCustom(data.period?.toDate) || null,
    };
    setSearchData(convertData);
  };

  const handleSelectedRow = (row: any, index: number | null) => {
    handleRefreshData(row);
  };

  return (
    <div className="bangDSBenhNhan position-relative spaces pt-10 gap-8">
      <div className="border-left-0 h-calc-300px">
        <FilterSidebar
          onSearch={handleSearch}
          formCheckList={TYPE_SEARCH}
          defaultValueFormCheckList={TYPE_VT.THUOC}
        />
        <LazyLoadTable
          searchData={searchData}
          columns={DSBenhNhanColumns}
          axiosReq={searchDSBenhNhan}
          className="spaces h-calc-vh-240"
          onRowClick={handleSelectedRow}
          idSelected={selectedRow?.id}
          refreshOnRowClick={true}
        />
      </div>
      <div className="d-flex flex-column spaces w-100 pb-3 gap-5 position-absolute bottom-15">
        <WfStatusSummary summaryStatus={summaryStatus || []} />
      </div>
    </div>
  );
};

export { TableDanhSachBenhNhan };

