import axios from "axios";
import { localStorageItem } from '../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;

export const searchVatTu = (searchData: any) => {
  let url =  `${API}/warehouse/card `;
  return axios.post(url, searchData);
};
export const searchListKho = (searchObject?: any) => {
  let url = API + `/category/warehouses?level=1`;
  return axios.get(url, searchObject);
};
export const inPhieuTheKho = (params: any) => {
  let url = `${API}/api/download/warehouse-card`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};