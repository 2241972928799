import { useFormikContext } from "formik";
import { Button, Form } from "react-bootstrap";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { useContext, useEffect, useState } from "react";
import { generateForm, roundToDecimalPlaces } from "../../utils/AppFunction";
import { AppContext } from "../../appContext/AppContext";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { PhanHeDieuChuyenContext } from "../PhanHeDieuChuyen";
import { CODE_DIEU_CHUYEN } from "../constants/constansDieuChuyen";

type Props = {
  generalValues?: any;
  indexRowEdit: number | null;
  isView: boolean;
  warehouseTransferType: any;
};

const FormThemVatTu = ({ generalValues, indexRowEdit, isView, warehouseTransferType }: Props) => {
  const { setIsLoading } = useContext(AppContext);
  const { dsDieuChuyen, setDsDieuChuyen } = useContext(PhanHeDieuChuyenContext);
  const {
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormikContext<any>();

  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);

  const handleTotalAmount = () => {
    const formData = values.vatTuDieuChuyen || {};
    const price = parseFloat(formData.sellingPrice || 0);
    const quantity = parseFloat(formData.totalQuantity || 0);
    const vat = parseFloat(formData.vat || 0);
    const result = price * quantity * (1 + vat / 100);

    const newFormData = {
      ...formData,
      totalAmount: roundToDecimalPlaces(result),
    };
    setFieldValue("vatTuDieuChuyen", newFormData);
  };

  useEffect(() => {
    handleTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.vatTuDieuChuyen?.totalQuantity]);

  const updateForm = async () => {
    const data = await generateForm(warehouseTransferType.formVatTu, setIsLoading);
    setListJsonGenerate(data || []);
  };

  useEffect(() => {
    updateForm();
  }, []);

  const handler = (values: any) => {
    setFieldValue("vatTuDieuChuyen", values);
  };

  useEffect(() => {
    setDsDieuChuyen([]);
  }, [generalValues.dieuChuyen?.transferWhId]);

  return (
    <Form onSubmit={handleSubmit}>
      <GenerateFormComponent
        isView={isView}
        listFieldAuto={listJsonGenerate}
        handleChange={handleChange}
        setValues={setValues}
        values={values}
        touched={touched}
        errors={errors}
        isIventoryNoZero={true}
        propsRenderButton={handler}
        lstSelectedRenderButton={dsDieuChuyen}
        setFieldValue={setFieldValue}
        modelID={"vatTuDieuChuyen"}
        DynamicClass={"pl-38"}
        customComponent={DynamicComponent}
        warehouseId={warehouseTransferType.code === CODE_DIEU_CHUYEN.NHAP_DIEU_CHUYEN ? generalValues.dieuChuyen?.transferWhId : generalValues.dieuChuyen?.reqWhId}
        isViewSelectThuoc={warehouseTransferType.code === CODE_DIEU_CHUYEN.NHAP_DIEU_CHUYEN ? !generalValues.dieuChuyen?.transferWhId : !generalValues.dieuChuyen?.reqWhId}
      />
      <div className="spaces mt-10">
        {!isView &&
          <Button className="btn btn-fill spaces min-w-90" onClick={e => handleSubmit(values)}>
            {indexRowEdit === null ? "Thêm thuốc/VT" : "Cập nhật thuốc/VT"}
          </Button>
        }
      </div>
    </Form>
  );
};

export default FormThemVatTu;
