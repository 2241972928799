import {ChangeEvent, useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {AppContext} from "../../../appContext/AppContext";
import InputSearch from "../../../component/InputSearch";
import {TableCustom} from "../../../component/table/table-custom/TableCustom";
import TextField from "../../../component/TextField";
import {
    CODE,
    DEFAULT_PAGE_INDEX,
    KEY,
    SELECTION_MODE,
    TYPE_WH,
} from "../../../utils/Constant";
import {DanhSachThongTinYLenh} from "../../constants/ColumnsNhapKho";
import "../../PhanHeNhapKho.scss";
import {getDsThongTinYLenh} from "../../services/services";

interface Props {
    LST_WAREHOUSE: any
    warehouseId: any,
    initialValues: any,
    show: boolean,
    onHide: () => void;
}

export const DialogThongTinYLenh = (props: Props) => {
    const {warehouseId, LST_WAREHOUSE, show, onHide, initialValues} = props;
    const [dsPhieuNhapKho, setDsPhieuNhapKho] = useState<any[]>([]);
    const {setIsLoading} = useContext(AppContext);
    const [searchData, setSearchData] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
    const currentWh = LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE];

    const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
        });
    };

    const updatePageData = async () => {
        try {
            setIsLoading(true);
            const {data} = await getDsThongTinYLenh({
                keyword: searchData?.keyword?.trim() || null,
                type: TYPE_WH.EXPORT,
                slipId: initialValues.nhapKho?.id
            });
            if (CODE.SUCCESS === data.code) {
                setDsPhieuNhapKho(data?.data?.content || []);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        updatePageData();
    }, [rowsPerPage, page]);

    const handlerSearchKeyWorld = () => {
        setPage(DEFAULT_PAGE_INDEX);
        setRowsPerPage(20);
        updatePageData()
    }

    return (
        <Modal
            centered
            show={show}
            // onHide={onHide}
            size="xl"
            fullscreen
            className="page-full spaces h-calc-vh-50"
        >
            <Modal.Header className="header-modal min-h-40px">
                <div className="d-flex w-100 gap-6 justify-content-between">
                    <div className="d-flex gap-8">
                        <div className="d-flex align-items-center title-back gap-3 cursor-pointer" onClick={onHide}>
                            <i className="spaces bi bi-chevron-left ml-10"></i>
                            <div className="spaces mr-20 my-3">Quay lại</div>
                        </div>
                        <div
                            className="spaces mt-4 title">{`Thông tin y lệnh`}</div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
                <div className="spaces px-10 pt-10 row mb-10">
                    <div className="col-lg-3">
                        <TextField
                            label="Tủ trực"
                            name="recieptStore"
                            type="text"
                            className="d-flex spaces width-100"
                            value={currentWh?.cabinetTypeName || ''}
                            labelClassName="spaces min-w-120 mt-4"
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            label="Kho nhận"
                            name="recieptStore"
                            type="text"
                            className="d-flex spaces width-100"
                            value={currentWh?.mateWhName || ''}
                            labelClassName="spaces min-w-120 mt-4"
                        />
                    </div>
                    <div className="flex-grow-1 bg-white mt-2">
                        <InputSearch
                            name="keyword"
                            value={searchData?.keyword || ''}
                            placeholder="Tìm kiếm"
                            handleChange={handleChangeSearchData}
                            handleSearch={() => handlerSearchKeyWorld()}
                            handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && handlerSearchKeyWorld()}
                            className="spaces h-31 pr-4 radius-3"
                        />
                    </div>
                </div>
                <div className="spaces bg-white mb-0">
                    <TableCustom<any>
                        className="spaces h-bang-ds-phieu"
                        data={dsPhieuNhapKho}
                        columns={DanhSachThongTinYLenh}
                        verticalScroll={true}
                        selectionMode={SELECTION_MODE.MULTI}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DialogThongTinYLenh;
