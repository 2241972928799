import * as Yup from "yup";
import { Form, Formik } from "formik";
import TableThongTinVT from "./TableThongTinVT";
import { AppContext } from "../../appContext/AppContext";
import { IconButtonSave } from "../../component/IconSvg";
import { Button, Col, Modal, Row } from "react-bootstrap";
import WfButton from "../../workflows/components/WfButton";
import { DuTruBoSungVTKhoContext } from "../DuTruBoSungVTKho";
import { getDsThuocDuTru, updatePhieuDuTru } from "../services/services";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, WF_TRACKER } from "../../utils/Constant";
import { toast } from "react-toastify";
import ButtonIn from "../../component/button-in-phieu";
import TextField from "../../component/TextField";
import { OctDatePickerCustom, OctTextValidator } from "@oceantech/oceantech-ui";
import { formatDateDTO, formatDateToDDMMYYYY } from "../../utils/FormatUtils";

interface Props {
  show: boolean;
  onHide: () => void;
  itemSelected: any;
  setItemSelected: Dispatch<SetStateAction<any>>;
}

const DialogPhieuDuTru = (props: Props) => {
  const { show, onHide, setItemSelected, itemSelected } = props;
  const { setIsLoading } = useContext(AppContext);
  const { dsNhapKho, setDsNhapKho } = useContext(DuTruBoSungVTKhoContext);
  const [isChangeRowData, setIsChangeRowData] = useState<boolean>(false);
  const validationSchema = Yup.object({});

  const handleFormSubmit = async () => {
    try {
      const errorMessage = dsNhapKho.some((item: any) => item.totalQuantity <= 0)
        ? 'Số lượng duyệt phải lớn hơn 0'
        : dsNhapKho.some((item: any) => item.totalQuantity > item.requireQuantity)
        ? 'Số lượng duyệt nhỏ hơn số lượng yêu cầu'
        // : dsNhapKho.some((item: any) => item.totalQuantity > item.item?.iventory)
        // ? 'Số lượng tồn kho không đủ'
        : null
      if (errorMessage) return toast.warning(errorMessage);
      setIsLoading(true);
      const dataSubmit = {
        slipDto: {
          ...itemSelected,
          invoiceDate: formatDateDTO(itemSelected.invoiceDate),
        },
        lstItem: dsNhapKho.map((item: any) => ({...item, totalQuantity: parseFloat(item.totalQuantity)})),
      };
      const { data } = await updatePhieuDuTru(itemSelected.id, dataSubmit);
      if (CODE.SUCCESS === data.code) {
        toast.success('Cập nhật SL duyệt thành công')
        setIsChangeRowData(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getDsThuocDuTru({ pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, slipId: id });      
      setDsNhapKho(data?.data?.content || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeData = (name: string, data: any) => {
    setItemSelected({
      ...itemSelected,
      [name]: data,
    });
    setIsChangeRowData(true);
  }

  useEffect(() => {
    itemSelected?.id && getSelectedRowsData(itemSelected?.id);
  }, [itemSelected?.id]);  

  return (
      <Modal
        centered
        show={show}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Formik
          enableReinitialize
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Header className="header-modal min-h-40px">
                  <div className="d-flex gap-6">
                    <div
                      className="d-flex align-items-center title-back gap-3 cursor-pointer"
                      onClick={onHide}
                    >
                      <i className="spaces bi bi-chevron-left ml-10"></i>
                      <div className="spaces mr-20 my-3">Quay lại</div>
                    </div>
                    <div className="spaces mt-4 title">
                      Thông tin tiếp nhận dự trù bổ sung
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body className="spaces p-0 bg-white">
                  <div className="spaces px-10">
                    <Row>
                      <Col xs={5}>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Tạo phiếu: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{itemSelected?.createSlip || ''}</Col>
                        </Row>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Người lập: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{itemSelected?.createdBy || ''}</Col>
                        </Row>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Người duyệt: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{''}</Col>
                        </Row>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Số hóa đơn: </Col>
                          <Col xs={8} lg={9} className="truncate-text">
                            {itemSelected?.statusCode !== "warehouse_export_approved" ?
                              <TextField
                                hideLabel
                                name="invoiceNumber"
                                onChange={(e: any) => handleChangeData(e.target.name, e.target.value)}
                                value={itemSelected.invoiceNumber}
                              />
                              : itemSelected.invoiceNumber
                            }
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={4}>
                      <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Tháng yêu cầu: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{itemSelected?.requestedMonth || ''}</Col>
                        </Row>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Loại yêu cầu: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{''}</Col>
                        </Row>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Nội dung: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{itemSelected?.notes || ''}</Col>
                        </Row>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Ngày hóa đơn: </Col>
                          <Col xs={8} lg={9} className="truncate-text">
                            {itemSelected?.statusCode !== "warehouse_export_approved" ?
                              <OctDatePickerCustom
                                name='invoiceDate'
                                setDateValue={date => handleChangeData('invoiceDate', date)}
                                value={itemSelected.invoiceDate}
                                dateFormatOutput="YYYY-MM-DD"
                              />
                              : formatDateToDDMMYYYY(itemSelected.invoiceDate)
                            }
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={3}>
                      <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Nơi yêu cầu: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{itemSelected?.whReceiveName || ''}</Col>
                        </Row>
                        <Row>
                          <Col xs={4} lg={3} className="fw-bold truncate-text">Nơi xuất: </Col>
                          <Col xs={8} lg={9} className="truncate-text">{itemSelected?.whIssueName || ''}</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <TableThongTinVT
                    setIsChangeRowData={setIsChangeRowData}
                    itemSelected={itemSelected}
                  />
                </Modal.Body>
                <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6 pl-5">
                  {!isChangeRowData && (
                    <WfButton
                      additionalFunc={(data) => setItemSelected({...itemSelected, statusCode: data?.currCode})}
                      tracker={WF_TRACKER.DU_TRU_BO_SUNG}
                      objectId={itemSelected?.id}
                      type={2}
                    />
                  )}
                  {isChangeRowData &&
                    <Button onClick={handleFormSubmit} className="btn btn-fill min-w-120px spaces mx-3">
                      <IconButtonSave /> Lưu
                    </Button>
                  }
                  <ButtonIn
                    className="spaces min-w-120 mx-3"
                    endpoint="api/download/cabinet/word/request-for-supplies"
                    params={{
                      slipId: itemSelected?.id,
                    }}
                  />
                </div>
              </Form>
          )}
        </Formik>
      </Modal>
  );
};

export default DialogPhieuDuTru;
