import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CODE_SUCCESS, ERROR_MESSAGE, INDEX_DB_NAME, KEY, TRANG_THAI } from "./Constant";
import { getJsonGenerateForm } from "../phan-he-quan-tri-he-thong/tab-loai-dich-vu/LoaiDichVuServices";
import { toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
import moment from "moment";
import { localStorageItem } from '../../modules/utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../modules/auth/core/_consts";
import { COMPONENT_TYPE } from "../component/generate-form/GenerateFormConfig";
import { getDataFromIndexedDB, openDatabaseByName, saveDataToIndexedDB } from "./IndexedDB";
import { formatDateDTO, formatDateToDDMMYYYY } from "./FormatUtils";
import { useEffect, useState } from "react";
import { ColumnConfig } from "../component/table/table-generate/ColumnConfig";
import { TableCustomHeader } from "../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../component/table/components/TableCustomCell";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
export const removeEventEnter = (e: any) => {
  if (e.key === KEY.ENTER) {
    e.preventDefault();
  }
};

export const totalPrice = (data: any, name: string) => {
  const totalPrice = data?.reduce((total: number, item: any) => {
    const totalChildren = item?.items?.reduce(
      (totalChild: number, child: any) => {
        return totalChild + (child[name] || 0);
      },
      0
    );
    return total + totalChildren;
  }, 0);
  return totalPrice || 0;
};

export const formatDate = (data: number) => {
  return data < 10 ? `0${data}` : data;
};

export const formatDateFromDTOCustom = (date: string | Date | undefined) => {
  return date ? moment(date).format("YYYY-MM-DDT00:00:00") : "";
};

export const formatDateToDTOCustom = (date: string | Date | undefined) => {
  return date ? moment(date).format("YYYY-MM-DDT23:59:59") : "";
};

export const RomanNumeralsConverter = (number: number) => {
  if (isNaN(number)) {
    return;
  };

  const romanNumerals: { value: number; numeral: string }[] = [
    { value: 1000, numeral: "M" },
    { value: 900, numeral: "CM" },
    { value: 500, numeral: "D" },
    { value: 400, numeral: "CD" },
    { value: 100, numeral: "C" },
    { value: 90, numeral: "XC" },
    { value: 50, numeral: "L" },
    { value: 40, numeral: "XL" },
    { value: 10, numeral: "X" },
    { value: 9, numeral: "IX" },
    { value: 5, numeral: "V" },
    { value: 4, numeral: "IV" },
    { value: 1, numeral: "I" },
  ];

  let result = "";
  for (let i = 0; i < romanNumerals.length; i++) {
    while (number >= romanNumerals[i].value) {
      result += romanNumerals[i].numeral;
      number -= romanNumerals[i].value;
    }
  }

  return result;
};

export function generateSecureRandomId(length: number = 16) {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
}

// export const generateForm = async (codeAPI: string, setIsLoading?: any) => {
//   setIsLoading && setIsLoading(true);
//   try {
//     let dataFieldAuto = localStorage.getItem(`form-${codeAPI}`) || "";
//     if (dataFieldAuto) {
//       return JSON.parse(dataFieldAuto) || {};
//     } else {
//       let res = await getJsonGenerateForm(codeAPI);
//       if (res?.data?.code === CODE_SUCCESS) {
//         localStorage.setItem(`form-${codeAPI}`, res?.data?.data);
//         return JSON.parse(res?.data?.data);
//       } else {
//         toast.error(ERROR_MESSAGE);
//       }
//     }
//   } catch (error) {
//     toast.error(ERROR_MESSAGE);
//   } finally {
//     setIsLoading && setIsLoading(false);
//   }
// };
export const getSimpleCategory = (searchObject: any) => {
  let url = API_PATH + "/simple-categories/search";
  return axios.get(url, { params: searchObject });
};

export const removeVietnameseTones = (str: string = "") => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");

  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");

  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư

  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();

  // Bỏ dấu câu, kí tự đặc biệt
  // eslint-disable-next-line no-useless-escape
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
}

export function customDebounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}
export const flatArrayByChildrenName = (arr: any, childName: string) => {
  let result: any = [];

  arr.forEach((item: any) => {
    result.push(item);
    if (item[childName] && item[childName].length > 0) {
      result = result.concat(flatArrayByChildrenName(item[childName], childName));
    }
  });

  return result;
}

export const handleConvertBirthdate = (data: any) => {
  if (data?.birthDate) {
    return moment(data?.birthDate).format("DD/MM/YYYY");
  } else if (data?.dobYear) {
    return data?.dobYear;
  } else if (data?.dobYear && data?.dobMonth) {
    return data?.dobMonth + "/" + data?.dobYear;
  }
};

export const handleConvertAddress = (data: any) => {
  if (data?.address) {
    return data?.provinceName + ", " + data?.districtName + ", " + data?.communeName + ", " + data?.address
  } else {
    return data?.provinceName + ", " + data?.districtName + ", " + data?.communeName
  }
};

export const handleCaculateTotalAmount = (data: any[], keyPrice?: string, keyQuantity?: string, keyVAT?: string) => {
  const calculatePrice = (item: any) => {
    const price = parseFloat(item?.[keyPrice || 'price'] || 0);
    const quantity = parseFloat(item?.[keyQuantity || 'totalQuantity'] || 0);
    const vat = parseFloat(item?.[keyVAT || 'vat'] || 0);
    return (quantity * price) * (1 + (vat / 100));
  };
  const result = data.reduce((total, item) => total + calculatePrice(item), 0);
  return roundToDecimalPlaces(parseFloat(result));
};

export const generateHrTag = (query: string) => {
  let isHasHrTag = document.querySelector("hr");
  let element = document.querySelector(query);
  if (!element || isHasHrTag) return;
  let hrTag = document.createElement('hr');
  element.insertAdjacentElement('afterend', hrTag)
};

export const getSurplusPercent = (value: number): number => {
  if (!value) return 0;
  if (value <= 1000) return 15;
  if (value <= 5000) return 10;
  if (value <= 100000) return 7;
  if (value <= 1000000) return 5;
  return 2;
};

export const generateValidationSchema = (data: any, formCode: string) => {
  const handleValidate = () => {
    let objValidation: any = {};
    const checkValidate = (lstData: any) => {
      let newLstData = lstData?.components?.filter(
        (item: any) => item.type !== COMPONENT_TYPE.BUTTON
      );

      newLstData?.forEach((value: any) => {
        if (value.validate?.required) {
          objValidation[value.key] = Yup.string()
            .required('Bắt buộc nhập')
            .nullable();
        } else if (value?.type === COMPONENT_TYPE.COLUMNS && value?.columns && value?.columns?.length) {
          value?.columns?.forEach((lstValue: any) => {
            checkValidate(lstValue);
          });
        }
      });
    };
    checkValidate(data);
    return Yup.object({
      [formCode]: Yup.object(objValidation),
    });
  };

  return handleValidate();
};

export const generateInitValues = (data: any, formCode?: string) => {
  const handleValues = () => {
    let values: any = {};

    const checkValue = (lstData: any) => {
      let newLstData = lstData?.components?.filter(
        (item: any) => item.type !== COMPONENT_TYPE.BUTTON
      );
      newLstData?.forEach((data: any) => {
        switch (data.type) {
          case COMPONENT_TYPE.TEXTFIELD:
          case COMPONENT_TYPE.DATEPICKER:
          case COMPONENT_TYPE.TEXTAREA:
          case COMPONENT_TYPE.DAY:
          case COMPONENT_TYPE.TIME:
          case COMPONENT_TYPE.EMAIL:
          case COMPONENT_TYPE.PHONE:
            values[data.key] = data.defaultValue;
            break;
          case COMPONENT_TYPE.DATETIME:
            values[data.key] = data.defaultDate === "newDate" ? formatDateDTO(new Date()) : data.defaultDate;
            break;
          case COMPONENT_TYPE.NUMBER:
            values[data.key] = parseFloat(data.defaultValue) || '';
            break;
          case COMPONENT_TYPE.SELECT:
            const fieldValue = data.defaultValue
              ? JSON.parse(data.defaultValue)
              : {
                [data.key]: "",
                [`${data.key}Id`]: "",
                [`${data.key}Name`]: "",
              };
            values = {
              ...values,
              ...fieldValue,
            };
            break;
          case COMPONENT_TYPE.CHECKBOX:
          case COMPONENT_TYPE.RADIO:
            values[data.key] = data.defaultValue == "true" ? "1" : "0";
            break;
          case COMPONENT_TYPE.COLUMNS:
            data?.columns?.forEach((lstData: any) => {
              checkValue(lstData);
            });
            break
          default:
            break;
        };
      });
    };

    checkValue(data);
    return formCode ? { [formCode]: values } : values;
  };

  return handleValues();
};

export const generateForm = async (codeAPI: string, setIsLoading?: (isLoading: boolean) => void) => {
  setIsLoading && setIsLoading(true);
  try {
    const db = await openDatabaseByName(INDEX_DB_NAME.FORM_MODEL);
    let dataFieldAuto = await getDataFromIndexedDB(db, codeAPI, INDEX_DB_NAME.FORM_MODEL);

    if (dataFieldAuto) {
      return JSON.parse(dataFieldAuto) || {};
    } else {
      let res = await getJsonGenerateForm(codeAPI);
      if (res?.data?.code === CODE_SUCCESS) {
        dataFieldAuto = res?.data?.data;

        if (dataFieldAuto) {
          await saveDataToIndexedDB(db, `form-${codeAPI}`, dataFieldAuto, INDEX_DB_NAME.FORM_MODEL);
          return JSON.parse(dataFieldAuto);
        } else {
          toast.error(ERROR_MESSAGE);
          return {};
        }
      } else {
        toast.error(ERROR_MESSAGE);
      }
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error(ERROR_MESSAGE);
  } finally {
    setIsLoading && setIsLoading(false);
  }
};

export const generateDataByUrl = async (
  codeAPI: string,
  funcApi: () => Promise<any>,
  setIsLoading?: (isLoading: boolean) => void
) => {
  setIsLoading && setIsLoading(true);
  try {
    const db = await openDatabaseByName(INDEX_DB_NAME.URL_DATA);
    let dataFieldAuto = await getDataFromIndexedDB(db, codeAPI, INDEX_DB_NAME.URL_DATA);

    if (dataFieldAuto) {
      return JSON.parse(dataFieldAuto) || {};
    } else {
      const res = await funcApi();
      if (res?.data?.code === CODE_SUCCESS) {
        const dataToStore = res.data.data;

        if (dataToStore) {
          await saveDataToIndexedDB(db, codeAPI, JSON.stringify(res), INDEX_DB_NAME.URL_DATA);
          return res;
        } else {
          toast.error(ERROR_MESSAGE);
          return {};
        }
      } else {
        toast.error(ERROR_MESSAGE);
      }
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error(ERROR_MESSAGE);
  } finally {
    setIsLoading && setIsLoading(false);
  }
};

export const roundToDecimalPlaces = (number: number, decimals?: number) => {
  const factor = Math.pow(10, decimals || 3);
  const result = Math.round(number * factor) / factor;
  return result;
};

export const generateSearchParams = (data: any, formCode?: string) => {
  const handleValues = () => {
    let searchParams: any = {};

    const checkValue = (lstData: any) => {
      let newLstData = lstData?.components?.filter(
        (item: any) => item.type !== COMPONENT_TYPE.BUTTON
      );
      newLstData?.forEach((data: any) => {
        switch (data.type) {
          case COMPONENT_TYPE.SELECT:
            if (data?.data?.headers?.length) {
              let ListKeySearch = {};
              data?.data?.headers?.length && data?.data?.headers.map((item: any, index: any) => {
                ListKeySearch = {
                  ...ListKeySearch,
                  [item.key]: item.value || ""
                }
              })
              searchParams = {
                ...searchParams,
                [data?.key]: ListKeySearch
              };
            }
            break;
          case COMPONENT_TYPE.COLUMNS:
            data?.columns?.forEach((lstData: any) => {
              checkValue(lstData);
            });
            break
          default:
            break;
        };
      });
    };

    checkValue(data);
    return formCode ? { [formCode]: searchParams } : searchParams;
  };

  return handleValues();
};

export const useDebounce = <T,>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    
      return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const extractColumnsOctTable = (data: any): any[] => {
  let result: ColumnConfig[] = [
    {
      id: "stt",
      title: "STT",
      classCell: "text-center w-50px",
      classColumn: "w-50px",
    },
  ];

  const traverse = (components: any[]) => {
    components.forEach((component) => {
      if (component.tableView) {
        result.push({
          id: component?.key,
          title: component?.label,
          classCell: "",
          classColumn: "",
        }); // Chỉ lấy những component có tableView: true
      }

      if (component.components) {
        traverse(component.components); // Duyệt vào components con
      }

      if (component.columns) {
        component.columns.forEach((column: any) => {
          if (column?.components) {
            traverse(column?.components); // Duyệt tiếp vào columns
          }
        });
      }
    });
  };

  if (data.components) {
    traverse(data.components);
  }

  return result;
};

export const extractColumnsTableCustom = (data: any, customComponent?: any, propsCustomComponent?: any): any[] => {
  let result: any[] = [
    {
      Header: (props: any) => (
        <TableCustomHeader<any>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
  ];

  const traverse = (components: any[]) => {
    components.forEach((component) => {
      if (propsCustomComponent && result.length === propsCustomComponent.index) {
        result.push({
              Header: (props: any) => (
                <TableCustomHeader<any>
                  tableProps={props}
                  title={propsCustomComponent.title}
                  className={propsCustomComponent.className}
                />
              ),
              id: propsCustomComponent.id,
              Cell: ({ ...props }) => customComponent({...props, ...propsCustomComponent})
            },)
      }

      if (component.tableView) {                
        result.push({
          Header: (props: any) => (
            <TableCustomHeader<any>
              tableProps={props}
              title={component?.label}
              className="text-center text-light"
            />
          ),
          id: component.properties?.field || component?.key,
            Cell: ({ ...props }) => {
              let data = props?.data[props?.row?.index][component.type === 'custom' ? component.field : component.properties?.field || component?.key];
              return (
              <TableCustomCell
                className=""
                data={component.type === 'datetime' ? formatDateToDDMMYYYY(data) : data}
              />
            );
          },
        },); // Chỉ lấy những component có tableView: true
        
      }

      if (component.components) {
        traverse(component.components); // Duyệt vào components con
      }

      if (component.columns) {
        component.columns.forEach((column: any) => {
          if (column?.components) {
            traverse(column?.components); // Duyệt tiếp vào columns
          }
        });
      }
    });
  };

  if (data.components) {
    traverse(data.components);
  }

  return result;
};