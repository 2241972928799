import { OctTable } from "@oceantech/oceantech-ui";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import InputSearch from "../../component/InputSearch";
import { TablePagination } from "../../component/table/components/TablePagination";
import { CODE, DEFAULT_PAGE_INDEX, KEY, STATUS_CODE, TYPE_WH, WAREHOUSE_ID, WF_TRACKER } from "../../utils/Constant";
import { formatDateFromDTOCustom, formatDateToDDMMYYYY, formatDateToDTOCustom, formatDateToYYYYMMDD } from "../../utils/FormatUtils";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { getSummaryStatusByType } from "../../workflows/services/WfWorkflowsService";
import { DuTruBoSungVTKhoContext } from "../DuTruBoSungVTKho";
import "../DuTruBoSungVTKho.scss";
import { deletePhieuDuTru, downloadReport, searchDsPhieuDuTru } from "../services/services";
import DialogPhieuDuTru from "./DialogPhieuDuTruBoSungVTKho";
import DialogThemPhieuTongHop from "../../TongHopBoSungVatTu/DialogThemPhieuTongHop";
import { localStorageItem } from "../../utils/LocalStorage";
import { getListWarehouse } from "../../utils/ServicesUtils";
import AdvancedSearch from "../../component/advanced-search/AdvancedSearch";
import DatePickerCustom from "../../component/date-picker/DatePickerCustom";

interface Props {
  warehouseId: any;
}

export const FormDuTruBoSungVTKho = (props: Props) => {
  const { warehouseId } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dsPhieuDuTru, setDsPhieuDuTru] = useState<any[]>([]);
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<boolean>(false);
  const [openDialogTongHop, setOpenDialogTongHop] = useState(false);
  const { setDsNhapKho } = useContext(DuTruBoSungVTKhoContext);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [lstDataChecked, setLstDataChecked] = useState<any[]>([]);
  const [itemSelected, setItemSelected] = useState<any>();
  const [summaryStatus, setSummaryStatus] = useState<any>([]);
  const [searchData, setSearchData] = useState<any>({});
  const [openDialogAdvancedSearch, setOpenDialogAdvancedSearch] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [lstWarehouse, setLstWarehouse] = useState<any>({});
  const dataUser = localStorageItem.get("current-user");


  const updateData = async () => {
    try {
      const data = await getListWarehouse();
      let newLstData: any = {};
      data.forEach((item: any) => {
        newLstData[item.id] = {
          whIssueId: item.id,
          whIssueName: item.name,
          whIssue: item.code,
        };
      });
      setLstWarehouse(newLstData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateData();
  }, []);
  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deletePhieuDuTru(itemSelected.id);
      if (CODE.SUCCESS === res.status) {
        toast.success("Xóa phiếu thành công");
        handleCloseDialog();
      }
    } catch (error: any) {
      const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
      toast.error(message);
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleCloseDialog = () => {
    updatePageData();
    setDsNhapKho([]);
    setOpenDialogTongHop(false);
    setOpenDialogConfirmDelete(false);
    setOpenDialogThemMoi(false);
    setItemSelected(null);
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const { data } = await searchDsPhieuDuTru({
        keyword: searchData?.keyword?.trim() || null,
        doCreationFromDate: formatDateFromDTOCustom(searchData?.doCreationFromDate),
        doCreationToDate: formatDateToDTOCustom(searchData?.doCreationToDate),
        type: TYPE_WH.DU_TRU_BO_SUNG,
        pageSize: rowsPerPage,
        pageIndex: page,
        whIssueId: warehouseId,
        reqQuarter: parseInt(searchData.quarterReq),
        reqMonth: parseInt(searchData.monthReq),
        whReceive: searchData.whReceiveCode,
      });
      const resSummary = await getSummaryStatusByType(
        WF_TRACKER.DU_TRU_BO_SUNG,
        TYPE_WH.DU_TRU_BO_SUNG,
        {
          whIssueId: warehouseId,
          fromDate: formatDateFromDTOCustom(searchData?.doCreationFromDate) || null,
          toDate: formatDateToDTOCustom(searchData?.doCreationToDate) || null,
        }
      );
      if (CODE.SUCCESS === resSummary.data?.code) {
        setSummaryStatus(resSummary.data.data);
      }
      if (CODE.SUCCESS === data.code) {
        const newData = data?.data?.content?.map((item: any) => ({ ...item, isDisable: STATUS_CODE.DA_DUYET !== item?.statusCode, isHidden: STATUS_CODE.DA_DUYET !== item?.statusCode }))
        setDsPhieuDuTru(newData || []);
        setConfigTable({
          totalPages: Math.ceil(data.data.pageable.totalElements / data.data.pageable.pageSize),
          totalElements: data?.data?.pageable?.totalElements,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    page,
    searchData.doCreationFromDate,
    searchData.doCreationToDate,
    searchData.whReceiveCode,
    searchData.quarterReq,
    searchData.monthReq,
  ]);

  const handlerSearchKeyWorld = () => {
    setPage(DEFAULT_PAGE_INDEX);
    setRowsPerPage(20);
    updatePageData();
  };

  const handleSummary = () => {
    if (!lstDataChecked || !lstDataChecked.length) return toast.warning('Chưa chọn phiếu dự trù');
    setOpenDialogTongHop(true);
  };

  const handleChangeDateSearch = (name: string, date: any) => {
    setSearchData({
      ...searchData,
      [name]: date,
    });
    // setAdvancedSearchData({
    //   ...advancedSearchData,
    //   [name]: date,
    // })
  };

  const handleAdvancedSearch = (values: any) => {
    setSearchData({
      ...searchData,
      ...values,
    })
  };

  const handleDownloadReport = async (values?: any) => {
    const data = values || searchData;
    if (!data.doCreationFromDate || !data.doCreationToDate || !data.whReceiveCode) {
      setOpenDialogAdvancedSearch(true);
      return;
    }
    try {
      setIsLoading(true);
      const response = await downloadReport([
        { name: "warehouse_id", value: warehouseId },
        { name: "type", value: TYPE_WH.DU_TRU_BO_SUNG },
        { name: "material_group", value: null },
        { name: "tu_ngay", value: formatDateToYYYYMMDD(data?.doCreationFromDate) },
        { name: "den_ngay", value: formatDateToYYYYMMDD(data?.doCreationToDate) },
        { name: "req_month", value: parseInt(data.monthReq) || null },
        { name: "wh_receive", value: data?.whReceiveCode || null },
        { name: "whReceiveName", value: data?.whReceiveName || null },
        { name: "req_quarter", value: parseInt(data?.quarterReq) || null},
      ]);
      const blob = new Blob([response.data], { type: "application/xls" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `excel.xls`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    {
      name: "Thao tác",
      field: "",
      headerStyle: {
        minWidth: 80,
        maxWidth: 80,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => (
        <div className="d-flex justify-content-center">
          <i
            className="bi bi-pencil-square text-navy"
            onClick={() => {
              setItemSelected(row);
              setOpenDialogThemMoi(true);
            }}
          ></i>
          {row.color === "#DC3545" && <i
            className="bi bi-trash3-fill text-danger spaces ml-10"
            onClick={() => {
              setOpenDialogConfirmDelete(true);
              setItemSelected(row);
            }}
          ></i>}
        </div>
      ),
    },
    {
      name: "TT",
      field: "code",
      headerStyle: {
        minWidth: 20,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => (
        <div className="text-center">
          <i className="bi bi-circle-fill" style={{ color: row?.color }}></i>
        </div>
      ),
    },
    {
      name: "Mã phiếu",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.code || row.item?.code || "",
    },
    {
      name: "Ngày tạo",
      field: "",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) =>
        formatDateToDDMMYYYY(row.createdDate),
    },
    {
      name: "Tháng yêu cầu",
      field: "reqMonth",
      headerStyle: {
        minWidth: 130,
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Loại yêu cầu",
      field: "",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.reqType === 'RT1' ? 'Đột xuất' : row.reqType === 'RT2' ? 'Kế hoạch' : ''
    },
    {
      name: "Nơi yêu cầu",
      field: "whReceiveName",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Nơi thực hiện",
      field: "whIssueName",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Nội dung",
      field: "notes",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  return (
    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex bg-white spaces width-100 flex-space-between">
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10 min-w-180">
                Danh sách phiếu dự trù
              </h4>
              {warehouseId === WAREHOUSE_ID.KHO_VPP && (
                <div className="flex spaces px-8">
                  <div className="spaces ml-4">
                    <Button
                      className="btn btn-fill mt-4"
                      onClick={() => handleDownloadReport()}
                    >
                      Tải báo cáo
                    </Button>
                  </div>
                  <div className="spaces ml-4">
                    <Button
                      className="btn btn-fill mt-4 ml-4"
                      onClick={() => setOpenDialogAdvancedSearch(true)}
                    >
                      Tìm kiếm nâng cao
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex mt-1 flex-column spaces width-100 ">
              <div className="bg-white">
                <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  {/* <div className=" bg-white flex align-items-center mr-13 spaces">
                    <Button
                      className="btn-fill ml-10 spaces min-w-90px btn btn-primary"
                      onClick={handleSummary}
                    >
                      Tổng hợp
                    </Button>
                  </div> */}
                  <div className="d-flex spaces mx-10 ">
                    <DatePickerCustom
                      name="doCreationFromDate"
                      value={searchData?.doCreationFromDate || ""}
                      setDateValue={date => handleChangeDateSearch('doCreationFromDate', date)}
                      dateFormatOutput="YYYY-MM-DD"
                      label="Từ ngày"
                      labelClassName="min-w-60"
                    />
                  </div>
                  <div className="d-flex spaces mr-10">
                    <DatePickerCustom
                      name="doCreationToDate"
                      value={searchData?.doCreationToDate || ""}
                      setDateValue={date => handleChangeDateSearch('doCreationToDate', date)}
                      dateFormatOutput="YYYY-MM-DD"
                      label="Đến ngày"
                      labelClassName="min-w-70"
                    />
                  </div>
                  <div className="flex-grow-1 ">
                    <InputSearch
                      name="keyword"
                      value={searchData?.keyword || ""}
                      placeholder="Tìm kiếm theo mã phiếu"
                      handleChange={handleChangeSearchData}
                      handleSearch={() => handlerSearchKeyWorld()}
                      handleKeyDown={(e: React.KeyboardEvent) =>
                        e.key === KEY.ENTER && handlerSearchKeyWorld()
                      }
                      className="spaces h-31 pr-4 radius-3"
                    />
                  </div>
                </div>
                <div className="spaces bg-white mb-0">
                  <OctTable
                    id="table-du-tru-bo-sung-vt"
                    className="custom-header-table spaces h-calc-vh-210"
                    type="multiline"
                    isShowSelection={false}
                    columns={columns}
                    data={dsPhieuDuTru}
                    dataChecked={lstDataChecked}
                    setDataChecked={data => setLstDataChecked(data)}
                    rowSelected={true}
                    noPagination
                    noToolbar
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={Number(configTable.totalPages)}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    customComponent={
                      <WfStatusSummary summaryStatus={summaryStatus} col={2} />
                    }
                    customComponentClassName="min-w-900px"
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
          </div>
          {openDialogAdvancedSearch && warehouseId === WAREHOUSE_ID.KHO_VPP && (
            <AdvancedSearch
              show={openDialogAdvancedSearch}
              onHide={() => setOpenDialogAdvancedSearch(false)}
              initialValues={searchData}
              idForm="timKiemNangCaoDuTruBoSungVatTu"
              handleSearch={handleAdvancedSearch}
              downloadReport
              handleDownloadReport={handleDownloadReport}
            />
          )}
          {openDialogTongHop && (
            <DialogThemPhieuTongHop
              lstDataChecked={lstDataChecked}
              show={openDialogTongHop}
              onHide={handleCloseDialog}
              initialValues={{
                themPhieuTongHopVatTu: {
                  ...itemSelected,
                  ...lstWarehouse[warehouseId],
                  // whReceive: itemSelected?.whReceive,
                  createDate: itemSelected?.createDate || new Date(),
                  notes: itemSelected?.notes,
                  createdBy: dataUser?.sub,
                  code: null
                }
              }}
              modelId="themPhieuTongHopVatTu"
              warehouseId={warehouseId}
            />
          )}
          {openDialogThemMoi && (
            <DialogPhieuDuTru
              show={openDialogThemMoi}
              onHide={handleCloseDialog}
              itemSelected={itemSelected}
              setItemSelected={setItemSelected}
            />
          )}
          {openDialogConfirmDelete && (
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handleCloseDialog}
              onYesClick={handleDelete}
              title="Xóa phiếu dự trù"
              message={`Bạn chắc chắn muốn xóa phiếu: ${itemSelected.code} ?`}
              yes="Xóa"
              close="Hủy"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormDuTruBoSungVTKho;
