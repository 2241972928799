import moment from "moment";

import {
  NUMBER_EXCEPT_THIS_SYMBOLS,
  TRANG_THAI,
  TRANG_THAI_KHAM_BENH,
} from "./Constant";
import {
  Col,
  Form,
  FormCheck,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import CustomTextarea from "../component/custom-textarea/CustomTextarea";
import { roundToDecimalPlaces } from "./AppFunction";
// import { trangThaiBenhNhanDatLich } from "../dat-lich-hen/constants/datLichHenConstants";

export const convertNumberPrice = (value: any) => {
  let number = Number(value ? value : 0);
  let plainNumber = number.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, "$&.");
  return plainNumber.substr(0, plainNumber.length - 2);
};

export const checkObject = (value: any) => {
  if (typeof value === "object" && !Array.isArray(value) && value !== null) {
    return true;
  }
  return false;
};

export const formatDateToString = (date: string | Date | undefined) => {
  let newDate = date ? new Date(date) : null;
  return newDate ? moment(newDate).format("MM/DD/YYYY") : "";
};

export const formatDateAdvanceToString = (date: string | Date | undefined) => {
  return date ? moment(date).format("HH:mm:ss DD/MM/YYYY") : "";
};

export const formatDateToDDMMYYYY = (date: string | Date | undefined) => {
  return date ? moment(date).format(`DD/MM/YYYY`) : "";
};

export const formatDateToYYYYMMDD = (date: string | Date | undefined) => {
  return date ? moment(date).format(`YYYY-MM-DD`) : null;
};
export const formatStringDateToYYYYMMDD = (date: string | Date | undefined) => {
  return date ? moment(date, "YYYY-MM-DDTHH:mm:ss").format(`YYYY-MM-DD`) : null;
};
export const formatStringDateDTO = (date: string | Date | undefined) => {
  return date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : "";
};
export const formatDateDTO = (date: string | Date | undefined) => {
  return date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : "";
};
export const formatDateFromDTOCustom = (date: string | Date | undefined) => {
  return date ? moment(date).format("YYYY-MM-DDT00:00:00") : "";
}
export const formatDateToDTOCustom = (date: string | Date | undefined) => {
  return date ? moment(date).format("YYYY-MM-DDT23:59:59") : "";
};
// export const formatStringDateDTO = (date: string | Date | undefined) => {
//   return date ? moment(date,"DD-MM-YYY").format("YYYY-MM-DDTHH:mm:ss") : "";
// };

export const formatDate = (value: string | undefined) => {
  if (value) {
    return value.split("-").reverse().join("/").toString();
  } else {
    return value;
  }
};

export const handleAgeCalculate = (DOB: string) => {
  const ageInMilliseconds = +new Date() - +new Date(DOB);
  const ageDate = new Date(ageInMilliseconds);

  if (ageDate.getUTCFullYear() - 1970 < 1) {
    if (ageDate.getUTCMonth() < 1) {
      const days = ageDate.getUTCDate() - 1;
      return `${days} ngày`;
    } else {
      return `${ageDate.getUTCMonth()} tháng`;
    }
  } else {
    return `${Math.abs(ageDate.getUTCFullYear() - 1970).toString()} tuổi`;
  }
};

export const generateRandomId = (length: number = 10): string => {
  const characters: string =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength: number = characters.length;
  let randomId: string = "";

  for (let i: number = 0; i < length; i++) {
    const randomIndex: number = Math.floor(Math.random() * charactersLength);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
};


export const removeDiacritics = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const handleSum = (array: any, name: string) => {
  return array?.length > 0
    ? array.reduce(
      (accumulator: number, dichVu: any) =>
        accumulator + Number(dichVu?.[name]),
      0
    )
    : 0;
};

export const numberExceptThisSymbols = (event: any) => {
  return (
    NUMBER_EXCEPT_THIS_SYMBOLS.includes(event?.key) && event.preventDefault()
  );
};

export const covertSinhHieu = (data: any) => {
  let sinhHieu: any = {};
  if (data?.length > 0) {
    data.map((item: any) => {
      sinhHieu[item?.code] = item;
    });
  }
  return sinhHieu;
};

export const CheckTrangThaiKhamBenh = (status: number) => {
  let objectStatus = TRANG_THAI_KHAM_BENH.find(
    (item) => item?.status === status
  );
  return objectStatus;
};

export const newDate = (
  birthYear: number,
  birthMonth = 0,
  birthDay = 1,
  birthHour = 0,
  birthMinute = 0,
  birthSecond = 0
) => {
  birthDay = birthDay <= 0 ? 1 : birthDay;
  return birthYear
    ? new Date(
      birthYear,
      birthMonth - 1,
      birthDay,
      birthHour,
      birthMinute,
      birthSecond
    ).toString()
    : "";
};

export const validateNgay = (ngay: number, thang: number, nam: number) => {
  if (ngay < 1 || ngay > 31) {
    return false;
  }

  if (thang === 4 || thang === 6 || thang === 9 || thang === 11) {
    return ngay <= 30;
  }

  if (thang === 2) {
    if ((nam % 4 === 0 && nam % 100 !== 0) || nam % 400 === 0) {
      return ngay <= 29;
    }
    return ngay <= 28;
  }

  return true;
};

export const formatTrangThaiBenhNhan = (trangThai: string | undefined) => {
  return (
    <div className="text-center">
      <i
        style={{ color: trangThai }}
        className="bi bi-circle-fill"></i>
    </div>
  );
};

// export const formatTrangThaiBenhNhanDatLich = (
//   trangThai: number | undefined
// ) => {
//   switch (trangThai) {
//     case trangThaiBenhNhanDatLich.chuaDen.code:
//       return (
//         <div className="text-center">
//           <i className="bi bi-circle-fill pe-2 text-status-blue"></i>&nbsp;
//         </div>
//       );
//     case trangThaiBenhNhanDatLich.daTiepNhan.code:
//       return (
//         <div className="text-center">
//           <i className="bi bi-circle-fill pe-2 text-status-yellow"></i>&nbsp;
//         </div>
//       );
//     case trangThaiBenhNhanDatLich.daGoiNhacLich.code:
//       return (
//         <div className="text-center">
//           <i className="bi bi-circle-fill pe-2 text-status-orange"></i>&nbsp;
//         </div>
//       );
//     case trangThaiBenhNhanDatLich.koDenHuyLich.code:
//       return (
//         <div className="text-center">
//           <i className="bi bi-circle-fill pe-2 text-status-red"></i>&nbsp;
//         </div>
//       );

//     default:
//       return trangThai;
//   }
// };

export const renderItemKhamBoPhan = (Data: any) => (
  <Row>
    {Data.map((item: any) => {
      return (
        <Col xs="4" className="d-flex">
          <Form className="d-flex align-items-center">
            <FormCheck
              type="checkbox"
              label={item?.name}
              className="min-w-125px d-flex align-items-end gap-2"
              name={item?.code}
            />
            <CustomTextarea marginUnderline={8} />
          </Form>
        </Col>
      );
    })}
  </Row>
);

export const renderTrangThaiHanhChinh = (
  trangThai: number,
  listTitle?: string[]
) => {
  const checkColor =
    trangThai === TRANG_THAI.DA_HOAN_THANH ? "text-success" : "text-warning";

  const renderTooltip = () => {
    if (listTitle && listTitle?.length > 0) {
      return <Tooltip>{listTitle[trangThai - 1]}</Tooltip>;
    }
    return <></>;
  };

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip()}
    >
      {trangThai ? (
        <i className={`fa-solid fa-circle ${checkColor}`}></i>
      ) : (
        <></>
      )}
    </OverlayTrigger>
  );
};

export const formatMoney = (value: number) => {
  if (!value) {
    return 0;
  } else {
    let roundedValue = roundToDecimalPlaces(value);
    let result = roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  }
};

export const formatMoneyToString = (value: number) => {
  if (!value) {
    return '0';
  } else {
    let roundedValue = roundToDecimalPlaces(value);
    let result = roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  }
};

export const calculateAge = (day: any, month: any, year: any): string => {
  const today = moment();
  const parsedDay = Number(day ? day : 1);
  const parsedMonth = Number(month ? month : 1);
  const parsedYear = Number(year);

  if (isNaN(parsedYear)) {
    return "";
  }
  // Kiểm tra phạm vi hợp lệ của ngày, tháng, năm
  if (parsedDay < 1 || parsedDay > 31 || parsedMonth < 1 || parsedMonth > 12 || parsedYear.toString().length !== 4) {
    return "";
  }
  let birthDate = moment([parsedYear, parsedMonth - 1, parsedDay]);
  // Tính tuổi theo tháng
  const ageInMonths = today.diff(birthDate, 'months');

  if (ageInMonths <= 36 && month) {
    return ageInMonths + ' tháng tuổi';
  } else {
    const ageInYears = today.diff(birthDate, 'years');
    return ageInYears + ' tuổi';
  }
};