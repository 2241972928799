import { Button, Modal } from "react-bootstrap";
import { OctTable } from "@oceantech/oceantech-ui";
import { formatDateToDDMMYYYY } from "../utils/FormatUtils";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AppContext } from "../appContext/AppContext";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, STATUS_CODE, TYPE_WH } from "../utils/Constant";
import { searchDsPhieuDuTru } from "../du-tru-bo-sung-vat-tu-kho/services/services";
import { toast } from "react-toastify";

interface Props {
  show?: boolean;
  onHide: () => void;
  warehouseId?: any;
  lstChecked?: any[];
  setLstChecked?: Dispatch<SetStateAction<any[]>>
}

const DialogThemPhieuDuTru = (props: Props) => {
  const { show, onHide, warehouseId, lstChecked = [], setLstChecked } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dsPhieuDuTru, setDsPhieuDuTru] = useState([]);
  const [newLstChecked, setNewLstChecked] = useState(lstChecked);

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const { data } = await searchDsPhieuDuTru({
        type: TYPE_WH.DU_TRU_BO_SUNG,
        pageSize: MAX_PAGE_SIZE,
        pageIndex: DEFAULT_PAGE_INDEX,
        whIssueId: warehouseId,
        slipStatus: STATUS_CODE.DA_DUYET,
      });
      if (CODE.SUCCESS === data.code) {
        setDsPhieuDuTru(data?.data?.content || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, []);

  const handleSave = () => {
    if (!newLstChecked || !newLstChecked.length) return toast.warning('Chưa chọn phiếu dự trù');
    setLstChecked && setLstChecked(newLstChecked);
    onHide();
  };

  const columns = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    {
      name: "TT",
      field: "code",
      headerStyle: {
        minWidth: 20,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => (
        <div className="text-center">
          <i className="bi bi-circle-fill" style={{ color: row?.color }}></i>
        </div>
      ),
    },
    {
      name: "Mã phiếu",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.code || row.item?.code || "",
    },
    {
      name: "Ngày tạo",
      field: "",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) =>
        formatDateToDDMMYYYY(row.createdDate),
    },
    {
      name: "Tháng yêu cầu",
      field: "requestedMonth",
      headerStyle: {
        minWidth: 130,
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Loại yêu cầu",
      field: "",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.reqType === "RT1"
          ? "Đột xuất"
          : row.reqType === "RT2"
          ? "Kế hoạch"
          : "",
    },
    {
      name: "Nơi yêu cầu",
      field: "whReceiveName",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Nơi thực hiện",
      field: "whIssueName",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Nội dung",
      field: "notes",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  return (
    <Modal centered show={show} onHide={onHide} size="xl" className="spaces">
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={onHide}
          >
            <i className="spaces bi bi-chevron-left ml-10"></i>
            <div className="spaces mr-20 my-3">Quay lại</div>
          </div>
          <div className="spaces mt-4 title">Thay đổi phiếu dự trù</div>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces p-0 bg-white">
        <OctTable
          id="table-du-tru-bo-sung-vt"
          className="custom-header-table spaces h-calc-vh-210"
          type="multiline"
          isShowSelection={false}
          columns={columns}
          data={dsPhieuDuTru}
          dataChecked={newLstChecked}
          setDataChecked={data => setNewLstChecked(data)}
          rowSelected={true}
          noPagination
          noToolbar
          fixedColumnsCount={-2}
        />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button onClick={handleSave} className="min-w-100px btn btn-fill">Lưu</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogThemPhieuDuTru;
