import { CellProps, Column } from "react-table";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";

const SITE = process.env.REACT_APP_SITE;

export const getColumns = (labelProduct?: string): Column<any>[] => {
	const label = labelProduct ?? 'Thuốc';
	const COLUMNS: Column<any>[] = [
		{
			Header: `Mã ${label}`,
			accessor: 'code',
			width: 100,
		},
		{
			Header: `Tên ${label}`,
			accessor: 'name',
			width: 200,
		},
		{
			Header: 'Số lô',
			accessor: 'consignment',
			width: 200,
		},
		{
			Header: 'Hàm Lượng',
			accessor: 'dosage',
			width: 150,
		},
		{
			Header: 'Hoạt Chất',
			accessor: 'actIngName',
			width: 150,
		},
		{
			Header: 'Đơn vị',
			accessor: 'unitofMeasureName',
			width: 120,
		},
		{
			Header: 'Khả dụng',
			accessor: 'availableQuantity',
			width: 80,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{value}</div>
				)
			},
		},
		...SITE !== 'RHM' ? [{
			Header: 'Đơn giá',
			accessor: 'sellingPrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		},
		{
			Header: 'Giá bán',
			accessor: 'salePrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		},
		{
			Header: 'Giá BHYT',
			accessor: 'bhytPrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		},
		{
			Header: 'Giá VP',
			accessor: 'hospitalPrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		},
		{
			Header: 'Giá dịch vụ',
			accessor: 'servicePrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		}] : [],
		{
			Header: 'Loại Thuốc',
			accessor: 'drugTypeName',
			width: 100,
		},
		{
			Header: 'HSD',
			accessor: 'expiryDate',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-center">{formatDateToDDMMYYYY(value)}</div>
				)
			},
		},
	];

	return COLUMNS;
}
export const getColumnsNhapKho = (labelProduct?: string): Column<any>[] => {
	const label = labelProduct ?? 'Thuốc';
	const COLUMNS_NHAP_KHO : Column<any>[] = [
		{
			Header: `Mã ${label}`,
			accessor: 'code',
			width: 100,
		},
		{
			Header: `Tên ${label}`,
			accessor: 'name',
			width: 200,
		},
		{
			Header: 'Số lô',
			accessor: 'consignment',
			width: 200,
		},
		{
			Header: 'Đơn vị',
			accessor: 'unitofMeasureName',
			width: 120,
		},
		...SITE !== 'RHM' ? [{
			Header: 'Đơn giá',
			accessor: 'sellingPrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		},
		{
			Header: 'Giá BHYT',
			accessor: 'bhytPrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		},
		{
			Header: 'Giá VP',
			accessor: 'hospitalPrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		},
		{
			Header: 'Giá dịch vụ',
			accessor: 'servicePrice',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-end">{formatMoney(value)}</div>
				)
			},
		}] : [],
		{
			Header: 'QĐ thầu',
			accessor: 'serialNumber',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="">{value}</div>
				)
			},
		},
		{
			Header: 'HSD',
			accessor: 'expiryDate',
			width: 100,
			Cell: ({ value }: CellProps<any>) => {
				return (
					<div className="text-center">{formatDateToDDMMYYYY(value)}</div>
				)
			},
		},
	];
	return COLUMNS_NHAP_KHO
}
