import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../appContext/AppContext";
import GenerateFormComponent from "../component/generate-form/GenerateFormHTMLNoValid";
import DynamicComponent from "../component/GenerateDynamicComponent";
import { TableCustom } from "../component/table/table-custom/TableCustom";
import { getLstItemTongHop } from "../du-tru-bo-sung-vat-tu-kho/services/services";
import { WMS_STATUS } from "../phan-he-nhap-kho/constants/constansNhapKho";
import { generateForm } from "../utils/AppFunction";
import { CODE, SELECTION_MODE, TYPE_WH, WF_TRACKER } from "../utils/Constant";
import WfButton from "../workflows/components/WfButton";
import { LstVTDuTruColumns } from "./constants/Columns";
import DialogThemPhieuDuTru from "./DialogThemPhieuDuTru";
import "./index.scss";
import { createPhieuTongHop, updatePhieuTongHop } from "./services";
import { formatDateDTO } from "../utils/FormatUtils";
 

interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  modelId: any;
  lstDataChecked?: any[];
  warehouseId?: number;
}

const DialogThemPhieuTongHop = (props: Props) => {
  const { show, onHide, initialValues, modelId, lstDataChecked = [], warehouseId } = props;
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [lstItem, setLstItem] = useState<any>([])
  const [openDialogThemPhieuDuTru, setOpenDialogThemPhieuDuTru] = useState(false);
  const [statusPhieu, setStatusPhieu] = useState<any>({ status: WMS_STATUS.warehouse_export_start });
  const [lstChecked, setLstChecked] = useState(lstDataChecked);
  const [isChangeData, setIsChangeData] = useState(false);


  useEffect(() => {
    // initialValues[modelId]?.id && getSelectedRowsData(initialValues[modelId]?.id);
    if (initialValues[modelId]?.id) {
      const newLstChecked = (initialValues[modelId]?.listMaterialBudget || []).map((id: string) => ({id: id}));
      setLstChecked(newLstChecked);
    }
  }, [initialValues[modelId]?.id]);

  useEffect(() => {
    updateForm();
  }, [modelId]);

  const validationSchema = Yup.object({
    [modelId]: Yup.object({
    })
  })

  const updateForm = async () => {
    const data = await generateForm(modelId);
    setListJsonGenerate(data || []);
  };

  const handleSubmit = async (values: any) => {
    const newValues = values[modelId];
    let id = values[modelId]?.id;
    debugger
    if (!lstItem.length) return toast.warning("Thông tin phiếu không có!")
    try {
      setIsLoading(true)
      const data = {
        slipDto: {
          ...newValues,
          type: TYPE_WH.TONG_HOP_DU_TRU,
          
          warehouseId: warehouseId,
          reqMonth: parseFloat(newValues.reqMonth),
          createdDate: formatDateDTO(newValues.createdDate),
          doCreation: formatDateDTO(newValues.doCreation),
          listMaterialBudget: null,
        },
        listMaterialBudget: lstChecked.map(item => item.id),
        lstItem: lstItem.map((item: any) => ({...item, id: null})),
      };
      let res = newValues.id ? await updatePhieuTongHop(id, data) : await createPhieuTongHop(data);
      if (res?.data?.code === CODE.SUCCESS) {
        !id && onHide();
        setIsChangeData(false);
        toast.success("Thành công");
      }
    } catch (error) {
      console.error("ERR", error)
    } finally {
      setIsLoading(false)
    }
  };

  // const getSelectedRowsData = async (id: string) => {
  //   try {
  //     setIsLoading(true);
  //     const { data } = await
  //       getDsVatTuPhieu({
  //         pageSize: MAX_PAGE_SIZE,
  //         pageIndex: DEFAULT_PAGE_INDEX,
  //         slipId: id,
  //       })
  //     if (CODE.SUCCESS === data.code) {
  //       let values = data.data.content ;
  //       setLstItem(values.map((item: any) => ({ ...item.item, ...item, id: item.id, unitOfMeasureName: item.item?.unitofMeasureName })))
  //     } else {
  //       toast.error('Có lỗi xảy ra, vui lòng thử lại');
  //     };
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleCloseDialogPhieuDuTru = () => {
    setOpenDialogThemPhieuDuTru(false);
  };

  const updateData = async () => {
    try {
      setIsLoading(true);
      const lstId = lstChecked.map(item => item.id);
      const { data } = await getLstItemTongHop(lstId);
      if (CODE.SUCCESS === data.code) {   
        setLstItem(data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    lstChecked && lstChecked.length && updateData();
  }, [lstChecked]);
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, setFieldValue }) => {
          return <Form >
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10 mb-5">
                <div>
                  <GenerateFormComponent
                    warehouseId={initialValues[modelId]?.whReceive?.id || initialValues[modelId]?.whReceiveId}
                    listFieldAuto={listJsonGenerate}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                    modelID={modelId}
                    customComponent={DynamicComponent}
                    isViewSelectThuoc={statusPhieu.status !== WMS_STATUS.warehouse_export_start}
                    isView={statusPhieu.status !== WMS_STATUS.warehouse_export_start}
                  />
                </div>
                 <div className="my-4">
                  {(!initialValues[modelId]?.statusCode || initialValues[modelId]?.statusCode == WMS_STATUS.warehouse_export_start) &&
                    <Button
                      className="btn btn-fill custom-position-btn spaces min-w-90px"
                      onClick={() => {
                        setIsChangeData(true);
                        setOpenDialogThemPhieuDuTru(true);
                      }}
                    >
                      Thay đổi phiếu dự trù
                    </Button>
                  }
                </div>
              </div>
              <div className=" spaces bg-white mb-0">
                <TableCustom<any>
                  key={statusPhieu?.status}
                  className={`${(!initialValues[modelId]?.statusCode || initialValues[modelId]?.statusCode === WMS_STATUS.warehouse_export_start) ? 'h-calc-vh-270px' : 'h-calc-vh-200px'}`}
                  data={lstItem}
                  columns={LstVTDuTruColumns}
                  verticalScroll={true}
                  selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                />
              </div>
            </Modal.Body>
            <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6">
              {(!initialValues[modelId]?.statusCode || initialValues[modelId]?.statusCode === WMS_STATUS.warehouse_export_start) &&
                <Button type="submit" className="btn btn-fill min-w-90px spaces ml-8 mr-4">
                  Lưu
                </Button>
              }
              {!isChangeData &&
                <WfButton
                  tracker={WF_TRACKER.TONG_HOP_BO_SUNG}
                  objectId={values[modelId]?.id}
                  additionalFunc={(data?: any) => {
                    setStatusPhieu({
                      status: data?.currCode
                    })
                    data?.currCode && setFieldValue(`${modelId}.statusCode`, data?.currCode)
                  }}
                />
              }
            </div>
          </Form>
        }}
      </Formik>
      {openDialogThemPhieuDuTru &&
        <DialogThemPhieuDuTru
          show={openDialogThemPhieuDuTru}
          onHide={handleCloseDialogPhieuDuTru}
          warehouseId={warehouseId}
          lstChecked={lstChecked}
          setLstChecked={setLstChecked}
        />
      }
    </Modal>
  );
};

export default DialogThemPhieuTongHop;
