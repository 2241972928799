import { Button, Modal } from "react-bootstrap";
import { generateForm, generateInitValues, generateValidationSchema, roundToDecimalPlaces } from "../../utils/AppFunction";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { PhanHeNhapKhoContext } from "../PhanHeNhapKho";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { addPhieuNhapKho, checkNewItem, getDsThuocNhapKho, updatePhieuNhapKho } from "../services/services";
import { CODE, DEFAULT_PAGE_INDEX, KEY, MAX_PAGE_SIZE, TYPE_WH } from "../../utils/Constant";
import { toast } from "react-toastify";
import WfButton from "../../workflows/components/WfButton";
import TableThongTinNhapThuoc from "./TableThongTinNhapThuoc"
import { CODE_NHAP_KHO, WMS_STATUS } from "../constants/constansNhapKho";
import FormThemVatTu from "./FormThemVatTu";
import { IconButtonSave } from "../../component/IconSvg";
import { TypeNhapKho } from "../models/NhapKhoModel";
import { formatDateDTO } from "../../utils/FormatUtils";
import ButtonIn from "../../component/button-in-phieu";
import DialogKiemKeNhapKho from "./DialogKiemKeNhapKho";

interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  setInitialValues: Dispatch<SetStateAction<any>>
  warehouseId?: any;
  type: TypeNhapKho;
  lstWarehouse: any;
}

const DialogPhieuNhapKho = (props: Props) => {
  const { show, onHide, warehouseId, setInitialValues, initialValues, type, lstWarehouse } = props;
  const { setIsLoading } = useContext(AppContext);
  const { dsNhapKho, setDsNhapKho, detailPhieuNhap, setDetailPhieuNhap } = useContext(PhanHeNhapKhoContext);
  const intl = useIntl();
  const [isView, setIsView] = useState<boolean>(!!detailPhieuNhap.id);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [listJsonGenerateVT, setListJsonGenerateVT] = useState<any>([]);
  const [dataVatTu, setDataVatTu] = useState<any>({});
  const [customFieldReadOnly, setCustomFieldReadOnly] = useState<any>();
  const [initialValuesFormThemVT, setInitialValuesFormThemVT] = useState<any>({});
  // const [initialValuesPhieu, setInitialValuesPhieu] = useState<any>({});
  const [indexRowEdit, setIndexRowEdit] = useState<number | null>(null);
  const [openDialogKiemNhap, setOpenDialogKiemNhap] = useState<boolean>(false);
  const [randomValues, setRandomValues] = useState<number>(); //Để kiểm soát useEffect gọi api lấy thông tin từ phiếu xuất KH

  const validationSchema = {
    [CODE_NHAP_KHO.NHA_CUNG_CAP]: Yup.object({
      nhapKho: Yup.object({
        mateWh: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
        doCreation: Yup.date().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
        inlsendDate: Yup.date().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
        supplier: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      })
    }),
    [CODE_NHAP_KHO.TRA_LE]: Yup.object({
      nhapKho: Yup.object({
        mateWh: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        patientName: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        patientGender: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        mateWhId: Yup.number()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        age: Yup.number()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .min(1, intl.formatMessage({ id: 'VALIDATION.MINNUMBER1' }))
          .nullable(),
        guardian: Yup.string()
          .nullable()
          .when('age', {
            is: (age: number) => (age && age < 12),
            then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
            otherwise: Yup.string().nullable(),
          }),
        guardianPhoneNumber: Yup.string()
          .nullable()
          .when('age', {
            is: (age: number) => (age && age < 12),
            then: Yup.string()
              .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
              .matches(/^[0-9]+$/, intl.formatMessage({ id: 'VALIDATE.NUMBER' }))
              .min(10, intl.formatMessage({ id: 'VALIDATE.MIN10' }))
              .max(11, intl.formatMessage({ id: 'VALIDATE.MAX11' })),
            otherwise: Yup.string().nullable(),
          }),
      }),
    }),
    [CODE_NHAP_KHO.KHAC]: Yup.object({
      nhapKho: Yup.object({
        mateWh: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
        creator: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
        content: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
        doCreation: Yup.date().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      })
    }),
  };

  useEffect(() => {
    const quantity = parseFloat(dataVatTu?.totalQuantity) || 0;
    const price = dataVatTu.sellingPrice || 0;
    const vat = dataVatTu.vat || 0;
    setDataVatTu({
      ...dataVatTu,
      totalAmount: (quantity * price * (1 + vat / 100)).toFixed(2),
    })
  }, [dataVatTu?.totalQuantity, dataVatTu?.sellingPrice, dataVatTu?.vat]);

  const handleAddItem = async (values: any) => {
    const dataItem = values.themVatTuPhieuNhapKho || {};
    const quantityExport = [...dsNhapKho].find((item: any) => item.fakeId === dataItem.fakeId)?.totalQuantity || 0;
    const message = !dataItem.code
      ? 'Chưa chọn vật tư'
      : !dataItem.totalQuantity
        ? 'Chưa nhập số lượng'
        : dataItem.totalQuantity <= 0
          ? 'Số lượng phải lớn hơn 0'
          : !!dataItem.vat && parseFloat(dataItem.vat) <= 0
            ? 'VAT lớn hơn 0%'
            : parseFloat(dataItem.vat) >= 100
              ? 'VAT nhỏ hơn 100%'
              : dataItem.codeExportIsValid && dataItem.totalQuantity > quantityExport
                ? `Số lượng tối đa: ${quantityExport}`
                : type.code === CODE_NHAP_KHO.NHA_CUNG_CAP && (!dataItem.consignment || !dataItem.expiryDate)
                  ? 'Chưa nhập đủ thông tin bắt buộc'
                  : parseFloat(dataItem.discountRate) < 0 || parseFloat(dataItem.discountRate) > 100
                    ? 'Chiết khấu trong khoảng 0-100%' : null
    if (message) {
      toast.warning(message);
      return;
    };

    let data = { code: CODE.SUCCESS, message: '' };
    if (type.code === CODE_NHAP_KHO.NHA_CUNG_CAP || type.code === CODE_NHAP_KHO.KHAC) {
      const response = await checkNewItem({
        serialNumber: dataItem.serialNumber,
        consignment: dataItem.consignment,
        biddingPackage: dataItem.biddingPackage,
        biddingGroup: dataItem.biddingGroup,
        biddingYear: dataItem.biddingYear,
        itemId: dataItem.itemId,
        servicePrice: dataItem.servicePrice || 0,
        sellingPrice: dataItem.sellingPrice || 0,
        expiryDate: dataItem.expiryDate,
        bhytPrice: dataItem.bhytPrice || 0,
        hospitalPrice: dataItem.hospitalPrice || 0,
        salePrice: dataItem.salePrice || 0,
      });

      data = response.data;

      if (CODE.SUCCESS !== data.code) {
        toast.error(data.message);
        return;
      };
    }

    let newLstItem = [...dsNhapKho];
    if (indexRowEdit === null) {
      newLstItem = [...newLstItem, { ...dataItem, item: dataItem.item || dataItem, id: null, warehouseId: warehouseId }];
    } else {
      newLstItem[indexRowEdit] = { ...dataItem, item: dataItem.item || dataItem, id: newLstItem[indexRowEdit].id, warehouseId: warehouseId };
    }
    setIndexRowEdit(null);

    if (type.formVatTu === 'themvtnhapkhorhm') {
      setInitialValuesFormThemVT({
        ...generateInitValues(listJsonGenerateVT),
        serialNumber: dataItem.serialNumber || "",
        biddingPackage: dataItem.biddingPackage || "",
        biddingGroup: dataItem.biddingGroup || "",
        biddingYear: dataItem.biddingYear || "",
        randomValues: Math.random(),
      });
    } else {
      setInitialValuesFormThemVT({ ...generateInitValues(listJsonGenerateVT), randomValues: Math.random() });
    }
    setDsNhapKho(newLstItem);
  };

  const handleFormSubmit = async (values: any) => {
    if (values.nhapKho.codeExport && !values.nhapKho.codeExportIsValid) {
      toast.warning('Mã xuất kho không hợp lệ');
      return;
    }

    if (!dsNhapKho?.length) {
      toast.warning('Chưa có vật tư nhập kho');
      return;
    };
    const dataSubmit = {
      slipDto: {
        ...values?.phieuKiemNhap,
        ...values?.nhapKho,
        doCreation: values?.nhapKho?.id ? formatDateDTO(values?.nhapKho?.doCreation) : formatDateDTO(new Date()),
        type: TYPE_WH.IMPORT,
        warehouseId: warehouseId,
        warehouseExportType: type.code,
      },
      lstItem: [...(dsNhapKho || [])].map(item => {
        return (
          {
            itemId: item?.item?.id,
            totalQuantity: parseFloat(item.totalQuantity ?? "0"),
            ...item?.item,
            ...item,
            sellingPrice: parseFloat(item.sellingPrice),
            totalAmount: parseFloat(item.totalAmount ?? "0"),
            warehouseId: warehouseId,
            pwVat: roundToDecimalPlaces(item.sellingPrice * (1 + item.vat / 100)),
            id: item?.id || null
          }
        )
      }),
    };
    try {
      setIsLoading(true);
      const id = values.nhapKho?.id;
      const { data } = id ? await updatePhieuNhapKho(id, dataSubmit, type.pathApi) : await addPhieuNhapKho(dataSubmit, type.pathApi);
      if (CODE.SUCCESS === data?.code) {
        setIsView(true);
        setInitialValues && setInitialValues(data?.data);
        setDetailPhieuNhap({
          ...data?.data,
          statusId: detailPhieuNhap.statusId,
        });
        getSelectedRowsData(data?.data?.id);
        toast.success(((id) ? "Cập nhật" : "Thêm") + " phiếu nhập kho thành công");
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    }
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getDsThuocNhapKho({ pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, slipId: id });
      const newDsNhapKho = (data?.data?.content || []).map((item: any) => ({ ...item, codeExportIsValid: !!detailPhieuNhap.codeExport }));
      setDsNhapKho(newDsNhapKho);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialogKiemNhap = () => {
    setOpenDialogKiemNhap(false);
  };

  useEffect(() => {
    initialValues?.nhapKho?.id && getSelectedRowsData(initialValues?.nhapKho?.id || detailPhieuNhap?.id);
  }, [initialValues?.nhapKho?.id]);

  useEffect(() => {
    updateForm();
    updateFormVatTu();
  }, []);
  const updateFormVatTu = async () => {
    const data = await generateForm(type.formVatTu, setIsLoading);
    setListJsonGenerateVT(data || []);
    setInitialValuesFormThemVT(generateInitValues(data));
  };

  const updateForm = async () => {
    const data = await generateForm(type.formThongTinChung, setIsLoading);
    setListJsonGenerate(data || []);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEY.ENTER && e.currentTarget.name === "codeExport") {
      setRandomValues(Math.random());
    };
  };

  useEffect(() => {
    setCustomFieldReadOnly({
      codeExport: isView,
      reason: isView,
      notes: isView,
    })
  }, [isView]);
  return (
      <Modal
        centered
        show={show}
        // onHide={onHide}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // initialValues={generateInitValues(listJsonGenerate, 'nhapKho')}
          validationSchema={validationSchema[type.code]}
          // validationSchema={generateValidationSchema(listJsonGenerate, 'nhapKho')}
          onSubmit={handleFormSubmit}
        >
          {({ values, touched, errors, setValues, handleChange, handleSubmit, setFieldValue }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <Modal.Header className="header-modal min-h-40px">
                  <div className="d-flex gap-6">
                    <div
                      className="d-flex align-items-center title-back gap-3 cursor-pointer"
                      onClick={onHide}
                    >
                      <i className="spaces bi bi-chevron-left ml-10"></i>
                      <div className="spaces mr-20 my-3">Quay lại</div>
                    </div>
                    <div className="spaces mt-4 title">
                      {`${values?.nhapKho?.id ? 'Cập nhật' : 'Thêm'} phiếu nhập kho (${type.name})`}
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body className="spaces p-0 bg-white">
                  <div className="spaces px-10">
                    <GenerateFormComponent
                      isView={isView || values.nhapKho.codeExport}
                      customFieldReadOnly={customFieldReadOnly}
                      listFieldAuto={listJsonGenerate}
                      handleChange={handleChange}
                      onKeyDown={handleKeyDown}
                      setValues={setValues}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      modelID={"nhapKho"}
                    />
                  </div>
                  <div className="spaces fw-bold"> <hr /></div>
                  <div className="spaces px-10">
                    <Formik
                      enableReinitialize
                      initialValues={{ themVatTuPhieuNhapKho: { ...initialValuesFormThemVT, supplier: values?.nhapKho?.supplier } }}
                      // initialValues={generateInitValues(listJsonGenerateVT, 'themVatTuPhieuNhapKho')}
                      validationSchema={generateValidationSchema(listJsonGenerateVT, 'themVatTuPhieuNhapKho')}
                      onSubmit={handleAddItem}
                    >
                      <FormThemVatTu
                        LST_WAREHOUSE={lstWarehouse}
                        type={type}
                        listJsonGenerateVT={listJsonGenerateVT}
                        generalValues={values}
                        setGeneralFieldValue={setFieldValue}
                        dsNhapKho={dsNhapKho}
                        setDsNhapKho={setDsNhapKho}
                        warehouseId={warehouseId}
                        isView={isView}
                        indexRowEdit={indexRowEdit}
                        setIndexRowEdit={setIndexRowEdit}
                        initialValuesFormThemVT={initialValuesFormThemVT}
                        setInitialValuesFormThemVT={setInitialValuesFormThemVT}
                        randomValues={randomValues}
                      />
                    </Formik>
                  </div>
                  <TableThongTinNhapThuoc
                    type={type}
                    isView={isView}
                    setInitialValuesFormThemVT={setInitialValuesFormThemVT}
                    indexRowEdit={indexRowEdit}
                    setIndexRowEdit={setIndexRowEdit}
                    warehouseId={warehouseId}
                  />
                </Modal.Body>
                <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6 pl-5">
                  {(values.nhapKho?.id || detailPhieuNhap?.id) && (
                    <WfButton
                      additionalFunc={(data) => {
                        setDetailPhieuNhap({
                          ...detailPhieuNhap,
                          status: data?.currCode,
                          statusCode: data?.currCode,
                          statusId: data?.currId,
                        })
                      }
                      }
                      handleClick={(data: any) => {
                        data.code === "allowed-edit" && setIsView(false);
                      }}
                      tracker="warehouse_receipt"
                      objectId={values.nhapKho?.id || detailPhieuNhap?.id}
                    />
                  )}
                  {!isView &&
                    <Button type="submit" className="btn btn-fill min-w-120px spaces mx-3">
                      <IconButtonSave /> Lưu
                    </Button>
                  }
                  {(isView && (detailPhieuNhap.statusCode === WMS_STATUS.warehouse_receipt_cancel || detailPhieuNhap.statusId === WMS_STATUS.id_warehouse_new)) &&
                    <Button
                      className="btn btn-fill min-w-120px spaces mx-3"
                      onClick={() => {
                        setIsView(false);
                      }}
                    >
                      Sửa phiếu
                    </Button>
                  }
                  {(detailPhieuNhap?.id && type.pathApiPrint) &&
                    <ButtonIn
                      className="spaces min-w-120 mx-3"
                      endpoint={type.pathApiPrint}
                      params={{
                        slipId: detailPhieuNhap?.id,
                      }}
                    />
                  }
                  {(detailPhieuNhap.statusId === WMS_STATUS.warehouse_approved_id && type.code === CODE_NHAP_KHO.NHA_CUNG_CAP) &&
                    <Button
                      className="btn btn-fill spaces mx-3"
                      onClick={() => setOpenDialogKiemNhap(true)}
                    >
                      Biên bản kiểm nhập
                    </Button>
                  }
                </div>
              </Form>
              {openDialogKiemNhap &&
                <DialogKiemKeNhapKho
                  show={openDialogKiemNhap}
                  onHide={handleCloseDialogKiemNhap}
                  slipId={initialValues.nhapKho?.id}
                  generalValues={values}
                  handleFormSubmit={handleFormSubmit}
                />
              }
            </>
          )}
        </Formik>
      </Modal>
  );
};

export default DialogPhieuNhapKho;
