import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import {
  formatDateToDDMMYYYY,
  formatDateAdvanceToString,
  formatMoney,
} from "../../utils/FormatUtils";

export {};

export const columnsDSVatTu: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="STT"
        className="text-center text-light max-w-35"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center "
        data={String(props?.row?.index + 1)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Ngày tạo"
        className="text-white text-center align-middle min-w-80px"
      />
    ),
    id: "doCreation",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center"
        data={formatDateAdvanceToString(
          props.data[props.row.index]?.doCreation
        )}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Ngày chốt số liệu"
        className="text-white text-center align-middle min-w-80px"
      />
    ),
    id: "doCreate",
    Cell: ({ ...props }) => (
      <TableCustomCell
          className="text-center"
        data={formatDateToDDMMYYYY(props.data[props.row.index]?.doCreate)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Ghi chú"
        className=" text-center text-white align-middle bg-pri min-w-500px"
      />
    ),
    id: "notes",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="s-2"
        data={props.data[props.row.index]?.notes}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Ngày bắt đầu"
        className="text-white text-center align-middle min-w-80px"
      />
    ),
    id: "doCreateFrom",
    Cell: ({ ...props }) => (
      <TableCustomCell
          className="text-center"
        data={formatDateToDDMMYYYY(props.data[props.row.index]?.doCreateFrom)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Ngày kết thúc"
        className="text-white text-center align-middle min-w-80px"
      />
    ),
    id: "doCreateTo",
    Cell: ({ ...props }) => (
      <TableCustomCell
          className="text-center"
        data={formatDateToDDMMYYYY(props.data[props.row.index]?.doCreateTo)}
      />
    ),
  },
];
