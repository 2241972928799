import { useContext, useEffect, useState } from "react";
import { OctTable } from "@oceantech/oceantech-ui";
import TextField from "../../component/TextField";
import { Button, Form, Modal } from "react-bootstrap";
import AutocompleteObjectV2 from "../../component/AutocompleteObjectV2";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";
import { QuanLyThauContext } from "../QuanLyThau";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { Formik } from "formik";
import * as Yup from "yup";
import { generateForm } from "../../utils/AppFunction";
import { AppContext } from "../../appContext/AppContext";
import { IconButtonSave } from "../../component/IconSvg";
import { useIntl } from "react-intl";
import { VatTu, initialValuesVatTu } from "./models/VatTuGoiThauModel";
import { searchVT, addVatTu, getDetailVatTu, updateVatTu } from "../services/QuanLyThauServices";
import { CODE, SELECTION_MODE } from "../../utils/Constant";
import { toast } from "react-toastify";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";


interface Props {
  isView: boolean;
}

const TableThongTinVTGoiThau = (props: Props) => {
  const { isView } = props;
  const { setIsLoading } = useContext(AppContext);
  const { detailGoiThau, setDetailGoiThau } = useContext(QuanLyThauContext);
  const intl = useIntl();
  const [openDialogThemVatTu, setOpenDialogThemVatTu] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [dsVatTu, setDsVatTu] = useState<any>([]);
  const [detailVatTu, setDetailVatTu] = useState<any>({});



  useEffect(() => {
    updateForm();
  }, []);

  useEffect(() => {
    detailGoiThau?.id && updatePageData();
  }, [detailGoiThau?.id]);
  const updatePageData = async () => {
    setIsLoading(true);
    try {
      let { data } = await searchVT({
        bidId: detailGoiThau?.id,
      });
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        setDsVatTu(data?.data?.content);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
    }
  };
  const hanldeDeleteRow = (index: number) => {
    const data = [...dsVatTu].filter((_: any, i: number) => i !== index);
    setDsVatTu(data);
  };

  const handleSubmit = (values: any) => {
    const dataSubmit = {
      ...values?.themDanhSachVatTu,
      itemId: values?.itemId || detailVatTu?.itemId,
      bidId: detailGoiThau?.id
      // lstItem: listItems,
    };
    // handleAddRow(values);
    (values?.id || detailVatTu?.id) ? handlerUpdateVatTu(dataSubmit) : handlerADDVatTu(dataSubmit)
    setInitialValues({});
  };


  const handlerUpdateVatTu = async (values?: any) => {
    try {
      setIsLoading(true);
      const { data } =
        await updateVatTu(values?.id || detailVatTu?.id, values)
      if (CODE.SUCCESS === data?.code) {
        setDetailVatTu(data?.data);
        setIsLoading(false);
        toast.success("Cập nhật gói thầu thành công");
        handleCloseDialog()
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  }

  const handlerADDVatTu = async (values?: any) => {
    try {
      setIsLoading(true);
      const { data } = await addVatTu(values);
      if (CODE.SUCCESS === data?.code) {
        setDetailVatTu(data?.data);
        setIsLoading(false);
        handleCloseDialog()
        toast.success("Thêm gói thầu thành công");
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  }


  const handleCloseDialog = () => {
    updatePageData();
    setOpenDialogThemVatTu(false);
    setDetailVatTu({})
  };
  const handleOpenDialog = () => {
    setOpenDialogThemVatTu(true);
    setDetailVatTu({})
  };
  const updateForm = async () => {
    const data = await generateForm("themDanhSachVatTu", setIsLoading);
    setListJsonGenerate(data || []);
  };
  // const handleOpenEditDialog = () => {
  //   setOpenDialogThemVatTu(true);
  //   handlerGetDetailVatTu()
  // };
  const handlerGetDetailVatTu = async (id?: any) => {
    setIsLoading(true);
    try {
      let { data } = await getDetailVatTu(id);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        setDetailVatTu(data?.data)
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
    }
  };

  const columns = [
    // ...(!isView
    //   ? [
    //     {
    //       name: "",
    //       field: "",
    //       headerStyle: {
    //         minWidth: 50,
    //         maxWidth: 50,
    //       },
    //       cellStyle: {
    //         textAlign: "center",
    //       },
    //       render: (row: any, index: number, stt: number) => (
    //         <i
    //           onClick={(e) => hanldeDeleteRow(index)}
    //           className="bi bi-trash3-fill text-danger spaces ml-10  "
    //         ></i>
    //       ),
    //     },
    //   ]
    //   : []),

    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    {
      name: "Thao tác",
      field: "actions",
      headerStyle: {
        minWidth: 80,
        maxWidth: 80,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => (
        <div className="d-flex justify-content-center ">
          <>
            <CustomIconButton
              variant="edit"
              onClick={() => {
                handlerGetDetailVatTu(row?.id)
                setOpenDialogThemVatTu(true);
              }}
            >
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>

            <CustomIconButton
              variant="delete"
              onClick={() => {
                // handlerGetDetailVatTu(row?.id)
                // setOpenDialogThemVatTu(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          </>
        </div>
      ),
    },
    {
      name: "Mã vật tư",
      field: "",
      headerStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => row?.code,
    },
    {
      name: "Tên vật tư",
      field: "",
      headerStyle: {
        minWidth: 220,
      },
      cellStyle: {
        textAlign: "lefr",
      },
      render: (row: any, index: number, stt: number) => row?.name,
    },
    {
      name: "Đơn vị",
      field: "UoM",
      headerStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) =>
        row?.UoM || "",
    },
    {
      name: "Số lượng",
      field: "quantity",
      headerStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.quantity || "",
    },
    {
      name: "SL nhập",
      field: "batchNum",
      headerStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row?.batchNum,
    },
    {
      name: "Giá thầu",
      field: "bidPrice",
      headerStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row?.bidPrice,
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.totalAmount,
    },
    {
      name: "Số đăng ký",
      field: "regisNum",
      headerStyle: {
        minWidth: 100,
        maxWidth: 150,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => row?.regisNum,
    },
    {
      name: "Gói thầu",
      field: "bidPackage",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row?.bidPackage,
    },
    {
      name: "Nhóm thầu",
      field: "bidGroup",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row?.bidGroup,
    },
    {
      name: "STT thầu",
      field: "bidserNum",
      headerStyle: {
        minWidth: 80,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row?.bidserNum,
    },
    {
      name: "Nhà cung cấp",
      field: "suppName",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row?.suppName,
    },
  ];

  const validationSchema = Yup.object({
    // themDanhSachVatTu: Yup.object({
    //   columns2ColumnsText: Yup.object().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    // }),
  });

  return (
    <div className="spaces mt-12 border-table custom-table">
      <div className="d-flex justify-content-between spaces py-4">
        <h4 className="spaces mt-8 title">Thông tin vật tư</h4>
        <div>
          {detailGoiThau?.id &&
            <Button
              className="btn btn-fill min-w-90px"
              onClick={handleOpenDialog}
            >
              <i className="bi bi-plus-lg text-pri fs-3"></i> Thêm
            </Button>
          }
        </div>
      </div>
      <OctTable
        className="spaces"
        columns={columns}
        data={dsVatTu}
        noToolbar
        clearToolbar
        noPagination
        fixedColumnsCount={-1}
      />
      {openDialogThemVatTu && (
        <Modal
          centered
          show={openDialogThemVatTu}
          onHide={handleCloseDialog}
          size="xl"
        >
          <Formik<VatTu>
            enableReinitialize
            initialValues={initialValuesVatTu}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              setValues,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <h4 className="title">Cập nhật vật tư</h4>
                </Modal.Header>
                <Modal.Body className="spaces px-10">
                  <GenerateFormComponent
                    listFieldAuto={listJsonGenerate}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    setValues= {setValues}
                    itemEdit={detailVatTu}
                    touched={touched}
                    errors={errors}
                    modelID="themDanhSachVatTu"
                  />
                  <div></div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex gap-3 w-100 justify-content-center">
                    <Button
                      type="submit"
                      className="btn btn-fill"
                    >
                      <i className="bi bi-pencil"></i> Nhập tiếp
                    </Button>
                    <Button
                      type="submit"
                      className="btn btn-fill"
                    // onClick={handleCloseDialog}
                    >
                      <IconButtonSave /> Lưu
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </div>
  );
};

export default TableThongTinVTGoiThau;
