import * as Yup from "yup";
import {useIntl} from "react-intl";
import {Formik, Form} from "formik";
import {toast} from "react-toastify";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../../../appContext/AppContext";
import {CODE, TYPE_WH} from "../../../../../utils/Constant";
import WfButton from "../../../../../workflows/components/WfButton";
import {formatDateAdvanceToString, formatDateDTO} from "../../../../../utils/FormatUtils";
import TableThongTinNhapHoanTra from "./TableThongTinNhapHoanTra";
import DialogThongTinYLenh from "./DialogThongTinYLenh";
import {getDetailsPhieuXuatKho, getDsHoanTraXuatKho, updatePhieuXuatKho} from "../../../../services/servicesXuatKho";
import {IconButtonSave} from "../../../../../component/IconSvg";
import {WMS_STATUS} from "../../../../constants/constantsXuatKho";

interface Props {
    LST_WAREHOUSE: any;
    show: boolean;
    onHide: () => void;
    initialValues: any;
    setInitialValues: Dispatch<SetStateAction<any>>;
    warehouseId: number,
    warehouseExportType?: any;
}

export interface HoanTraContextProps {
    dsPhieuHoanTra: any;
    setDsPhieuHoanTra: SetStateAction<any>;
}

const initialContext = {
    dsPhieuHoanTra: [],
    setDsPhieuHoanTra: () => {
    },
}

export const HoanTraContext = createContext<HoanTraContextProps>(initialContext);

const DialogNhapHoanTra = (props: Props) => {
    const {
        show,
        onHide,
        initialValues,
        setInitialValues,
        warehouseId,
        LST_WAREHOUSE,
        warehouseExportType,
    } = props;

    const {setIsLoading} = useContext(AppContext);
    const [dsPhieuHoanTra, setDsPhieuHoanTra] = useState([]);
    const [workFlow, setWorkFlow] = useState<any>(null);
    const [isChangeData, setIsChangeData] = useState<boolean>(false);

    const intl = useIntl();
    const [isView, setIsView] = useState<boolean>(false);
    const [openDialogThonTinYLenh, setOpenDialogThonTinYLenh] = useState<boolean>(false);

    const validationSchema = Yup.object({
        xuatKho: Yup.object({}),
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        const listItems = [...dsPhieuHoanTra].map((item: any) => ({
            ...item,
            accept: item?.accept ? item?.accept : item?.totalQuantity,
        }));

        const dataForm = values?.xuatKho || {};

        const dataSubmit = {
            slipDto: {
                ...dataForm,
                type: TYPE_WH.EXPORT,
                warehouseId: dataForm.id ? dataForm.warehouseId : warehouseId,
                doCreation: dataForm.id ? formatDateDTO(dataForm.doCreation) : formatDateDTO(new Date()),
                warehouseExportType: warehouseExportType.code,
            },
            lstItem: listItems,
        };

        try {
            const {data} = await updatePhieuXuatKho(dataForm.id, dataSubmit, warehouseExportType.modelId);
            if (CODE.SUCCESS === data?.code) {
                setIsChangeData(false);
                toast.success(((values.xuatKho?.id) ? "Cập nhật" : "Thêm") + " phiếu xuất kho thành công");
                setInitialValues(data.data);
            } else {
                setIsLoading(false);
                let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
                toast.warning(errorMesage);
            }
        } catch (error) {
            toast.warning("Xảy ra lỗi, vui lòng thử lại!");
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getSelectedRowsData = async (id: string) => {
        try {
            setIsLoading(true);
            const {data} = await getDsHoanTraXuatKho({
                slipId: id,
                type: warehouseExportType.type,
            });

            const updateList = (data?.data?.content || []).map((item: any) => ({
                ...item,
                accept: item?.accept ? item?.accept : item?.requireQuantity,
            }))

            setDsPhieuHoanTra(updateList);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const updatePageData = async (id: string) => {
        try {
            setIsLoading(true);
            const {data} = await getDetailsPhieuXuatKho(id, warehouseExportType?.modelId);

            setInitialValues(data?.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialogThonTinYLenh(false);
    }
    useEffect(() => {
        if (initialValues?.xuatKho?.id) {
            updatePageData(initialValues?.xuatKho?.id);
            getSelectedRowsData(initialValues?.xuatKho?.id);
        }
    }, [initialValues?.xuatKho?.id]);

    useEffect(() => {
        setIsView(!(workFlow?.currId === WMS_STATUS.WAIT_APPROVE && isChangeData))
    }, [workFlow, isChangeData]);

    const value = useMemo(() => ({
        dsPhieuHoanTra: dsPhieuHoanTra,
        setDsPhieuHoanTra: setDsPhieuHoanTra,
    }), [dsPhieuHoanTra])

    return (
        <HoanTraContext.Provider value={value}>
            <Modal
                centered
                show={show}
                // onHide={onHide}
                size="xl"
                fullscreen
                className="page-full spaces h-calc-vh-50"
            >
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({values, touched, errors, handleChange, handleSubmit, setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header className="header-modal min-h-40px">
                                <div className="d-flex w-100 gap-6 justify-content-between">
                                    <div className="d-flex gap-8">
                                        <div className="d-flex align-items-center title-back gap-3 cursor-pointer"
                                             onClick={() => onHide()}>
                                            <i className="spaces bi bi-chevron-left ml-10"></i>
                                            <div className="spaces mr-20 my-3">Quay lại</div>
                                        </div>
                                        <div
                                            className="spaces mt-4 title">{`${values?.xuatKho?.id ? 'Cập nhật' : 'Thêm'} phiếu xuất kho (${warehouseExportType.name})`}</div>
                                    </div>
                                </div>
                            </Modal.Header>
                            <Modal.Body className="spaces p-0 bg-white">
                                <div className="spaces px-10 pt-10">
                                    <Row>
                                        <Col xs={4}>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-export">Khoa phòng: </span>
                                                <span>{initialValues.xuatKho?.departmentName}</span>
                                            </div>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-export">Ngày xuất: </span>
                                                <span>{formatDateAdvanceToString(initialValues.xuatKho?.exportDate)}</span>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-export">Người tổng hợp: </span>
                                                <span>{initialValues.xuatKho?.synthesizer}</span>
                                            </div>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-export">Người duyệt: </span>
                                                <span>{initialValues.xuatKho?.reviewer}</span>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-export">Ngày yêu cầu: </span>
                                                <span>{formatDateAdvanceToString(initialValues.xuatKho?.doExecution)}</span>
                                            </div>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-export">Người xuất: </span>
                                                <span>{initialValues.xuatKho?.exporter}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="spaces px-10">
                                    <Button type="button" className="btn btn-fill spaces min-w-120 mt-10"
                                            onClick={() => setOpenDialogThonTinYLenh(true)}>
                                        Thông tin y lệnh
                                    </Button>
                                </div>
                                <TableThongTinNhapHoanTra
                                    setIsChangeData={setIsChangeData}
                                    workFlow={workFlow}
                                    isView={isView}
                                />
                                {openDialogThonTinYLenh &&
                                  <DialogThongTinYLenh
                                    LST_WAREHOUSE={LST_WAREHOUSE}
                                    warehouseId={warehouseId}
                                    show={openDialogThonTinYLenh}
                                    onHide={handleCloseDialog}
                                    initialValues={initialValues}
                                  />
                                }
                                <div className="d-flex spaces pl-6 py-10 h-50">
                                    {(!isView) &&
                                      <Button type="submit" className="btn btn-fill spaces min-w-120 mx-3">
                                        <IconButtonSave/> Lưu
                                      </Button>
                                    }
                                    {!isChangeData &&
                                      <WfButton
                                        tracker={warehouseExportType.tracker}
                                        objectId={initialValues?.xuatKho?.id}
                                        additionalFunc={(flow: any) => setWorkFlow(flow)}
                                      />
                                    }
                                </div>
                            </Modal.Body>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </HoanTraContext.Provider>
    );
};

export default DialogNhapHoanTra;