import axios from "axios";
import { localStorageItem } from "../../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_HOAN_TRA = `${API}/slip/themPhieuXuatHoanTra`;

export const getDsVatTuPhieu = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};

export const getListMedicine = (searchData: any) => {
  const url = `${API}/slip/search-detail`
  return axios.post(url, searchData);
};

export const createPhieuHoanTraVatTu = (data: any) => {
  let url = API_HOAN_TRA;
  return axios.post(url, data);
};
export const updatePhieuHoanTraVatTu = (id: string, data: any) => {
  let url = `${API_HOAN_TRA}/${id}`;
  return axios.put(url, data);
};

export const printPhieu = (params: any) => {
  const url = `${API}/api/download/cabinet/word/export-for-use`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};
