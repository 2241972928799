import * as Yup from "yup";
import {useIntl} from "react-intl";
import {Formik, Form} from "formik";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../appContext/AppContext";
import {TYPE_WH, WF_TRACKER} from "../../../utils/Constant";
import WfButton from "../../../workflows/components/WfButton";
import {formatDateAdvanceToString} from "../../../utils/FormatUtils";
import TableThongTinNhapHoanTra from "./TableThongTinNhapHoanTra";
import DialogThongTinYLenh from "./DialogThongTinYLenh";
import {TypeNhapKho} from "../../models/NhapKhoModel";
import {getDetailsPhieuNhapKho, getDsHoanTraNhapKho} from "../../services/services";


interface Props {
    LST_WAREHOUSE: any;
    show: boolean;
    onHide: () => void;
    initialValues: any;
    setInitialValues: Dispatch<SetStateAction<any>>;
    warehouseId: number,
    type: TypeNhapKho;
}

export interface HoanTraContextProps {
    dsPhieuHoanTra: any;
    setDsPhieuHoanTra: SetStateAction<any>;
}

const initialContext = {
    dsPhieuHoanTra: [],
    setDsPhieuHoanTra: () => {
    },
}

export const HoanTraContext = createContext<HoanTraContextProps>(initialContext);

const DialogNhapHoanTra = (props: Props) => {
    const {
        show,
        onHide,
        initialValues,
        setInitialValues,
        warehouseId,
        LST_WAREHOUSE,
        type,
    } = props;

    const {setIsLoading} = useContext(AppContext);
    const [dsPhieuHoanTra, setDsPhieuHoanTra] = useState([]);
    const [workFlow, setWorkFlow] = useState<any>(null);
    const intl = useIntl();
    const [openDialogThonTinYLenh, setOpenDialogThonTinYLenh] = useState<boolean>(false);

    const validationSchema = Yup.object({
        nhapKho: Yup.object({}),
    });

    // nhập kho chỉ có wf không có hàm lưu
    const handleSubmit = async (values: any) => {
    };

    const getSelectedRowsData = async (id: string) => {
        try {
            setIsLoading(true);
            const {data} = await getDsHoanTraNhapKho({
                slipId: id,
                type: TYPE_WH.IMPORT,
            });

            const updateList = (data?.data?.content || []).map((item: any) => ({
                ...item,
                accept: item?.accept ? item?.accept : item?.requireQuantity,
            }))

            setDsPhieuHoanTra(updateList);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const updatePageData = async (id: string) => {
        try {
            setIsLoading(true);
            const {data} = await getDetailsPhieuNhapKho(id, type?.pathApi);

            setInitialValues(data?.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialogThonTinYLenh(false);
    }
    useEffect(() => {
        if (initialValues?.nhapKho?.id) {
            updatePageData(initialValues?.nhapKho?.id);
            getSelectedRowsData(initialValues?.nhapKho?.id)
        }
    }, [initialValues?.nhapKho?.id]);

    const value = useMemo(() => ({
        dsPhieuHoanTra: dsPhieuHoanTra,
        setDsPhieuHoanTra: setDsPhieuHoanTra,
    }), [dsPhieuHoanTra])

    return (
        <HoanTraContext.Provider value={value}>
            <Modal
                centered
                show={show}
                // onHide={onHide}
                size="xl"
                fullscreen
                className="page-full spaces h-calc-vh-50"
            >
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({values, touched, errors, handleChange, handleSubmit, setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header className="header-modal min-h-40px">
                                <div className="d-flex w-100 gap-6 justify-content-between">
                                    <div className="d-flex gap-8">
                                        <div className="d-flex align-items-center title-back gap-3 cursor-pointer"
                                             onClick={onHide}>
                                            <i className="spaces bi bi-chevron-left ml-10"></i>
                                            <div className="spaces mr-20 my-3">Quay lại</div>
                                        </div>
                                        <div
                                            className="spaces mt-4 title">{`${values?.nhapKho?.id ? 'Cập nhật' : 'Thêm'} phiếu nhập kho (${type.name})`}</div>
                                    </div>
                                </div>

                            </Modal.Header>
                            <Modal.Body className="spaces p-0 bg-white">
                                <div className="spaces px-10 pt-10">
                                    <Row>
                                        <Col xs={4}>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-import">Khoa phòng: </span>
                                                <span>{initialValues.nhapKho?.departmentName}</span>
                                            </div>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-import">Ngày nhập: </span>
                                                <span>{formatDateAdvanceToString(initialValues.nhapKho?.exportDate)}</span>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-import">Người yêu cầu: </span>
                                                <span>{initialValues.nhapKho?.synthesizer}</span>
                                            </div>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-import">Người nhập: </span>
                                                <span>{initialValues.nhapKho?.reviewer}</span>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="flex spaces py-5">
                                                <span className="title-info-import">Ngày yêu cầu: </span>
                                                <span>{formatDateAdvanceToString(initialValues.nhapKho?.doExecution)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="spaces px-10">
                                    <Button type="button" className="btn btn-fill spaces min-w-120 mt-10"
                                            onClick={() => setOpenDialogThonTinYLenh(true)}>
                                        Thông tin y lệnh
                                    </Button>
                                </div>
                                <TableThongTinNhapHoanTra/>
                                {openDialogThonTinYLenh &&
                                  <DialogThongTinYLenh
                                    LST_WAREHOUSE={LST_WAREHOUSE}
                                    warehouseId={warehouseId}
                                    show={openDialogThonTinYLenh}
                                    onHide={handleCloseDialog}
                                    initialValues={initialValues}/>
                                }
                                <div className="d-flex spaces pl-6 py-10 h-50">
                                    <WfButton
                                        tracker={WF_TRACKER.HOAN_TRA}
                                        objectId={initialValues?.nhapKho?.id}
                                        additionalFunc={(flow: any) => setWorkFlow(flow)}
                                    />
                                </div>
                            </Modal.Body>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </HoanTraContext.Provider>
    );
};

export default DialogNhapHoanTra;