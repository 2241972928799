import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { IconButtonSave } from "../../component/IconSvg";
import { generateForm } from "../../utils/AppFunction";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { PhanHeDieuChuyenContext } from "../PhanHeDieuChuyen";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, TYPE_WH, WAREHOUSE_ID, WF_TRACKER } from "../../utils/Constant";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { addPhieuDieuChuyen, getDsThuocDieuChuyen, updateDsThuocDieuChuyen, updatePhieuDieuChuyen } from "../services/services";
import WfButton from "../../workflows/components/WfButton";
import FormThemVatTu from "./FormThemVatTu";
import TableThongTinDieuChuyen from "./TableThongTinDieuChuyen";
import { formatDateDTO } from "../../utils/FormatUtils";
import { CODE_DIEU_CHUYEN, FILTER_WAREHOUSE, STATUS_DIEU_CHUYEN, TYPE_DIEU_CHUYEN } from "../constants/constansDieuChuyen";


interface Props {
  LST_WAREHOUSE: any;
  show: boolean;
  onHide: () => void;
  initialValues: any;
  setInitialValues: Dispatch<SetStateAction<any>>;
  warehouseId: number,
  warehouseTransferType: any,
}

const DialogPhieuDieuChuyen = (props: Props) => {
  const { show, onHide, initialValues, setInitialValues, warehouseId, warehouseTransferType, LST_WAREHOUSE } = props;
  const { setIsLoading } = useContext(AppContext);
  const { dsDieuChuyen, setDsDieuChuyen } = useContext(PhanHeDieuChuyenContext);
  const [indexRowEdit, setIndexRowEdit] = useState<number | null>(null);
  const [initialValuesVatTu, setInitialValuesVatTu] = useState({});
  const [workFlow, setWorkFlow] = useState<any>(null);
  const [isChangeData, setIsChangeData] = useState<boolean>(false);
  const intl = useIntl();
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [customFieldReadOnly, setCustomFieldReadOnly] = useState<any>({});
  const [customSearchObject, setCustomSearchObject] = useState<any>({});
  const [isView, setIsView] = useState<boolean>(false);

  const validationSchema = Yup.object({
    dieuChuyen: Yup.object({
      reqWh: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      transferWh: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      dateCreate: Yup.date().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      creator: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
      notes: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
    }),
  });

  const validationSchemaFormThemVT = Yup.object({
    vatTuDieuChuyen: Yup.object({}),
  });

  const updateForm = async () => {
    const data = await generateForm(warehouseTransferType.formThongTinChung, setIsLoading);
    setListJsonGenerate(data || []);
  };

  const handleSubmit = async (values: any) => {
    const listItems = [...dsDieuChuyen].map(item => ({
      ...item,
      totalQuantity: parseFloat(item.totalQuantity),
      accept: values?.dieuChuyen?.id ? parseFloat(item.accept || 0) : parseFloat(item.totalQuantity || 0),
      totalAmount: parseFloat(item?.totalAmount),
    }));

    const dataForm = values?.dieuChuyen || {};

    const dataSubmit = {
      slipDto: {
        ...dataForm,
        type: TYPE_WH.TRANSFER,
        warehouseId: dataForm.id ? dataForm.warehouseId : warehouseId,
        dateCreate: dataForm.id ? formatDateDTO(dataForm.dateCreate) : formatDateDTO(new Date()),
        createdDate: dataForm.id ? formatDateDTO(dataForm.createdDate) : formatDateDTO(new Date()),
        doCreation: dataForm.id ? formatDateDTO(dataForm.doCreation) : formatDateDTO(new Date()),
        warehouseExportType: warehouseTransferType.code,
      },
      lstItem: listItems,
    };
    try {
      setIsLoading(true);
      const { data } = dataForm.id ? await updatePhieuDieuChuyen(dataForm.id, dataSubmit) : await addPhieuDieuChuyen(dataSubmit);
      if (values.dieuChuyen?.id) {
        await updateDsThuocDieuChuyen({
          slipId: values.dieuChuyen?.id,
          type: TYPE_WH.TRANSFER,
          lstItem: listItems,
        });
      }
      if (CODE.SUCCESS === data?.code) {
        setIsChangeData(false);
        setIsLoading(false);
        toast.success(((values.dieuChuyen?.id) ? "Cập nhật" : "Thêm") + " phiếu điểu chuyển thành công");
        setInitialValues(data.data);
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getDsThuocDieuChuyen({
        pageSize: MAX_PAGE_SIZE,
        pageIndex: DEFAULT_PAGE_INDEX,
        slipId: id,
        type: warehouseTransferType.code === CODE_DIEU_CHUYEN.NHAP_DIEU_CHUYEN ? TYPE_DIEU_CHUYEN.YEU_CAU : null,
      });
      setDsDieuChuyen(data?.data?.content || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddItem = async (values: any) => {
    const dataItem = values.vatTuDieuChuyen || {};
    const errrorMessage = !dataItem.code
      ? "Chưa chọn thuốc/VT"
      : !dataItem.totalQuantity
        ? "Chưa nhập số lượng"
        : parseFloat(dataItem.totalQuantity) <= 0
          ? "Số lượng phải lớn hơn 0"
          : parseFloat(dataItem.totalQuantity) > parseFloat(values.vatTuDieuChuyen.availableQuantity)
            ? "Số lượng khả dụng không đủ"
            : null;

    if (errrorMessage) {
      toast.warning(errrorMessage);
      return;
    };

    let newLstItem = [...dsDieuChuyen];
    if (indexRowEdit === null) {
      newLstItem = [
        ...newLstItem,
        { ...dataItem, id: null, itemId: dataItem.id, warehouseId: warehouseId }
      ];
    } else {
      newLstItem[indexRowEdit] = { ...dataItem, id: newLstItem[indexRowEdit]?.id, itemId: dataItem.itemId || dataItem.item?.id, warehouseId: warehouseId };
    }
    setIndexRowEdit(null);
    setInitialValuesVatTu({ randomValues: Math.random() });
    setDsDieuChuyen(newLstItem);
  };

  useEffect(() => {
    initialValues?.dieuChuyen?.id && getSelectedRowsData(initialValues?.dieuChuyen?.id);
  }, [initialValues?.dieuChuyen?.id]);

  useEffect(() => {
    updateForm();
  }, []);

  useEffect(() => {
    let codeNot = LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE]?.reqWh;
    setCustomFieldReadOnly({
      transferWh: warehouseId === WAREHOUSE_ID.NHA_THUOC || isView,
    });

    if (FILTER_WAREHOUSE.includes(warehouseId)) {
      codeNot = `DR01,${LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE]?.reqWh}`
    };
    setCustomSearchObject({
      transferWh: {
        codeNot: codeNot,
      },
    });
  }, [warehouseId, isView]);

  useEffect(() => {
    setIsView(workFlow && workFlow?.currId !== STATUS_DIEU_CHUYEN.NEW && workFlow?.currId !== STATUS_DIEU_CHUYEN.NEW_EXPORT);
  }, [workFlow]);

  return (
    <Modal
      centered
      show={show}
      // onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex w-100 gap-6 justify-content-between">
                <div className="d-flex gap-8">
                  <div
                    className="d-flex align-items-center title-back gap-3 cursor-pointer"
                    onClick={onHide}
                  >
                    <i className="spaces bi bi-chevron-left ml-10"></i>
                    <div className="spaces mr-20 my-3">Quay lại</div>
                  </div>
                  <div className="spaces mt-4 title">{initialValues?.dieuChuyen?.reqWhId === warehouseId ? `${warehouseTransferType.name}` : "Phiếu tiếp nhận"}</div>
                </div>
              </div>

            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10">
                {initialValues?.dieuChuyen?.reqWhId === warehouseId &&
                  <>
                    <h4 className="title spaces mb-5 pt-10">Thông tin chung</h4>
                    <GenerateFormComponent
                      listFieldAuto={listJsonGenerate}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      errors={errors}
                      modelID={"dieuChuyen"}
                      customComponent={DynamicComponent}
                      isView={isView}
                      customFieldReadOnly={customFieldReadOnly}
                      customSearchObject={customSearchObject}
                    />
                  </>}
                {initialValues?.dieuChuyen?.transferWhId === warehouseId &&
                  <Row className="spaces pt-10">
                    <Col xs={6}>
                      <div className="flex spaces py-5">
                        <span className="title-info-transfer">Tạo phiếu: </span>
                        <span>{initialValues.dieuChuyen?.createSlip}</span>
                      </div>
                      <div className="flex spaces py-5">
                        <span className="title-info-transfer">Người lập: </span>
                        <span>{initialValues.dieuChuyen?.creator}</span>
                      </div>
                      <div className="flex spaces py-5">
                        <span className="title-info-transfer">Người duyệt: </span>
                        <span>{initialValues.dieuChuyen?.approvalSlip}</span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="flex spaces py-5">
                        <span className="title-info-transfer">{warehouseTransferType.code === CODE_DIEU_CHUYEN.NHAP_DIEU_CHUYEN ? "Kho yêu cầu: " : "Kho xuất: "} </span>
                        <span>{initialValues.dieuChuyen?.reqWhName}</span>
                      </div>
                      <div className="flex spaces py-5">
                        <span className="title-info-transfer">{warehouseTransferType.code === CODE_DIEU_CHUYEN.NHAP_DIEU_CHUYEN ? "Kho xuất: " : "Kho nhận: "}</span>
                        <span>{initialValues.dieuChuyen?.transferWhName}</span>
                      </div>
                    </Col>
                  </Row>
                }
              </div>
              {initialValues?.dieuChuyen?.reqWhId === warehouseId &&
                <>
                  <div className="spaces fw-bold"> <hr /></div>
                  <div className="spaces px-10">
                    <Formik
                      enableReinitialize
                      initialValues={initialValuesVatTu}
                      validationSchema={validationSchemaFormThemVT}
                      onSubmit={handleAddItem}
                    >
                      <FormThemVatTu
                        generalValues={values}
                        isView={isView}
                        indexRowEdit={indexRowEdit}
                        warehouseTransferType={warehouseTransferType}
                      />
                    </Formik>
                  </div>
                </>}
              <TableThongTinDieuChuyen
                setInitialValuesVatTu={setInitialValuesVatTu}
                indexRowEdit={indexRowEdit}
                setIndexRowEdit={setIndexRowEdit}
                setIsChangeData={setIsChangeData}
                workFlow={workFlow}
                isView={isView}
                isTransfer={warehouseId === initialValues.dieuChuyen?.transferWhId}
              />
              <div className="d-flex spaces pl-6 py-10 h-50">
                {!isChangeData &&
                  <WfButton
                    tracker={warehouseTransferType.tracker}
                    objectId={initialValues?.dieuChuyen?.id}
                    type={initialValues?.dieuChuyen?.reqWhId === warehouseId ? TYPE_DIEU_CHUYEN.YEU_CAU : TYPE_DIEU_CHUYEN.NHAN_YEU_CAU}
                    additionalFunc={(flow: any) => setWorkFlow(flow)}
                  />
                }
                {(!isView || (workFlow?.currId === STATUS_DIEU_CHUYEN.WAIT_APPROVE && isChangeData)) &&
                  <Button type="submit" className="btn btn-fill spaces min-w-120 mx-3">
                    <IconButtonSave /> Lưu
                  </Button>
                }
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DialogPhieuDieuChuyen;
