import { Formik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import FormThemMoi from "./components/FormThemMoi";
import "./ThemMoiPhieuXuat.scss";
import { initialPhieuXuat, PhieuXuat } from "../../models/XuatKhoModel";
import { PhanHeXuatKhoContext } from "../../PhanHeXuatKho";
import { addPhieuXuatKho, getDetailsPhieuXuatKho, updatePhieuXuatKho } from "../../services/servicesXuatKho";
import { toast } from "react-toastify";
import { CODE, TYPE_WH } from "../../../utils/Constant";
import { generateForm, generateInitValues, generateValidationSchema, handleCaculateTotalAmount } from "../../../utils/AppFunction";
import { EXPORT_CODE } from "../../constants/constantsXuatKho";
import { formatStringDateDTO } from '../../../utils/FormatUtils';

type Props = {
  MATE_WH_DEFAUILT: any;
  show: boolean;
  onHide: () => void;
  dataEdit: PhieuXuat;
  warehouseId: number;
  warehouseExportType?: any
};

const DialogThemMoiPhieuXuatKho: FC<Props> = (props) => {

  const { show, onHide, warehouseExportType, dataEdit, warehouseId, MATE_WH_DEFAUILT = [] } = props;
  const intl = useIntl();
  const { setIsLoading } = useContext(AppContext);
  const { onReset, thongTinPhieu, setThongTinPhieu } = useContext(PhanHeXuatKhoContext);
  const [listJsonGeneratePhieuXuat, setListJsonGeneratePhieuXuat] = useState<any>([]);

  const [thongTinPhieuXuat, setThongTinPhieuXuat] = useState<PhieuXuat>(initialPhieuXuat);
  const [validationSchemaSelected, setValidationSchemaSelected] = useState<any>(Yup.object({}));

  const validationSchema = {
    themMoiPhieuXuatKhoKH: Yup.object({
      phieuXuatKhoAdd: Yup.object({
        mateWh: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        patientName: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        patientGender: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        mateWhId: Yup.number()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        age: Yup.number()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .min(1, intl.formatMessage({ id: 'VALIDATION.MINNUMBER1' }))
          .nullable(),
        guardian: Yup.string()
          .nullable()
          .when('age', {
            is: (age: number) => (age && age < 12),
            then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
            otherwise: Yup.string().nullable(),
          }),
        guardianPhoneNumber: Yup.string()
          .nullable()
          .when('age', {
            is: (age: number) => (age && age < 12),
            then: Yup.string()
              .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
              .matches(/^[0-9]+$/, intl.formatMessage({ id: 'VALIDATE.NUMBER' }))
              .min(10, intl.formatMessage({ id: 'VALIDATE.MIN10' }))
              .max(11, intl.formatMessage({ id: 'VALIDATE.MAX11' })),
            otherwise: Yup.string().nullable(),
          }),
      }),
    }),
    themMoiPhieuXuatKhoNCC: Yup.object({
      phieuXuatKhoAdd: Yup.object({
        mateWh: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        invoiceDate: Yup.date()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        supplier: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        doCreation: Yup.date()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
      })
    }),
    phieuXuatKhac: Yup.object({
      phieuXuatKhoAdd: Yup.object({
        mateWh: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        doCreation: Yup.date()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
        content: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
          .nullable(),
      })
    }),
  };
  useEffect(() => {
    if (dataEdit.id) {
      updatePageData();
    };
  }, [dataEdit.id]);

  useEffect(() => {
    warehouseExportType.modelId && getListform();
  }, [warehouseExportType.modelId]);
  const getListform = async () => {
    let data = await generateForm(warehouseExportType?.modelId, setIsLoading)
    setListJsonGeneratePhieuXuat(data)
  }
  useEffect(() => {
    const wareHouseKey = warehouseId as keyof typeof MATE_WH_DEFAUILT;
    setThongTinPhieuXuat({
      ...thongTinPhieuXuat,
      phieuXuatKhoAdd: {
        ...thongTinPhieuXuat?.phieuXuatKhoAdd,
        ...MATE_WH_DEFAUILT[wareHouseKey]
      }
    })
  }, [warehouseId]);

  useEffect(() => {
    const validationSchemaKey = warehouseExportType.modelId as keyof typeof validationSchema;
    setValidationSchemaSelected(validationSchema?.[validationSchemaKey]);
  }, [warehouseExportType.modelId]);

  const handleFormSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      if (!thongTinPhieu.dsThuoc?.length) {
        toast.warning('Chưa có vật tư xuất kho');
        return;
      }
      const listItems = [...(thongTinPhieu.dsThuoc || [])].map(item => (
        {
          ...item?.item,
          id: item?.id,
          consignment: item?.consignment || item?.item?.consignment,
          serialNumber: item?.serialNumber || item?.item?.serialNumber,
          itemId: item?.item?.itemId || item?.itemId || item?.item?.id || item?.id,
          totalQuantity: parseFloat(item.totalQuantity || 0),
          totalAmount: parseFloat(item?.totalAmount || item?.item?.totalAmount),
          warehouseId: warehouseId,
        }
      ));

      const price = warehouseExportType?.code === EXPORT_CODE.KHACH_HANG ? "hospitalPrice" : "sellingPrice"
      const total = handleCaculateTotalAmount(listItems, price)
      const dataSubmit = {
        slipDto: {
          ...values.phieuXuatKhoAdd,
          doCreation: values.phieuXuatKhoAdd?.id ? formatStringDateDTO(values.phieuXuatKhoAdd?.doCreation) : formatStringDateDTO(new Date()),
          type: warehouseExportType?.type,
          warehouseId: warehouseId,
          warehouseExportType: warehouseExportType?.code || null,
          amount: total
        },
        lstItem: listItems,
      };

      const { data } = values.phieuXuatKhoAdd?.id ? await updatePhieuXuatKho(values.phieuXuatKhoAdd?.id, dataSubmit, warehouseExportType.modelId) : await addPhieuXuatKho(dataSubmit, warehouseExportType.modelId);
      if (CODE.SUCCESS === data.code) {
        toast.success(`${values.phieuXuatKhoAdd?.id ? 'Cập nhật' : 'Thêm'} phiếu xuất kho thành công`);
        setThongTinPhieuXuat({
          ...thongTinPhieuXuat,
          phieuXuatKhoAdd: {
            ...data.data,
          }
        })
        setThongTinPhieu({
          ...thongTinPhieu,
          phieuXuatKhoAdd: {
            ...data.data,
          }
        })
        if (warehouseExportType.code === EXPORT_CODE.KHACH_HANG) {
          onReset();
          onHide();
        }
      } else {
        toast.error(data?.data[0]?.errorMessage || "Có lỗi xảy ra, vui lòng thử lại !");
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại !");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getDetailsPhieuXuatKho(dataEdit.id, warehouseExportType.modelId);
      setThongTinPhieuXuat({
        ...thongTinPhieuXuat,
        phieuXuatKhoAdd: {
          ...data.data,
          id: dataEdit.id || data.data?.id,
        }
      });
      setThongTinPhieu({
        ...thongTinPhieu,
        phieuXuatKhoAdd: {
          ...data.data,
        }
      })
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        centered
        show={show}
        // onHide={onHide}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Modal.Header className="header-modal min-h-40px ">
          <div className="d-flex gap-6">
            <div
              className="d-flex align-items-center title-back gap-3 cursor-pointer"
              onClick={() => {
                onReset();
                onHide();
              }}
            >
              <i className="spaces bi bi-chevron-left ml-10"> </i>
              <div className="spaces mr-20 my-3">Quay lại</div>
            </div>
            <div className="spaces mt-4 title">
              {`${thongTinPhieuXuat?.phieuXuatKhoAdd?.id ? 'Cập nhật' : 'Thêm mới'} phiếu xuất kho (${warehouseExportType.name})`}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="spaces p-0 mt-1 bg-white">
          <Formik<any>
            enableReinitialize
            initialValues={{ ...thongTinPhieuXuat }}
            validationSchema={validationSchemaSelected}
            // initialValues={generateInitValues(listJsonGeneratePhieuXuat, 'phieuXuatKhoAdd')}
            // validationSchema={generateValidationSchema(listJsonGeneratePhieuXuat, "phieuXuatKhoAdd")}
            onSubmit={handleFormSubmit}
          >
            <FormThemMoi listJsonGeneratePhieuXuat={listJsonGeneratePhieuXuat} isReload={show} onHide={onHide} warehouseExportType={warehouseExportType} warehouseId={warehouseId} />
          </Formik>
        </Modal.Body>

      </Modal>
    </div>
  );
};
export default DialogThemMoiPhieuXuatKho;
