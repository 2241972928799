import {useContext} from "react";
import {OctTable} from "@oceantech/oceantech-ui";
import {formatMoney} from "../../../utils/FormatUtils";
import {HoanTraContext} from "./DialogNhapHoanTra";

interface Props {
    title?: string,
};

const TableThongTinNhapHoanTra = (props: Props) => {
    const { title } = props;

    const {dsPhieuHoanTra} = useContext(HoanTraContext);

    const columns = [
        {
            name: "STT",
            field: "",
            headerStyle: {
                minWidth: 50,
                maxWidth: 50,
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (row: any, index: number, stt: number) => <span>{stt}</span>,
        },
        {
            name: "Mã thuốc",
            field: "itemCode",
            headerStyle: {
                minWidth: 110,
                maxWidth: 110,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.itemCode,
        },
        {
            name: "Tên thuốc",
            field: "itemName",
            headerStyle: {
                minWidth: 220,
                maxWidth: 220,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.itemName,
        },
        {
            name: "ĐVT",
            field: "unitOfMeasureName",
            headerStyle: {
                minWidth: 100,
                maxWidth: 100,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.unitOfMeasureName,
        },
        {
            name: "SL yêu cầu",
            field: "",
            headerStyle: {
                minWidth: 100,
                maxWidth: 100,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => formatMoney(row.totalQuantity),
        },
        {
            name: "SL trả",
            field: "",
            headerStyle: {
                minWidth: 120,
                maxWidth: 120,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => row.accept,
        },
        {
            name: "Số lô",
            field: "",
            headerStyle: {
                minWidth: 120,
                maxWidth: 120,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.consignment,
        },
        {
            name: "Đơn giá",
            field: "",
            headerStyle: {
                minWidth: 120,
                maxWidth: 120,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => formatMoney(row.sellingPrice || row.item?.sellingPrice),
        },
        {
            name: "Thành tiền",
            field: "",
            headerStyle: {
                minWidth: 120,
                maxWidth: 120,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => {
                const quantity = (parseFloat(row.accept) || 0);
                const price = row.sellingPrice || 0;
                const vat = row.vat || 0;
                const result = quantity * price * (1 + vat / 100);
                return formatMoney(result);
            },
        },
    ];

    return (
        <div className="spaces mt-12 border-table custom-table">
            {title && <h4 className="spaces mt-8 ml-10 title">{title}</h4>}
            <OctTable
                className={`table-view-dieu-chuyen custom-header-table`}
                columns={columns}
                data={dsPhieuHoanTra}
                noToolbar
                clearToolbar
                noPagination
                fixedColumnsCount={-1}
            />
        </div>
    );
};

export default TableThongTinNhapHoanTra;
