import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { DanhSachPhieuInterface } from "../models/NhapKhoModel"
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { formatDateAdvanceToString, formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";
import { CODE_NHAP_KHO } from "./constansNhapKho";

export const DanhSachPhieuColumns: ReadonlyArray<
  Column<DanhSachPhieuInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "doCreation",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Số HĐ"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "invoiceNumber",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.invoiceNumber}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày gửi HĐ"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "inlsendDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.inlsendDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Nhà cung cấp"
          className="text-center text-white align-middle bg-pri min-w-125px"
        />
      ),
      id: "supplierName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.supplierName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Nhập kho"
          className="text-left text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "receWarehouseName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.receWarehouseName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Người giao"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "deliverer",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.deliverer}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Người nhận"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "recipient",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.recipient}
        />
      ),
    },
  ];
  
  export const DanhSachPhieuKHColumns: ReadonlyArray<
  Column<DanhSachPhieuInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "doCreation",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Kho"
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "mateWhName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.mateWhName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Tên BN"
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "patientName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.patientName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Giới tính"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "patientGender",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 text-center"
          data={
            props.data[props.row.index]?.patientGenderName ??
            props.data[props.row.index]?.patientGender === 'G001' ? 'Nam' : 'Nữ'
          }
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Tuổi"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "age",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 text-center"
          data={props.data[props.row.index]?.age}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Lý do hoàn"
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "reason",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.reason}
        />
      ),
    },
  ];

  export const DanhSachPhieuKhac: ReadonlyArray<
  Column<DanhSachPhieuInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "doCreation",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Loại phiếu"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "loaiPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data="Nhập khác"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Nơi chỉ định"
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "receWarehouseName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.receWarehouseName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ghi chú"
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "content",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.content}
        />
      ),
    },
  ];

export const DanhSachNhapThuocColumns: ReadonlyArray<
  Column<DanhSachPhieuInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Tên thuốc"
          className=" text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "tenThuoc",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.item?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="ĐVT"
          className=" text-center text-white align-middle bg-pri min-w-50px"
        />
      ),
      id: "dvt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.item?.unitofMeasure}
          className="text-left"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Quy cách đóng gói"
          className=" text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "quyCach",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.item?.packaging}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Số thầu"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "soThau",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.soThau}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="SL nhập"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "slNhap",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.quanReceived)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="SL quy đổi"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "slQuyDoi",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.slQuyDoi)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Số lô nhập"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "soLoNhap",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.consignment}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Ngày hết hạn"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "ngayHetHan",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.expiryDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Đơn giá"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "donGia",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.unitPrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Giá quy đổi"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "giaQuyDoi",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.giaQuyDoi)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Giá nhập kho"
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "giaNhapKho",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.purchasePrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Giá bán"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "giaBan",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.sellingPrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="VAT"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "vat",
      Cell: ({ ...props }) => {
        const row = props.data[props.row.index] || {};
        return (
          <TableCustomCell
            className="text-end s-2"
            data={formatMoney(row.giaNhapKho * row.vat / 100)}
          />
        )
      }
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuInterface>
          tableProps={props}
          title="Thành tiền"
          className="text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "thanhTien",
      Cell: ({ ...props }) => {
        const row = props.data[props.row.index] || {};
        return (
          <TableCustomCell
            className="text-end s-2"
            data={formatMoney(row.giaNhapKho * row.slQuyDoi + (row.giaNhapKho * row.vat / 100))}
          />
        )
      }
    },
  ];

export const DanhSachHoanTra: ReadonlyArray<
    Column<DanhSachPhieuInterface>
> = [
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="TT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "custom",
        Cell: ({...props}) => (
            <div className="text-center">
                <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
            </div>
        )
        ,
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Ngày tạo"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "doCreation",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-center s-2"
                data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Mã phiếu"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "code",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.code}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Ngày y lệnh"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "loaiPhieu",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-center s-2"
                data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Ngày lập"
                className="text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "receWarehouseName",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Khoa lập phiếu"
                className="text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "department",
        Cell: ({...props}) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.content}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Nội dung"
                className="text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "content",
        Cell: ({...props}) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.content}
            />
        ),
    },
];

export const DanhSachThongTinYLenh: ReadonlyArray<
    Column<DanhSachPhieuInterface>
> = [
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Mã bệnh nhân"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "patientCode",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.patientCode}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Tên bệnh nhân"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "patientName",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.patientName}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Mã phiếu xuất BN"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "formCode",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.formCode}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Mã thuốc"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "itemCode",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.itemCode}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Tên thuốc"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "itemName",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.itemName}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Số lô"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "consignment",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.consignment}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Số lượng khả dụng"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "availableQuantity",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-left s-2"
                data={props.data[props.row.index]?.availableQuantity}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<DanhSachPhieuInterface>
                tableProps={props}
                title="Số lượng yêu cầu"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "requireQuantity",
        Cell: ({...props}) => (
            <TableCustomCell
                className="text-right s-2"
                data={props.data[props.row.index]?.requireQuantity}
            />
        ),
    },
];

export const COLUMNS_NHAP_KHO = {
    [CODE_NHAP_KHO.TRA_LE]: DanhSachPhieuKHColumns,
    [CODE_NHAP_KHO.NHA_CUNG_CAP]: DanhSachPhieuColumns,
    [CODE_NHAP_KHO.KHAC]: DanhSachPhieuKhac,
    [CODE_NHAP_KHO.HOAN_TRA]: DanhSachHoanTra,
}

export const summaryStatus = [
  {
    statusName: 'Chờ duyệt',
    summary: 3,
    color: '#28A745',
  },
  {
    statusName: 'Đã duyệt',
    summary: 3,
    color: '#DC3545',
  }
]