import React, { useContext, useEffect, useState } from "react";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTML"
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../../PhanHeThongKe"

type Props = {
  ThongTinTab?: any;
  dataVatTu?: any;
};

const TabThongTinVatTu = ({ ThongTinTab, dataVatTu }: Props) => {

  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const { dataVatTuView } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);

  useEffect(() => {
    getListform();
  }, [dataVatTu])

  const getListform = async () => {
    let data = await generateForm(ThongTinTab?.modelId || "", setIsLoading)
    setListJsonGenerate(data)
  }
  return (
    <div className="bg-white spaces mb-6 fl-1 mx-10">
      <GenerateFormHTML
        isView={false}
        listFieldAuto={listJsonGenerate}
        itemEdit={dataVatTuView}
        isSave={false}
        isUpdate={true}
      />
    </div>
  );
};

export default TabThongTinVatTu;
