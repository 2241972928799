import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { IInPhieuParams } from "../../models/params";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_XUAT_KHO = API + '/slip';

export const searchPhieuXuatKho = (searchData: any) => {
  const url = `${API_XUAT_KHO}/search`;
  return axios.post(url, searchData);
};

export const addPhieuXuatKho = (data: any,modelId?:any) => {
  const url = `${API_XUAT_KHO}/${modelId}`;
  return axios.post(url, data);
};

export const updatePhieuXuatKho = (idPhieuXuatKho: string, data: any,modelId?:any) => {
  const url = `${API_XUAT_KHO}/${modelId}/${idPhieuXuatKho}`;
  return axios.put(url, data);
};

export const getDetailsPhieuXuatKho = (idPhieuXuatKho: string | undefined,modelId?:any) => {
  const url = `${API_XUAT_KHO}/${modelId}/${idPhieuXuatKho}`;
  return axios.get(url);
};

export const deletePhieuXuatKho = (idPhieuXuatKho: string | undefined) => {
  const url = `${API_XUAT_KHO}/${idPhieuXuatKho}`;
  return axios.delete(url);
};

export const getDsThuocXuatKho = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};

export const getDsHoanTraXuatKho = (searchData: any) => {
  const url = `${API}/inventory-item/orders-issue-slip/search`;
  return axios.post(url, searchData);
};

export const getDsThongTinYLenh = (searchData: any) => {
  const url = `${API}/inventory-item/orders-issue-slip/detail`;
  return axios.post(url, searchData);
};

export const updateDsThuocXuatKho = (data: any) => {
  const url = `${API}/inventory-item/update-items`;
  return axios.put(url, data);
};

export const addVatTuXuatKho = (data: any) => {
  const url = `${API}/inventory-item`;
  return axios.post(url, data);
};

export const updateVatTuXuatKho = (id: string, data: any) => {
  const url = `${API}/inventory-item/${id}`;
  return axios.put(url, data);
};

export const deleteVatTuXuatKho = (id: string) => {
  const url = `${API}/inventory-item/${id}`;
  return axios.delete(url);
};

export const inPhieuXuatKhachHang = (params: IInPhieuParams) => {
  let url = `${API}/api/download/word/receipt-request`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inPhieuXuatNCC = (params: IInPhieuParams) => {
  let url = `${API}/api/download/supplier-delivery-note`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};
export const inPhieuXuatHoaDon = (params: IInPhieuParams) => {
  let url = `${API}/api/download/word/issue-health-insurance`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inPhieuXuatHuy = (params: IInPhieuParams) => {
  let url = `${API}/api/download/export-issue-for-disposal`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inPhieuXuatKhac = (params: IInPhieuParams) => {
  let url = `${API}/api/download/other-delivery-note`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inPhieuXuatHaoPhi = (params: IInPhieuParams) => {
  let url = `${API}/api/download/department-fee-issuance-slip`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};