import axios from "axios";
import {
  DEFAULT_PAGE_INDEX,
  MAX_PAGE_SIZE,
  TYPE_WH,
} from "../../utils/Constant";
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { IInPhieuParams } from "../../models/params";
const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_SLIP = `${API}/slip`;
const API_INVENTORY = `${API}/inventory-item`;

export const searchDSBenhNhan = (searchData: any) => {
  let url = `${API_SLIP}/search`;
  return axios.post(url, searchData);
};

export const addPhieuThu = (data: any, modelId?:any) => {
  const url = `${API_SLIP}/${modelId}`;
  return axios.post(url, data);
};

export const searchVatTuThanhToan = (searchData: any) => {
  let url = `${API_INVENTORY}/search`;
  return axios.post(url, {
    ...searchData,
    pageSize: MAX_PAGE_SIZE,
    pageIndex: DEFAULT_PAGE_INDEX,
    type: TYPE_WH.EXPORT,
  });
};

export const addVatTuThanhToan = (data: any) => {
  let url = `${API_INVENTORY}`;
  return axios.post(url, data);
};

export const updateVatTuThanhToan = (id: string, data: any) => {
  let url = `${API_INVENTORY}/${id}`;
  return axios.put(url, data);
};

export const deleteVatTuThanhToan = (id: string) => {
  let url = `${API_INVENTORY}/${id}`;
  return axios.delete(url);
};

export const updateThongTinThanhToan = (id: string, data: any) => {
  let url = `${API_SLIP}/thanhToan/${id}`;
  return axios.put(url, data);
};

export const inPhieuThuNgan = (params: IInPhieuParams) => {
  let url = `${API}/api/download/pharmacy-receipt`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
}

export const inPhieuXuatLe = (params: IInPhieuParams) => {
  let url = `${API}/api/download/word/receipt-request`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
}

export const getDetailThongTinThanhToan = (id: string) => {
  let url = `${API_SLIP}/thanhToan/${id}`;
  return axios.get(url);
};

export const cancelApproval = (data: any, id: string) => {
  let url = `${API_SLIP}/save/reason-cancel/${id}`;
  return axios.put(url, data);
};

export const getListPhieuThu = (code: string) => {
  let url = `${API_SLIP}/search`;
  return axios.post(url, {
    type: 11,
    pageIndex: 1,
    pageSize: 100,
    code: code,
  })
};