import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AppContext } from "../../appContext/AppContext";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import InputSearch from "../../component/InputSearch";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import TextField from "../../component/TextField";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  KEY,
  SELECTION_MODE,
  STATUS_CODE,
  TYPE_WH,
  WAREHOUSE_ID,
  WF_TRACKER
} from "../../utils/Constant";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { COLUMNS_NHAP_KHO } from "../constants/ColumnsNhapKho";
import "../PhanHeNhapKho.scss";
import DialogPhieuNhapKho from "./DialogPhieuNhapKho";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { deletePhieuNhapKho, searchPhieuNhapKho } from "../services/services";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { toast } from "react-toastify";
import { PhanHeNhapKhoContext } from "../PhanHeNhapKho";
import { getSummaryStatusByType } from "../../workflows/services/WfWorkflowsService";
import { formatDateFromDTOCustom, formatDateToDTOCustom } from "../../utils/FormatUtils";
import {
  CODE_NHAP_KHO,
  INIT_GENERAL_VALUES,
  TYPE_NHAP_KHO,
  TYPE_NHAP_KHO_MAU,
  TYPE_NHAP_KHO_NOI_TRU
} from "../constants/constansNhapKho";
import { TypeNhapKho } from "../models/NhapKhoModel";
import AutocompleteV2 from "../../component/AutocompleteObjectV2";
import ButtonIn from "../../component/button-in-phieu";
import DialogNhapHoanTra from "./HoanTra/DialogNhapHoanTra";
interface Props {
  LST_WAREHOUSE: any
  warehouseId: any
};

export const FormNhapKho = (props: Props) => {
  const { warehouseId, LST_WAREHOUSE } = props;
  const [typeNhapKho, setTypeNhapKho] = useState<TypeNhapKho>(TYPE_NHAP_KHO[1]);
  const [dsPhieuNhapKho, setDsPhieuNhapKho] = useState<any[]>([]);
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<boolean>(false);
  const { setDsNhapKho, setDetailPhieuNhap } = useContext(PhanHeNhapKhoContext);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>({ ...INIT_GENERAL_VALUES, ...LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE] });
  const { setIsLoading } = useContext(AppContext);
  const [summaryStatus, setSummaryStatus] = useState<any>([]);
  const [searchData, setSearchData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [options, setOptions] = useState<any>([])
  const [separateExport, setIsSeparateExport] = useState<boolean>(true);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });

  useEffect(() => {
    if (warehouseId === WAREHOUSE_ID.KHO_MAU) {
      setOptions(TYPE_NHAP_KHO_MAU)
      setTypeNhapKho(TYPE_NHAP_KHO_MAU[0])
    } else if (warehouseId === WAREHOUSE_ID.NHA_THUOC) {
      setOptions(TYPE_NHAP_KHO)
      setTypeNhapKho(TYPE_NHAP_KHO[1])
    } else if (warehouseId === WAREHOUSE_ID.NOI_TRU) {
      setOptions(TYPE_NHAP_KHO_NOI_TRU)
      setTypeNhapKho(TYPE_NHAP_KHO[1])
    } else {
      setOptions(TYPE_NHAP_KHO.slice(1))
      setTypeNhapKho(TYPE_NHAP_KHO[1])
    }
  }, [warehouseId]);

  useEffect(() => {
    if (warehouseId === WAREHOUSE_ID.NOI_TRU && typeNhapKho.code === CODE_NHAP_KHO.HOAN_TRA) {
      setIsSeparateExport(false);
    } else {
      setIsSeparateExport(true);
    }
  }, [warehouseId, typeNhapKho])

  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deletePhieuNhapKho(itemSelected.id);
      if (CODE.SUCCESS === res.status) {
        toast.success("Xóa phiếu thành công");
        handleCloseDialog();
      }
    } catch (error: any) {
      const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
      toast.error(message)
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleChangeTypeNhapKho = (type?: any) => {
    setTypeNhapKho(type)
    setSearchData({})
  }

  const handleCloseDialog = () => {
    updatePageData();
    setDsNhapKho([]);
    setDetailPhieuNhap({});
    setOpenDialogConfirmDelete(false);
    setOpenDialogThemMoi(false);
    setItemSelected({
      ...INIT_GENERAL_VALUES,
      ...LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE],
      randomValue: Math.random()
    }
    );
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const { data } = await searchPhieuNhapKho({
        ...searchData,
        warehouseExportType: typeNhapKho.code,
        keyword: searchData?.keyword?.trim() || null,
        doCreationFromDate: formatDateFromDTOCustom(searchData?.doCreationFromDate),
        doCreationToDate: formatDateToDTOCustom(searchData?.doCreationToDate),
        type: TYPE_WH.IMPORT,
        pageSize: rowsPerPage,
        pageIndex: page,
        warehouseId: warehouseId
      });
      const resSummary = await getSummaryStatusByType(
        WF_TRACKER.NHAP_KHO,
        TYPE_WH.IMPORT,
        {
          warehouseExportType: typeNhapKho.code,
          warehouseId: warehouseId,
          fromDate: formatDateFromDTOCustom(searchData?.doCreationFromDate) || null,
          toDate: formatDateToDTOCustom(searchData?.doCreationToDate) || null,
        }
      );
      if (CODE.SUCCESS === resSummary.data?.code) {
        setSummaryStatus(resSummary.data.data);
      };
      if (CODE.SUCCESS === data.code) {
        setDsPhieuNhapKho(data?.data?.content || []);
        setConfigTable({
          totalPages: Math.ceil(data.data.pageable.totalElements / data.data.pageable.pageSize),
          totalElements: data?.data?.pageable?.totalElements,
          // numberOfElements: data?.data?.pageable?.pageNumber,
        })
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, [rowsPerPage, page, typeNhapKho.code]);

  useEffect(() => {
    setItemSelected({ ...INIT_GENERAL_VALUES, ...LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE] });
  }, [LST_WAREHOUSE]);

  const handlerSearchKeyWorld = () => {
    setPage(DEFAULT_PAGE_INDEX);
    setRowsPerPage(20);
    updatePageData()
  }
  const columnsActions = {

    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">
          <CustomIconButton
            variant="edit"
            onClick={() => {
              setItemSelected(props.cell.row.original);
              setDetailPhieuNhap(props.cell.row.original)
              setOpenDialogThemMoi(true);
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>

          {props.cell.row.original?.statusCode !== STATUS_CODE.DA_NHAP_KHO &&
            <CustomIconButton
              variant="delete"
              onClick={() => {
                setItemSelected(props.cell.row.original);
                setOpenDialogConfirmDelete(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          }
          {typeNhapKho.pathApiPrint &&
            <ButtonIn
              isIcon
              endpoint={typeNhapKho.pathApiPrint}
              params={{
                slipId: props.cell.row.original?.id,
              }}
            />
          }
      </div>
    )
  };

  return (
    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex bg-white spaces width-100 " >
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10 min-w-180">
                Danh sách nhập kho
              </h4>
              <div className="flex bg-white spaces width-20 mt-2 " >
                <AutocompleteV2
                    options={options}
                    name="name"
                    className="autocomplete-custom-renderform  radius spaces width-100 h-29"
                    onChange={(type: TypeNhapKho) => handleChangeTypeNhapKho(type)}
                    value={typeNhapKho}
                    isClearable={false}
                />
              </div>
            </div>
            <div className="d-flex  mt-1 flex-column spaces width-100 ">
              <div className="bg-white">
                <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  {separateExport && <div className=" bg-white flex align-items-center mr-13 spaces">
                    <Button className="btn-fill ml-10 spaces min-w-90px btn btn-primary" onClick={() => setOpenDialogThemMoi(true)}>
                      <i className="bi bi-plus-lg text-pri fs-3"></i>
                      Thêm
                    </Button>
                  </div>}
                  <div className="d-flex spaces mx-10 ">
                    <TextField
                      label="Từ ngày"
                      name="doCreationFromDate"
                      type="date"
                      value={searchData?.doCreationFromDate || ''}
                      className="flex"
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="d-flex spaces mr-10">
                    <TextField
                      label="Đến ngày"
                      name="doCreationToDate"
                      type="date"
                      className="flex"
                      value={searchData?.doCreationToDate || ''}
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="flex-grow-1 ">
                    <InputSearch
                      name="keyword"
                      value={searchData?.keyword || ''}
                      placeholder="Tìm kiếm theo mã phiếu"
                      handleChange={handleChangeSearchData}
                      handleSearch={() => handlerSearchKeyWorld()}
                      handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && handlerSearchKeyWorld()}
                      className="spaces h-31 pr-4 radius-3"
                    />
                  </div>
                </div>
                <div className="spaces bg-white mb-0">
                  <TableCustom<any>
                    className="spaces h-bang-ds-phieu"
                    data={dsPhieuNhapKho}
                    columns={COLUMNS_NHAP_KHO[typeNhapKho.code]}
                    columnsActions={columnsActions}
                    verticalScroll={true}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={Number(configTable.totalPages)}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    customComponent={<WfStatusSummary summaryStatus={summaryStatus} col={4} />}
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
          </div>
          {openDialogThemMoi && (separateExport ?
              <DialogPhieuNhapKho
                  lstWarehouse={LST_WAREHOUSE}
                  warehouseId={warehouseId}
                  type={typeNhapKho}
                  show={openDialogThemMoi}
                  onHide={handleCloseDialog}
                  initialValues={{nhapKho: {...itemSelected, codeExportIsValid: !!itemSelected.codeExport}}}
                  setInitialValues={setItemSelected}
              /> : <DialogNhapHoanTra
                  LST_WAREHOUSE={LST_WAREHOUSE}
                  warehouseId={warehouseId}
                  type={typeNhapKho}
                  show={openDialogThemMoi}
                  onHide={handleCloseDialog}
                  initialValues={{nhapKho: {...itemSelected}}}
                  setInitialValues={setItemSelected}
              />)
          }
          {openDialogConfirmDelete &&
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handleCloseDialog}
              onYesClick={handleDelete}
              title="Xóa phiếu nhập kho"
              message={`Bạn chắc chắn muốn xóa phiếu: ${itemSelected.code} ?`}
              yes="Xóa"
              close="Hủy"
            />
          }
        </div>

      </div>

    </div>
  )
}

export default FormNhapKho;
