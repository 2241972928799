import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { IInPhieuParams } from "../../models/params";
import { PHIEU_IN_ENDPOINT } from "../../utils/Constant";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_NHAP_KHO = API + "/slip";
const API_PATH_THONG_KE_VAT_TU = API + '/item';

export const addPhieuNhapKho = (data: any, pathApi: string) => {
  let url = API_NHAP_KHO + `/${pathApi}`;
  return axios.post(url, data);
};
export const updatePhieuNhapKho = (id: string, data: any, pathApi: string) => {
  let url = API_NHAP_KHO + `/${pathApi}/${id}`;
  return axios.put(url, data);
};

export const addVatTu = (data: any) => {
  let url = API + `/inventory-item`;
  return axios.post(url, data);
};
export const updateVatTu = (id: string, data: any) => {
  let url = API + `/inventory-item/${id}`;
  return axios.put(url, data);
};

export const searchPhieuNhapKho = (searchData: any) => {
  const url = `${API_NHAP_KHO}` + `/search`;
  return axios.post(url, searchData);
};

export const deletePhieuNhapKho = (id: string) => {
  const url = `${API_NHAP_KHO}` + `/${id}`;
  return axios.delete(url);
};
export const searchListVatTu = (searchObject?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/search`;
  return axios.post(url, searchObject);
};
export const getDsThuocNhapKho = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};
export const updateDsThuocNhapKho = (data: any) => {
  const url = `${API}/inventory-item/update-items`;
  return axios.put(url, data);
};
export const checkNewItem = (params: any) => {
  const url = `${API}/inventory-item/check-serial-number`;
  return axios.get(url, { params: params });
};
export const getListVatTuByCode = (code: string) => {
  let url = `${API_NHAP_KHO}/get-by-code/thanhToan/${code}`;
  return axios.get(url);
};

export const inPhieuNhapNCC = (params: IInPhieuParams) => {
  let url = `${API}/api/download/supplier-receipt-request`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const downloadPhieuKiemNhap = (parameters: any, type?: string) => {
  const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiReportUrl"];
  const url = `${API}/${PHIEU_IN_ENDPOINT.KIEM_NHAP}`;
  return axios.post(url, { parameters: parameters?.parameters, type: type }, { responseType: 'blob' });
};

export const getDetailsPhieuNhapKho = (id: string | undefined, modelId?:any) => {
  const url = `${API_NHAP_KHO}/${modelId}/${id}`;
  return axios.get(url);
};

export const getDsHoanTraNhapKho = (searchData: any) => {
  const url = `${API}/inventory-item/orders-issue-slip/search`;
  return axios.post(url, searchData);
};

export const getDsThongTinYLenh = (searchData: any) => {
  const url = `${API}/inventory-item/orders-issue-slip/detail`;
  return axios.post(url, searchData);
};