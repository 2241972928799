import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_PHIEU_LINH = `${API}/slip/formPhieuLinh`;
const API_XUAT_SD = `${API}/slip/formXuatSuDungKhoaPhong`;
const API_BO_SUNG_CO_SO_TU_TRUC = `${API}/slip/themphieubosungcosotutruc`;
const API_PATH_THONG_KE_VAT_TU = API + '/item';

export const searchDSTuTruc = (searchData: any) => {
  const url = `${API}/category/warehouses?level=2`;
  return axios.get(url, { params: searchData });
};

export const getListVatTu = (searchData: any) => {
  const url = `${API}/category/warehouses`;
  return axios.get(url, { params: searchData });
};

export const searchListVatTu = (searchObject?: any) => {
  let url = API_PATH_THONG_KE_VAT_TU + `/search`;
  return axios.post(url, searchObject);
};

export const searchPhieuTuTruc = (searchData: any) => {
  const url = `${API}/slip/search`;
  return axios.post(url, searchData);
};

export const deletePhieuTuTruc = (id: string) => {
  const url = `${API}/slip/${id}`;
  return axios.delete(url);
};

export const getDsVatTuPhieu = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};

export const updateDsVatTuTuTruc = (data: any) => {
  const url = `${API}/inventory-item/update-items`;
  return axios.put(url, data);
};

//Phieu Linh
export const createPhieuLinh = (data: any) => {
  const url = API_PHIEU_LINH;
  return axios.post(url, data);
};

export const updatePhieuLinh = (id: string, data: any) => {
  const url = `${API_PHIEU_LINH}/${id}`;
  return axios.put(url, data);
};

//Xuat su dung khoa/phong

export const createPhieuXuatSD = (data: any) => {
  const url = API_XUAT_SD;
  return axios.post(url, data);
};

export const updatePhieuXuatSD = (id: string, data: any) => {
  const url = `${API_XUAT_SD}/${id}`;
  return axios.put(url, data);
};

// Bổ sung cơ số tủ trực
export const createPhieuBoSungCoSoTuTruc = (data: any) => {
  const url = API_BO_SUNG_CO_SO_TU_TRUC;
  return axios.post(url, data);
};

export const updatePhieuBoSungCoSoTuTruc = (id: string, data: any) => {
  const url = API_BO_SUNG_CO_SO_TU_TRUC + `/${id}`;
  return axios.put(url, data);
};

export const caculatorTonKhoTuTruc = (data: any) => {
  const url = API + `/item/by-id-and-warehouse`;
  return axios.post(url, data);
};

export const printPhieu = (params: any) => {
  const url = `${API}/api/download/cabinet/word/add-base-quantity`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};
