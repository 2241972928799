import axios from "axios";
import { localStorageItem } from "../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_REPORT = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiReportUrl"] || process.env.REACT_APP_WMS_API_URL;

export const searchKiemKe = (searchData: any) => {
  let url = `${API}/slip/search `;
  return axios.post(url, searchData);
};
export const searchListKho = (searchObject?: any) => {
  let url = API + `/category/warehouses?level=1`;
  return axios.get(url, searchObject);
};
export const inPhieuTheKho = (params: any) => {
  let url = `${API}/api/download/warehouse-card`;
  return axios.post<Blob>(url, params, { responseType: "blob" });
};
export const addPhieuKiemKe = (data?: any, modelId?: any) => {
  let url = API + `/slip/${modelId}`;
  return axios.post(url, data);
};
export const updatePhieuKiemKe = (data?: any, modelId?: any) => {
  let url = API + `/slip/${modelId}/${data?.id}`;
  return axios.put(url, data);
};
export const deleteKiemKe = (data?: any) => {
  let url = API + `/slip/${data?.id}`;
  return axios.delete(url);
};
export const printPhieuKiemKe = (params: any) => {
  const url = `${API_REPORT}/rest/reports/run/a3737eb1-24c8-d854-bd1a-bbb7f1e7284c`;
  return axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const downloadInv = (parameters: any, type?: string) => {
  const url = `${API_REPORT}/rest/reports/run/a3737eb1-24c8-d854-bd1a-bbb7f1e7284c`;
  return axios.post(url, { parameters: parameters?.parameters, type: type }, { responseType: 'blob' });
};