import { Formik } from "formik";
import { FC, ReactNode, createContext } from "react";
import TableDSVatTu from "./component/TableDSVatTu";

export interface QuanLyKhoContextProps {}

interface TheKhoProps {
  warehouseId?: number | undefined;
  customTitle?: ReactNode;
}

const initialContext = {};

export const QuanLyKhoContext =
  createContext<QuanLyKhoContextProps>(initialContext);

export const KiemKe: FC<TheKhoProps> = (props) => {
  const { warehouseId, customTitle } = props;
  return (
    <Formik<any> initialValues={{}} onSubmit={() => {}}>
      <QuanLyKhoContext.Provider value={{}}>
        <div className="bg-white">
          <div className="flex-1 flex flex-column">
            <div className="py-1 ">
              <TableDSVatTu
                warehouseId={warehouseId}
                customTitle={customTitle ? customTitle : null}
              />
            </div>
          </div>
        </div>
      </QuanLyKhoContext.Provider>
    </Formik>
  );
};

export default KiemKe;
