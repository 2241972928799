import { IconMenu } from "../../component/IconSvg"
import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { DanhSachVatTuInterface } from "../models/ThongKeModel"

export const dsVatTuColumnsSimple: any[] = [
  {
    name: "",
    field: "checkbox",
    width: 50,
    minWidth: 27,
  },
  {
    name: "Mã thuốc",
    field: "code",
    width: 100,
    minWidth: 75,
    textAlign: "center"
  },
  {
    name: "Tên thuốc",
    field: "name",
    width: 400,
    minWidth: 200,
    textAlign: "left"
  },
  {
    name: "DVT",
    field: "dvt",
    width: 100,
    minWidth: 50,
    textAlign: "center"
  },
  {
    name: "Nhà cung cấp",
    field: "nhaCungCap",
    width: 200,
    minWidth: 75,
    textAlign: "left",
  },
  {
    name: "Hãng sản xuất",
    field: "hangSanXuat",
    width: 300,
    minWidth: 80,
    textAlign: "left",
  },
  {
    name: "Nước sản xuất",
    field: "nuocSanXuat",
    width: 200,
    minWidth: 80,
    textAlign: "left",
  },
  {
    name: "Số đăng ký",
    field: "soDangKy",
    width: 180,
    minWidth: 70,
    textAlign: "left",
  },
  {
    name: "Số lô nhập",
    field: "soLoNhap",
    width: 150,
    minWidth: 85,
    textAlign: "left",
  },
];

export const TreeVatTu = {
  code: "all",
  name: "Tất cả dịch vụ",
  icon: <IconMenu />,
  filter: [
    {
      code: "all.khamBenh",
      name: "Loại thuốc",
    },
    {
      code: "Nhóm BHYT",
      name: "Xét nghiệm",
    },
  ],
};

export const COMPONENT_TYPE = {
  TEXTFIELD: "textfield",
  DATEPICKER: "datepicker",
  NUMBER: "number",
  SELECT: "select",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  TEXTAREA: "textarea",
  FILE: "file",
  COLUMNS: "columns",
  BUTTON: "button",
  PASSWORD: "password",
  SELECTBOXES: "selectboxes",
  DAY: "day",
  TIME: "time",
  EMAIL: "email",
  DATETIME: "datetime",
  PHONE: "phoneNumber",
  CONTENT: "content",
  HTML: "htmlelement"
};
export const VT_THUOC = {
  1: "thuốc",
  2: "vật tư",
}

export const DanhSachVatTuColumns: ReadonlyArray<Column<DanhSachVatTuInterface>> =
  [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Mã vật tư"}
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={props.data[props.row.index].code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Tên vật tư"}
          className=" text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"DVT"}
          className=" text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "unitofMeasureName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.unitofMeasureName}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Số đăng ký"}
          className="text-center text-white align-middle bg-pri min-w-125px"
        />
      ),
      id: "registrationNumber",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 text-center"
          data={props.data[props.row.index]?.registrationNumber}
        />
      ),

    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Số lô nhập"}
          className="text-center text-white align-middle bg-pri min-w-125px"
        />
      ),
      id: "consignment",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 text-end"
          data={props.data[props.row.index]?.consignment}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Nhà cung cấp"}
          className=" text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "supplier",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.supplierName}
        />
      ),
    }
  ];

export const DanhSachMauColumns: ReadonlyArray<Column<DanhSachVatTuInterface>> =
  [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Mã vật tư"}
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={props.data[props.row.index].code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Tên vật tư"}
          className=" text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"DVT"}
          className=" text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "unitofMeasureName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.unitofMeasureName}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Thể tích"}
          className=" text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "volume",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.volume}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachVatTuInterface>
          tableProps={props}
          title={"Số quản lý"}
          className=" text-center text-white align-middle bg-pri min-w-90px"
        />
      ),
      id: "manufacNum",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.manufacNum}
          className="text-center"
        />
      ),
    },
  ];