import {Dispatch, SetStateAction, useContext} from "react";
import {OctTable} from "@oceantech/oceantech-ui";
import {HoanTraContext} from "./DialogNhapHoanTra";
import CustomIconButton from "../../../../../component/custom-icon-button/CustomIconButton";
import {formatMoney} from "../../../../../utils/FormatUtils";
import TextField from "../../../../../component/TextField";
import {STATUS_DIEU_CHUYEN} from "../../../../../phan-he-dieu-chuyen/constants/constansDieuChuyen";

interface Props {
    setIsChangeData: Dispatch<SetStateAction<any>>,
    isView: boolean,
    title?: string,
    workFlow: any
};

const TableThongTinNhapHoanTra = (props: Props) => {
    const {
        setIsChangeData,
        isView,
        title,
        workFlow
    } = props;

    const {dsPhieuHoanTra, setDsPhieuHoanTra} = useContext(HoanTraContext);

    const hanldeDeleteRow = (index: number) => {
        const data = [...dsPhieuHoanTra].filter((_: any, i: number) => i !== index);
        setDsPhieuHoanTra(data);
    };

    const handleChangeAcceptQuantity = (e: React.ChangeEvent<HTMLInputElement>, rowData: any, index: number) => {
        setIsChangeData(true);
        let quantity = parseFloat(e.target.value);
        if (quantity < 0 || isNaN(quantity)) quantity = 0;
        else if (quantity > rowData.requireQuantity) quantity = rowData.requireQuantity || 0;
        const updatedDsPhieuHoanTra = [...dsPhieuHoanTra];
        updatedDsPhieuHoanTra[index] = {
            ...updatedDsPhieuHoanTra[index],
            accept: quantity,
            totalQuantity: quantity,
        };

        setDsPhieuHoanTra(updatedDsPhieuHoanTra);
    };

    const columns = [
        {
            name: "STT",
            field: "",
            headerStyle: {
                minWidth: 50,
                maxWidth: 50,
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (row: any, index: number, stt: number) => <span>{stt}</span>,
        },
        ...(isView ? [] : [
            {
                name: "TT",
                field: "",
                headerStyle: {
                    minWidth: 50,
                    maxWidth: 50,
                },
                cellStyle: {
                    textAlign: "left",
                },
                render: (row: any, index: number, stt: number) => (
                    <div className="d-flex justify-content-center gap-3">
                        <CustomIconButton variant="delete" onClick={() => hanldeDeleteRow(index)}>
                            <i className="bi bi-trash3-fill text-danger"></i>
                        </CustomIconButton>
                    </div>
                ),
            }]),
        {
            name: "Mã thuốc",
            field: "itemCode",
            headerStyle: {
                minWidth: 110,
                maxWidth: 110,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.itemCode,
        },
        {
            name: "Tên thuốc",
            field: "itemName",
            headerStyle: {
                minWidth: 220,
                maxWidth: 220,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.itemName,
        },
        {
            name: "ĐVT",
            field: "unitOfMeasureName",
            headerStyle: {
                minWidth: 100,
                maxWidth: 100,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.unitOfMeasureName,
        },
        {
            name: "SL khả dụng",
            field: "availableQuantity",
            headerStyle: {
                minWidth: 100,
                maxWidth: 100,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => formatMoney(row.availableQuantity),
        },
        {
            name: "SL yêu cầu",
            field: "requireQuantity",
            headerStyle: {
                minWidth: 120,
                maxWidth: 120,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => row.requireQuantity || ''
        },
        {
            name: "SL phát",
            field: "accept",
            headerStyle: {
                minWidth: 100,
                maxWidth: 100,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => workFlow?.currId !== STATUS_DIEU_CHUYEN.WAIT_APPROVE ? row.accept : (
                <TextField
                    name="accept"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeAcceptQuantity(e, row, index)}
                    value={row.accept || ''}
                />
            ),
        },
        {
            name: "Số lô",
            field: "consignment",
            headerStyle: {
                minWidth: 120,
                maxWidth: 120,
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any, index: number, stt: number) => row.consignment,
        },
        {
            name: "Đơn giá",
            field: "",
            headerStyle: {
                minWidth: 100,
                maxWidth: 100,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => formatMoney(row.sellingPrice),
        },
        {
            name: "Thành tiền",
            field: "",
            headerStyle: {
                minWidth: 100,
                maxWidth: 100,
            },
            cellStyle: {
                textAlign: "right",
            },
            render: (row: any, index: number, stt: number) => {
                const quantity = (parseFloat(row.accept) || 0);
                const price = row.sellingPrice || 0;
                const vat = row.vat || 0;
                const result = quantity * price * (1 + vat / 100);
                return formatMoney(result);
            },
        },
    ];

    return (
        <div className="spaces mt-12 border-table custom-table">
            {title && <h4 className="spaces mt-8 ml-10 title">{title}</h4>}
            <OctTable
                className={`${isView ? "table-view-dieu-chuyen" : "table-dieu-chuyen"} custom-header-table`}
                columns={columns}
                data={dsPhieuHoanTra}
                noToolbar
                clearToolbar
                noPagination
                fixedColumnsCount={-1}
            />
        </div>
    );
};

export default TableThongTinNhapHoanTra;
