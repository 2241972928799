import { Formik } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import "./ThemMoiPhieuThu.scss";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { handleCaculateTotalAmount } from "../../utils/AppFunction";
import { formatStringDateDTO } from "../../utils/FormatUtils";
import { CODE, TYPE_WH } from "../../utils/Constant";
import { addPhieuThu } from "../services/PhanHeThuNganServices";
import ThongTinPhieu from "./components/ThongTinPhieu";
import FormThemVatTu from "./components/FormThemVatTu";
import ThongTinXuatThuoc from "./components/ThongTinXuatVT";
import { INITIAL_VALUES } from "./constants/constants";
import { IconButtonSave } from "../../component/IconSvg";

type Props = {
  show: boolean
  onHide: () => void
  warehouseId: number
  warehouseExportType: any
  handleRefreshData: (data: any, isUpdateSummaryStatus?: boolean, isUpdateDsBenhNhan?: boolean) => void
};

const DialogThemMoiPhieuThu: FC<Props> = (props) => {
  const { show, onHide, warehouseExportType, warehouseId, handleRefreshData} = props;
  const { setIsLoading } = useContext(AppContext);
  const intl = useIntl();
  const [vatTuSelected, setVatTuSelected] = useState<any>({});
  const [dsVatTu, setDsVatTu] = useState<any>([]);

  const validationSchema = Yup.object({
    phieuXuatKhoAdd: Yup.object({
      mateWh: Yup.string()
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
        .nullable(),
      patientName: Yup.string()
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
        .nullable(),
      patientGender: Yup.string()
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
        .nullable(),
      mateWhId: Yup.number()
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
        .nullable(),
      age: Yup.number()
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
        .min(1, intl.formatMessage({ id: 'VALIDATION.MINNUMBER1' }))
        .nullable(),
      guardian: Yup.string()
        .nullable()
        .when('age', {
          is: (age: number) => age < 12,
          then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
          otherwise: Yup.string().nullable(),
        }),
      guardianPhoneNumber: Yup.string()
        .nullable()
        .when('age', {
          is: (age: number) => age < 12,
          then: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
            .matches(/^[0-9]+$/, intl.formatMessage({ id: 'VALIDATE.NUMBER' })) 
            .min(10, intl.formatMessage({ id: 'VALIDATE.MIN10' }))
            .max(11, intl.formatMessage({ id: 'VALIDATE.MAX11' })),
          otherwise: Yup.string().nullable(),
        }),
    }),
  });    

  const handleFormSubmit = async (values: any) => {    
    try {
      if (!dsVatTu?.length) {
        toast.warning('Chưa có vật tư phiếu thu');
        return;
      }
      setIsLoading(true);
      const listItems = [...dsVatTu].map(item => (
        {
          itemId: item?.item?.id,
          totalQuantity: parseFloat(item.totalQuantity ?? "0"),
          ...item?.item,
          consignment:item?.consignment || item?.item?.consignment,
          serialNumber:item?.serialNumber || item?.item?.serialNumber,
          totalAmount:parseFloat(item?.totalAmount || item?.item?.totalAmount || "0"),
          warehouseId: warehouseId,
          id: null,
        }
      ));
      const total = handleCaculateTotalAmount(listItems, "salePrice")
      const dataSubmit = {
        slipDto: {
          ...values.phieuXuatKhoAdd,
          doCreation: formatStringDateDTO(values.phieuXuatKhoAdd?.doCreation),
          type: TYPE_WH.THU_NGAN,
          warehouseId: warehouseId,
          warehouseExportType: warehouseExportType.code,
          amount: total,
        },
        lstItem: listItems,
      };

      const { data } = await addPhieuThu(dataSubmit, warehouseExportType.modelId);      
      if (CODE.SUCCESS === data.code) {
        handleRefreshData(data.data, false, true);
        toast.success(`Thêm phiếu thu thành công`);
        onHide();
      } else {
        setIsLoading(false);
        toast.error(data?.data[0]?.errorMessage || "Có lỗi xảy ra, vui lòng thử lại !");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={onHide}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Modal.Header className="header-modal min-h-40px ">
          <div className="d-flex gap-6">
            <div
              className="d-flex align-items-center title-back gap-3 cursor-pointer"
              onClick={onHide}
            >
              <i className="spaces bi bi-chevron-left ml-10"> </i>
              <div className="spaces mr-20 my-3">Quay lại</div>
            </div>
            <div className="spaces mt-4 title">Thêm mới phiếu thu</div>
          </div>
        </Modal.Header>
        <Modal.Body className="spaces p-0 mt-1 bg-white">
          <Formik<any>
            enableReinitialize
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ handleSubmit, values }) => (
              <Form className="receive" onSubmit={handleSubmit}>
                <div className="spaces width-100 ">
                  <ThongTinPhieu warehouseExportType={warehouseExportType} />
                  <FormThemVatTu
                    generalValues={values}
                    warehouseExportType={warehouseExportType}
                    warehouseId={warehouseId}
                    dsVatTu={dsVatTu}
                    setDsVatTu={setDsVatTu}
                    setVatTuSelected={setVatTuSelected}
                    vatTuSelected={vatTuSelected}
                  />
                  <ThongTinXuatThuoc
                    dsVatTu={dsVatTu}
                    setDsVatTu={setDsVatTu}
                    setVatTuSelected={setVatTuSelected}
                  />
                </div>
                <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6 pl-10">
                  <Button className="btn btn-fill spaces min-w-100" type="submit">
                    <IconButtonSave /> Lưu
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>

      </Modal>
    </div>
  );
};
export default DialogThemMoiPhieuThu;
