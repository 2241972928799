import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";

export const ColumnThongTinThuNgan = [
  {
    name: "STT",
    field: "",
    headerStyle: {
      minWidth: 40,
      maxWidth: 40,
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (row: any, index: number, stt: number) => stt,
  },
  {
    name: "Mã thuốc/VT",
    field: "",
    headerStyle: {
      minWidth: 150,
      maxWidth: 150,
    },
    render: (row: any, index: number, stt: number) => row.item?.code || row.code,
  },
  {
    name: "Tên thuốc/VT",
    field: "",
    headerStyle: {
      minWidth: 200,
      maxWidth: 200,
    },
    render: (row: any, index: number, stt: number) => row.item?.name || row.itemName,
  },
  {
    name: "Đơn vị",
    field: "",
    headerStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (row: any, index: number, stt: number) => row.item?.unitofMeasureName || row.unitId,
  },
  {
    name: "SL khả dụng",
    field: "",
    headerStyle: {
      minWidth: 70,
      maxWidth: 70,
    },
    cellStyle: {
      textAlign: "end",
    },
    render: (row: any, index: number, stt: number) => formatMoney(row.item?.availableQuantity || row.availableQuantity),
  },
  {
    name: "Yêu cầu",
    field: "",
    headerStyle: {
      minWidth: 70,
      maxWidth: 70,
    },
    cellStyle: {
      textAlign: "end",
    },
    render: (row: any, index: number, stt: number) => formatMoney(row.totalQuantity),
  },
  {
    name: "Duyệt",
    field: "accept",
    headerStyle: {
      minWidth: 70,
      maxWidth: 70,
    },
    cellStyle: {
      textAlign: "end",
    },
    render: (row: any, index: number, stt: number) => formatMoney(row.accept || 0),
  },
  {
    name: "Giá bán",
    field: "",
    headerStyle: {
      minWidth: 110,
      maxWidth: 110,
    },
    cellStyle: {
      textAlign: "end",
    },
    render: (row: any, index: number, stt: number) => formatMoney(row?.salePrice || row.item?.salePrice),
  },
  {
    name: "VAT(%)",
    field: "",
    headerStyle: {
      minWidth: 60,
      maxWidth: 60,
    },
    cellStyle: {
      textAlign: "end",
    },
    render: (row: any, index: number, stt: number) => row?.vat || row.item?.vat || 0,
  },
  {
    name: "Giá bán + VAT",
    field: "",
    headerStyle: {
      minWidth: 110,
      maxWidth: 110,
    },
    cellStyle: {
      textAlign: "end",
    },
    render: (row: any, index: number, stt: number) => {
      const quantity = parseFloat(row.totalQuantity || 0);
      const price = parseFloat(row?.salePrice || row.item?.salePrice || 0);
      const vat = parseFloat(row?.vat || row.item?.vat || 0);
      return formatMoney((price * quantity) * (1 + vat / 100))
    },
  },
  {
    name: "Thành tiền",
    field: "",
    headerStyle: {
      minWidth: 110,
      maxWidth: 110,
    },
    cellStyle: {
      textAlign: "end",
    },
    render: (row: any, index: number, stt: number) => {
      const price = parseFloat(row?.salePrice || row.item?.salePrice || 0);
      const vat = parseFloat(row?.vat || row.item?.vat || 0);
      const quantity = parseFloat(row.totalQuantity || 0);
      return formatMoney((quantity * price) * (1 + (vat / 100)))
    },
  },
  {
    name: "Hướng dẫn sử dụng",
    field: "",
    headerStyle: {
      minWidth: 200,
      maxWidth: 200,
    },

    render: (row: any, index: number, stt: number) => row.usageIns || row.instructions,
  },
  {
    name: "Hoạt chất",
    field: "",
    headerStyle: {
      minWidth: 100,
      maxWidth: 100,
    },
    render: (row: any, index: number, stt: number) => row.item?.actIngName,
  },
  {
    name: "Số ĐK",
    field: "",
    headerStyle: {
      minWidth: 120,
      maxWidth: 120,
    },
    render: (row: any, index: number, stt: number) => row.regisNum || row.item?.registrationNumber,
  },
  {
    name: "Số lô",
    field: "",
    headerStyle: {
      minWidth: 120,
      maxWidth: 120,
    },
    render: (row: any, index: number, stt: number) => row.consignment || row.item?.consignment,
  },
  {
    name: "HSD",
    field: "",
    headerStyle: {
      minWidth: 120,
      maxWidth: 120,
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (row: any, index: number, stt: number) => formatDateToDDMMYYYY(row.expiryDate || row.item?.expiryDate),
  },
];

export const DSBenhNhanColumns = [
  {
    name: "TT",
    field: "",
    headerStyle: {
      minWidth: 50,
      maxWidth: 50,
      backgroundColor: '#3C78D8',
      color: '#ffffff',
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (row: any, index: number) => (
      <i className="bi bi-circle-fill" style={{ color: row.color }}></i>
    ),
  },
  {
    name: "Tên khách hàng",
    field: "",
    headerStyle: {
      minWidth: 160,
      backgroundColor: '#3C78D8',
      color: '#ffffff',
    },
    render: (row: any, index: number) => (
      <div className="fs-3">
        <div className="text-system">{row.patientName}</div>
        <div className="fw-300">Xuất lẻ</div>
      </div>
    ),
  },
  {
    name: "Mã phiếu",
    field: "",
    headerStyle: {
      minWidth: 80,
      backgroundColor: '#3C78D8',
      color: '#ffffff',
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (row: any, index: number) => row.code,
  },
  {
    name: "Tuổi",
    field: "",
    headerStyle: {
      minWidth: 50,
      backgroundColor: '#3C78D8',
      color: '#ffffff',
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (row: any, index: number) => row.age,
  },
  {
    name: "Địa chỉ",
    field: "",
    headerStyle: {
      minWidth: 200,
      backgroundColor: '#3C78D8',
      color: '#ffffff',
    },
    render: (row: any, index: number) => row.patientAddress,
  },
];