import axios from "axios";
import { localStorageItem } from '../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;

export const searchList = (pathUrl?: any,searchObject?: any) => {
  let url = `${API}/${pathUrl}/search `;
  return axios.post(url, searchObject);
};

export const searchListDM = (searchObject?: any) => {
  let url = API + `/category?classId=30`;
  return axios.get(url, searchObject);
};

export const addDanhMuc = (pathUrl?: any, data?: any) => {
  let url = API + `/${pathUrl}`;
  return axios.post(url, data);
};
export const updateDanhMuc = (pathUrl?: any, data?: any) => {
  let url = API + `/${pathUrl}/${data?.termModelId || data?.id}`;
  return axios.put(url, data);
};

export const deleteDanhMuc = (pathUrl?: any, data?: any) => {
  let url = API + `/${pathUrl}/${data?.termModelId || data?.id}`;
  return axios.delete(url);
};

export const searchListItemGoiThau = (id: string) => {
  let url = `${API}/bid-item/get-by-bid-id`;
  return axios.post(url, { bidId: id});
};

export const importDanhMuc = (file: any, endpoint: string) => {
  let url = `${API}/obs-complex-categories/${endpoint}/import`;
  let formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, formData, config);
};

export const downloadSampleImportDanhMuc = (endpoint: string) => {
  let url = `${API}/api/files/category/${endpoint}/download`;
  return axios.get(url, {
    responseType: 'blob',
  });
};