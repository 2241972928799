import { useFormikContext } from "formik";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTMLNoValid";
import { WMS_STATUS } from "../../../constants/constantsXuatKho";

type Props = {
  modelID?: any
  listJsonGeneratePhieuXuat?:any
  isView?: boolean
};

const ThongTinPhieu = ({ modelID, listJsonGeneratePhieuXuat, isView }: Props) => {
  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    handleChange
  } = useFormikContext<any>();
  
  return (
    <div className="bg-white spaces mb-1 pb-15 fl-1 pl-16 pr-16">
      <GenerateFormHTML
        isView={isView}
        listFieldAuto={listJsonGeneratePhieuXuat}
        itemEdit={{}}
        isSave={false}
        isUpdate={true}
        modelID={modelID}
        handleSubmit={handleSubmit}
        setValues={setValues}
        setTouched={setTouched}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
        isValid={isValid}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ThongTinPhieu;
