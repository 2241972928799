import React, { useContext, useEffect, useState } from "react";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTMLNoValid"
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import { useFormikContext } from "formik";
import { VatTu } from "../../ThemMoiVatTu/models/ThemMoiVatTuModel";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../../PhanHeThongKe"

type Props = {
  ThongTinTab?: any;
};

const TabThongTinAutoGenerateNoValidate = ({
  ThongTinTab,
}: Props) => {

  const { setIsLoading } = useContext(AppContext);
  const { dataVatTuEdit } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    handleChange
  } = useFormikContext<VatTu>();

  useEffect(() => {
    getListform();
  }, [])
  useEffect(() => {
    Object.keys(dataVatTuEdit || {})?.length && setValues(dataVatTuEdit);
  }, [dataVatTuEdit])
  const getListform = async () => {
    let data = await generateForm(ThongTinTab?.modelId || "", setIsLoading)
    setListJsonGenerate(data)
  }
  return (
    <div className="bg-white spaces mb-6 fl-1 mx-10">
      <GenerateFormHTML
        isView={false}
        listFieldAuto={listJsonGenerate}
        itemEdit={dataVatTuEdit[ThongTinTab?.modelId] || {}}
        isSave={false}
        isUpdate={true}
        handleSubmit={handleSubmit}
        setValues={setValues}
        setTouched={setTouched}
        setFieldValue={setFieldValue}
        values={values}
        modelID={ThongTinTab?.modelId}
        errors={errors}
        touched={touched}
        isValid={isValid}
        handleChange={handleChange}
      />
    </div>
  );
};

export default TabThongTinAutoGenerateNoValidate;

