import { Button, Modal } from "react-bootstrap";
import { generateForm, generateHrTag } from "../../../utils/AppFunction";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../appContext/AppContext";
import { Formik, Form, useFormikContext, FormikHelpers } from "formik";
import * as Yup from "yup";
import GenerateFormComponent from "../../../component/generate-form/GenerateFormHTMLNoValid";
import DynamicComponent from "../../../component/GenerateDynamicComponent";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, SELECTION_MODE, WF_TRACKER } from "../../../utils/Constant";
import { toast } from "react-toastify";
import WfButton from "../../../workflows/components/WfButton";
import '../../PhanHeTuTruc.scss';
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { TablePagination } from "../../../component/table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../../utils/PageUtils";
import { TU_TRUC } from "../../constants/TuTrucConstans";
import { TMCoSoTuTrucColumns } from "../../constants/TuTrucColumns";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import { RESPONSE_CODE_SUCCESS } from "../../../category-personnel/const/PersonnelConst";
import { updatePhieuXuatSuDung, getDsVatTuPhieu, getListMedicine, printPhieu, createPhieuXuatSuDung } from "./services";
import "./index.scss"
import ButtonIn from "../../../component/button-in-phieu";
import { WMS_STATUS } from "../../../phan-he-nhap-kho/constants/constansNhapKho";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";


interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  modelId: any;
}

interface InventoryItem {
  iventory: number;
  code: string;
  unitofMeasure: string;
  materialGroup: number;
  materialType: string;
  name: string;
  peShipment: number;
  id: number;
  unitofMeasureName: string;
}

const DialogThemPhieuXuatSuDung = (props: Props) => {
  const { show, onHide, initialValues, modelId } = props;
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [lstItem, setLstItem] = useState<any>([])
  const [itemSelected, setItemSelected] = useState<any>([] as any)
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false)
  const [statusPhieu, setStatusPhieu] = useState<any>({ status: WMS_STATUS.warehouse_new });
  const formRef = useRef<any>()
  const [searchData, setSearchData] = useState<any>();
  const [idItem, setIdItem] = useState('')
  const departmentRef = useRef('');
  const slipIdRef = useRef('');
  const caculatorTotalAmount = (values: any) => Number(values?.totalQuantity) * (Number(values?.sellingPrice) + ((Number(values?.vat || 0) / 100) * Number(values?.sellingPrice)))
  const clearValues = (setFieldValue: FormikHelpers<Record<string, any>>['setFieldValue']) => {
    ["name", "totalQuantity", "unitOfMeasureName", "code", "specification", "manufacturer", "countryOfOrigin", "consignment", "expiryDate", "iventory", "sellingPrice", "vat", "totalAmount"].forEach((key: string) => {
      setFieldValue(`${modelId.id}.${key}`, '')
    })
  }

  useEffect(() => {
     getSelectedRowsData(initialValues[modelId.id]?.id);
  }, [initialValues[modelId.id]?.id]);

  useEffect(() => {
    updateForm();
  }, []);

  useEffect(() => {
    generateHrTag('#kt_app_body > div.fade.page-full.spaces.h-calc-vh-50.modal.show > div > div > form > div.spaces.p-0.bg-white.modal-body > div.spaces.px-10.mb-10 > div > form > div:nth-child(2) > div.themPhieuXuatSuDung-columns6-1.col-lg-6')
    let hrTag = document.getElementsByTagName("hr");
    if (hrTag.length > 0) {
      hrTag[0].style.marginTop = "12px";
    }
  }, [listJsonGenerate])

  const validationSchema = Yup.object({
    [modelId.id]: Yup.object({
    })
  })

  const handlerMedicine = (value?: any, values?: any, setFieldValue?: any) => {
    let obj = {
      ...values[modelId.id],
      iventory: Number(value.iventory),
      unitOfMeasureName: value.unitofMeasureName,
      code: value.code,
      CountryOfOrigin: null, //Nước sản xuất
      manufacturer: null, //Hãng sản xuất
      consignment: value.consignment, // Số lô
      specification: null, //Quy cách
      name: value.name,
      itemId: value.id,
      sellingPrice: Number(value.sellingPrice),
      serialNumber: value.serialNumber,
      expiryDate: value?.expiryDate,
      createdDate: values[modelId.id]?.createdDate
    }
    delete obj?.[modelId.id];
    setFieldValue(modelId.id, obj)
  }

  const handlerAddWithJs = async (values: any, setFieldValue: any) => {
    values[modelId.id].iventoryInit = values[modelId.id].iventory;
    if(!values[modelId.id].department) return toast.warning("Vui lòng chọn khoa phòng!")
    if(!values[modelId.id].name) return toast.warning("Vui lòng chọn thuốc!")
    if(!values[modelId.id].totalQuantity) return toast.warning("Số lượng không được để trống!")
    if(values[modelId.id].totalQuantity <= 0 || isNaN(values[modelId.id].totalQuantity) || values[modelId.id].totalQuantity > values[modelId.id].iventory) return toast.warning("Số lượng nhập không được vượt số lượng tồn trong kho!")
    if(itemSelected.idFake || itemSelected.id) { // Cập nhật
      let arr = lstItem.map((item:any) => {
        if(item.idFake !== itemSelected.idFake || item.id !== itemSelected.id) return item;
        return { ...itemSelected, ...values[modelId.id], totalAmount: caculatorTotalAmount(values[modelId.id]) }
      })
      setItemSelected({})
      setLstItem(arr)
    }
    clearValues(setFieldValue)
    !itemSelected.idFake && !itemSelected.id && setLstItem([...lstItem, generateSlipDto(values)])
  }


  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const updateForm = async () => {
    const data = await generateForm(modelId.id, setIsLoading);
    setListJsonGenerate(data || []);
  };

  const generateSlipDto = (values:any) =>
  ({
    ...initialValues[modelId.id],
    ...values[modelId.id],
    code: values[modelId.id].code,
    idFake: new Date(),
    sellingPrice: Number(values[modelId.id].sellingPrice) || 0,
    totalQuantity: Number(values[modelId.id].totalQuantity) || 0,
    totalAmount: caculatorTotalAmount(values[modelId.id]),
    vat: Number(values[modelId.id].vat) || 0,
    whIssue: values[modelId.id]?.whIssue?.code || values[modelId.id]?.whIssue || initialValues[modelId.id]?.whIssue,
    whIssueName: values[modelId.id]?.whIssue?.whReceiveName || values[modelId.id]?.whIssueName || initialValues[modelId.id]?.whIssueName,
    whIssueId: values[modelId.id]?.whIssue?.id || values[modelId.id]?.whIssueId || initialValues[modelId.id]?.whIssueId,
    type: TU_TRUC.XUAT_SU_DUNG // Xuất sử dụng
  })

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    let id = initialValues[modelId.id]?.id ?? slipIdRef.current;
    if(!lstItem.length) return toast.warning("Thông tin phiếu không có!")
    try {
      setIsLoading(true)
      const data = {
        slipDto: generateSlipDto(values),
        lstItem: lstItem.map((item: any) => {
          item.iventory = Number(item.iventory)
          return {
            ...item,
            id: item?.whIssue?.id,
            requireQuantity: item?.totalQuantity,
            itemId: item?.itemId,
            serialNumber: item?.serialNumber,
            code: item?.code,
            name: item?.name,
            consignment: item?.consignment,
            expiryDate: item?.expiryDate,
            createdDate: item?.createdDate,
          }
        })
      };
      let res = id ?
        await updatePhieuXuatSuDung(id, data) :
        await createPhieuXuatSuDung(data);
      if (res?.data?.code === RESPONSE_CODE_SUCCESS) {
        toast.success("Thành công");
        setTimeout(() => {
          slipIdRef.current = res?.data?.data?.id
          // formikHelpers.setFieldValue(`${modelId.id}.id`, res?.data?.data?.id)
          formikHelpers.setValues({
            ...values,
            [modelId.id]: {
              ...values[modelId.id],
              id : res?.data?.data?.id,
              notes: values[modelId.id].notes,
              department: values[modelId.id].department,
              departmentId: values[modelId.id].departmentId,
              departmentName: values[modelId.id].departmentName,
            }
          })
        }, 200);
      }
    } catch (error) {
      console.error("ERR", error)
    } finally {
      setIsLoading(false)
    }
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = id ? await
        getDsVatTuPhieu({
          pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, slipId: id,
        }) : await getListMedicine({
          pageSize: MAX_PAGE_SIZE,
          pageIndex: DEFAULT_PAGE_INDEX,
          warehouseId: initialValues[modelId.id]?.whReceive?.id,
          type: 2 // Xuất
        })
      if (CODE.SUCCESS === data.code) {
        // setConfigTable({
        //   totalPages: data.data.pageable.totalElements / data.data.pageable.pageSize,
        //   totalElements: data.data.pageable.totalElements,
        //   numberOfElements: data.data.pageable.pageSize,
        // });
        let values = id ? data.data.content : data.data;
        setLstItem(values.map((item: any) => ({ ...item.item, ...item, id: item.id, unitOfMeasureName: item.item?.unitofMeasureName })))
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">
        {modelId.title && statusPhieu.status === WMS_STATUS.warehouse_new &&
          <>
            <CustomIconButton
              variant="edit"
              onClick={() => {
                setItemSelected({ ...props.cell.row.original });
              }}
            >
              <i className={`spaces bi bi-pencil-square text-navy ${(modelId.title) ? 'mr-5' : 'mx-0'}`}></i>
            </CustomIconButton>

            <CustomIconButton
              variant="delete"
              onClick={() => {
                setIdItem(props.cell.row.original.id ? props.cell.row.original.id : props.cell.row.original.idFake);
                setOpenDialogConfirmDelete(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          </>
        }
      </div>
    )
  };

  const handleDeletePhieu = () => {
    let arr = [...lstItem.filter((item:any) => ![item.idFake, item.id].includes(idItem))]
    setLstItem(arr)
    setOpenDialogConfirmDelete(false)
  }

  const FormObserver = () => {
    const { values, setFieldValue, setValues } = useFormikContext();
    useEffect(() => {
      const totalAmount = caculatorTotalAmount((values as any)[modelId.id]);
      setValues((prevValues: any) => ({
        ...prevValues,
        [modelId.id]: {
          ...prevValues[modelId.id],
          totalAmount: totalAmount
        }
      }))
    }, [(values as any)[modelId.id]?.totalQuantity, (values as any)[modelId.id]?.sellingPrice, (values as any)[modelId.id]?.vat]);

    useEffect(() => {
      if(!itemSelected.idFake && !itemSelected.id) return;
      setFieldValue(modelId.id,
        initialValues[modelId.id]?.id ? {
          ...itemSelected,
          createdBy: initialValues[modelId.id]?.createdBy,
          whIssue: initialValues[modelId.id]?.whIssue,
          department: initialValues[modelId.id]?.department,
          notes: initialValues[modelId.id]?.notes,
          createdDate: initialValues[modelId.id]?.createdDate,
          unitOfMeasureName: itemSelected?.item?.unitofMeasureName,
          iventory: itemSelected?.iventoryInit ? itemSelected?.iventoryInit : itemSelected?.item?.iventory,
        } : {
          ...itemSelected,
          createdDate: initialValues[modelId.id]?.createdDate,
          iventory: itemSelected?.iventoryInit ? itemSelected?.iventoryInit : itemSelected?.item?.iventory,
        })
    }, [itemSelected])

    useEffect(() => {
      if (departmentRef.current != (values as any)[modelId.id]?.department && (values as any)[modelId.id]?.department && departmentRef.current != undefined) {
        setLstItem([])
        setItemSelected({})
        clearValues(setFieldValue)
      }
      departmentRef.current = (values as any)[modelId.id]?.department
    }, [(values as any)[modelId.id]?.department]);

    useEffect(() => {
      if (!values) return;
      // Số lượng validate
      let eleTotalQuantity = document.querySelector("input[name='totalQuantity']");
      const handlerValidateSl = function (this: HTMLInputElement) {
        // Loại bỏ mọi ký tự không phải số
        if (!Number(this.value)) { this.value = this.value.replace(/[^0-9]/g, '') }
        // this.value = this.value.replace(/[^0-9]/g, '');

        // Nếu giá trị là trống, không làm gì cả
        if (this.value === '') return;

        // Chuyển giá trị sang số nguyên để kiểm tra
        const valueAsNumber = parseInt(this.value, 10);

        // Kiểm tra giá trị nhỏ hơn 1 thì đặt lại thành 1
        if (valueAsNumber < 1) {
          this.value = '1';
        }
      }
      if (eleTotalQuantity) {
        // Đặt thuộc tính min là 1
        eleTotalQuantity.setAttribute('min', '1');

        // Đảm bảo chỉ cho phép nhập số nguyên dương
        eleTotalQuantity.addEventListener('input', handlerValidateSl);
      }
      return () => {
        eleTotalQuantity?.removeEventListener('input', handlerValidateSl)
      };
    });

    return null;
  }

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, setFieldValue }) => {
          return <Form ref={formRef} >
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
                {
                  modelId.title &&
                  <div className="spaces mt-4 title">
                    {`${values[modelId.id]?.id || slipIdRef.current ? 'Cập nhật' : 'Thêm mới'} ${modelId.title}`}
                  </div>
                }
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10 mb-10">
                {/* <h4 className="text-title fw-bold fs-5 spaces mb-5">Thông tin phiếu</h4> */}
                <div>
                  <GenerateFormComponent
                    warehouseId={initialValues[modelId.id]?.whIssue?.id || initialValues[modelId.id]?.whIssueId}
                    listFieldAuto={listJsonGenerate}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                    modelID={modelId.id}
                    customComponent={DynamicComponent}
                    propsRenderButton={(e: any) => handlerMedicine(e, values, setFieldValue)}
                    isViewSelectThuoc={statusPhieu.status !== WMS_STATUS.warehouse_new}
                    isView={statusPhieu.status !== WMS_STATUS.warehouse_new}
                  />
                </div>
                {
                  <Button disabled={!modelId?.title || WMS_STATUS.warehouse_new !== statusPhieu?.status} className="btn btn-fill custom-position-btn spaces min-w-90px mt-10" onClick={() => handlerAddWithJs(values, setFieldValue)}>
                    {(itemSelected.idFake || itemSelected.id) ? 'Cập nhật' : 'Thêm'}
                  </Button>
                }
              </div>
              <div className=" spaces bg-white mb-0">
                <TableCustom<any>
                  key={statusPhieu?.status}
                  className={`spaces ${modelId.title ? "h-70vh" : "h-72vh"}`}
                  data={lstItem}
                  columns={TMCoSoTuTrucColumns}
                  columnsActions={columnsActions}
                  verticalScroll={true}
                  selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                />
                {modelId.title &&
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    // customComponent={<WfStatusSummary hiddenQuantity summaryStatus={summaryStatus} col={3} />}
                    customComponentClassName="min-w-600px"
                    className="border border-0"
                  />
                }
              </div>
            </Modal.Body>
            <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6">
              <WfButton
                key={values[modelId.id]?.id}
                tracker={WF_TRACKER.XUAT_SU_DUNG} // button
                objectId={values[modelId.id]?.id}
                additionalFunc={(data) => {
                  setStatusPhieu({
                    status: data?.currCode
                  })
                  data?.currCode && setFieldValue(`${modelId.id}.statusCode`, data?.currCode)
                }}
              />
              {(WMS_STATUS.warehouse_new === statusPhieu?.status) && modelId.title &&
                <Button type="submit" className="btn btn-fill min-w-90px spaces ms-5">
                  Lưu
                </Button>
              }
              {(![WMS_STATUS.warehouse_new].includes(statusPhieu.status) && statusPhieu.status) &&
                <ButtonIn
                  className="spaces min-w-120 mx-3"
                  fetchExport={printPhieu}
                  params={{
                    slipId: values[modelId.id]?.id,
                  }}
                />
              }
            </div>
            <FormObserver />
          </Form>
        }}
      </Formik>
      {openDialogConfirmDelete &&
          <ConfirmDialog
            show={openDialogConfirmDelete}
            onCloseClick={() => setOpenDialogConfirmDelete(false)}
            onYesClick={handleDeletePhieu}
            title="Xóa phiếu"
            message={`Bạn chắc chắn muốn xóa ?`}
            yes="Xóa"
            close="Hủy"
          />
        }
    </Modal>
  );
};

export default DialogThemPhieuXuatSuDung;
