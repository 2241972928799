
  // Hàm lấy giá trị theo đường dẫn
  const getValueByPath = (data: Record<string, any>, path: string): any =>
    path.split(".").reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), data);
  
  type LogicType = any[] | { [key: string]: any } | string | number | boolean;

  const evaluateLogic = (logic: LogicType, data: Record<string, any>): any => {
    if (Array.isArray(logic)) {
      throw new Error("Invalid JSON configuration.");
    } else if (typeof logic === "object" && logic !== null) {
      const [operator, values] = Object.entries(logic)[0];
      switch (operator) {
        case "if": {
          // Mệnh đề "if" là mảng với 3 phần tử [condition, trueResult, falseResult]
          if (!Array.isArray(values) || values.length !== 3) {
            throw new Error("'if' must have exactly 3 elements: [condition, trueResult, falseResult]");
          }
          const [condition, trueResult, falseResult] = values;
          return evaluateLogic(condition, data)
            ? evaluateLogic(trueResult, data)
            : evaluateLogic(falseResult, data);
        }
        case "==": {
          // So sánh hai giá trị
          const [left, right] = values;
          const evaluatedLeft = evaluateLogic(left, data);
          const evaluatedRight = evaluateLogic(right, data);
          
          // Nếu so sánh với boolean, chuyển giá trị sang boolean trước
          if (typeof right === "boolean") {
            return evaluatedLeft === 0 ? true : Boolean(evaluatedLeft) === evaluatedRight;
          }
          return evaluatedLeft === evaluatedRight;
        }
        case "and": {
          // Toán tử AND: tất cả các điều kiện phải đúng
          return values.every((condition: LogicType) => evaluateLogic(condition, data));
        }
        case "or": {
          // Toán tử OR: ít nhất một điều kiện phải đúng
          return values.some((condition: LogicType) => evaluateLogic(condition, data));
        }
        case ">": {
          // Kiểm tra giá trị lớn hơn
          const [left, right] = values;
          return evaluateLogic(left, data) > evaluateLogic(right, data);
        }
        case "<": {
          // Kiểm tra giá trị nhỏ hơn
          const [left, right] = values;
          return evaluateLogic(left, data) < evaluateLogic(right, data);
        }
        case "var": {
          // Lấy giá trị từ `data` theo đường dẫn
          return getValueByPath(data, values);
        }
        default:
          throw new Error(`Unsupported operator: ${operator}`);
      }
    }
  
    // Trả về giá trị trực tiếp nếu không phải mệnh đề
    return logic;
  };

  interface ValidationConfig {
    validate?: {
      json: LogicType;
    };
  }

  export const validateData = (jsonConfig: ValidationConfig, data: Record<string, any>) => {
    if (!jsonConfig.validate || !jsonConfig.validate.json) {
      throw new Error("Invalid JSON configuration.");
    }

    const validationLogic = jsonConfig.validate.json;
    const result = evaluateLogic(validationLogic, { data });

    return result === true
      ? { isValid: true, message: null }
      : { isValid: false, message: result };
  };

