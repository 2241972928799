import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_REPORT = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiReportUrl"] || process.env.REACT_APP_WMS_API_URL;

export const searchVatTu = (searchData: any) => {
  let url =  `${API}/item/search`;
  return axios.post(url, searchData);
};

export const downloadReport = (parameters: any) => {
  const url = `${API_REPORT}/rest/reports/run/30eb4c89-9189-151a-7d35-cfdc3de64c78`;
  return axios.post(url, { parameters }, { responseType: 'blob' });
};