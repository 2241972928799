import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, InputGroup, Modal, Row, Stack } from "react-bootstrap";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { Formik, FormikHelpers } from "formik";
import { VatTu, initialValuesThemMoiVatTu } from "./models/ThemMoiVatTuModel";
import { useIntl } from "react-intl";
import FormThemMoi from "./components/FormThemMoi";
import "./ThemMoiVatTu.scss";
import { addVatTu, updateVatTu } from "../../services/ThongKeServices";
import { CODE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../PhanHeThongKe"
import { generateForm, generateInitValues, generateValidationSchema } from "../../../utils/AppFunction";

type Props = {
  show: boolean;
  onHide: () => void;
};


const DialogThemMoiVatTu: FC<Props> = (props) => {
  const { show, onHide } = props;
  const intl = useIntl();
  const { setIsLoading } = useContext(AppContext);
  const { dataVatTuEdit, nhomVatTu } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [initialValuesVatTu, setInitialValuesVatTu] = useState<any>();

  useEffect(() => {
    if (nhomVatTu?.id) {
      getListform();
    }
  }, [dataVatTuEdit])

  const getListform = async () => {
    let data = await generateForm(`${nhomVatTu?.description}`, setIsLoading)
    setListJsonGenerate(data);
    setInitialValuesVatTu(
      // generateInitValues(data, nhomVatTu?.description || 'thongTinChung'))
      {
        ...initialValuesVatTu,
        [nhomVatTu?.description || 'thongTinChung']: {
          ...generateInitValues(listJsonGenerate, nhomVatTu?.description || 'thongTinChung')?.thongTinChung,
          ...initialValuesVatTu?.[nhomVatTu?.description || 'thongTinChung'],
          ...dataVatTuEdit?.[nhomVatTu?.description],
        }
      }
    )

  }
  // useEffect(() => {
  //   setInitialValuesVatTu({
  //     ...dataVatTuEdit,
  //     [nhomVatTu?.description || 'thongTinChung']: {
  //       ...initialValuesVatTu?.[nhomVatTu?.description || 'thongTinChung'],
  //       ...dataVatTuEdit?.[nhomVatTu?.description],
  //     }
  //   })
  //   // setInitialValuesVatTu({
  //   //   ...dataVatTuEdit,
  //   //   thongTinChung: {
  //   //     ...generateInitValues(listJsonGenerate, nhomVatTu?.description  || 'thongTinChung')?.thongTinChung,
  //   //     ...dataVatTuEdit?.thongTinChung
  //   //   }
  //   // })
  // }, [dataVatTuEdit])
  const handlerConvertValues = (data: any) => {
    let dataThuoc = data;
    let dataVatTu = data;
    let dataMau = data;
    dataThuoc = {
      id: data?.id || dataVatTuEdit?.id,
      thongTinChung: {
        ...data?.thongTinChung,
        materialGroup: nhomVatTu?.id
      },
    }
    dataVatTu = {
      id: data?.id || dataVatTuEdit?.id,
      thongTinChungVatTu: {
        ...data?.thongTinChungVatTu,
        materialGroup: nhomVatTu?.id
      },
    }
    dataMau = {
      id: data?.id || dataVatTuEdit?.id,
      thongTinChungMau: {
        ...data?.thongTinChungMau,
        materialGroup: nhomVatTu?.id
      },
    }
    if (nhomVatTu?.id == 1) return dataThuoc
    if (nhomVatTu?.id == 2) return dataVatTu
    if (nhomVatTu?.id == 3) return dataMau
    return {}
  }
  const handleFormSubmit = async (values: any) => {
    let dataValues = handlerConvertValues(values);
    const modelID = nhomVatTu?.description || "thongTinChung";
    try {
      setIsLoading(true);
      let { data } = (dataValues?.id || dataVatTuEdit?.id)
        ? await updateVatTu({
          lstModelType: dataValues,
        })
        : await addVatTu({
          lstModelType: dataValues
        }, modelID);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success(((dataValues?.id) ? "Cập nhật" : "Thêm") + ` ${nhomVatTu?.defauiltName} thành công`);
        onHide()
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
  }
  return (
    <div>
      <Modal
        centered
        show={show}
        // onHide={onHide}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Modal.Header className="header-modal min-h-40px ">
          <div className="d-flex gap-6">
            <div
              className="d-flex align-items-center title-back gap-3 cursor-pointer"
              onClick={onHide}
            >
              <i className="spaces bi bi-chevron-left ml-10"> </i>
              <div className="spaces mr-20  my-3">Quay lại</div>
            </div>
            <div className="spaces mt-4 title">{`${initialValuesVatTu?.thongTinChung?.id ? 'Cập nhật' : 'Thêm mới'} ${nhomVatTu?.defauiltName}`}</div>
          </div>
        </Modal.Header>
        <Modal.Body className="spaces p-0 mt-5 bg-white">
          <Formik<VatTu>
            enableReinitialize
            initialValues={initialValuesVatTu}
            validationSchema={generateValidationSchema(listJsonGenerate, nhomVatTu?.description || "thongTinChung")}
            onSubmit={handleFormSubmit}
          >
            <FormThemMoi listJsonGenerate={listJsonGenerate} isReload={show} onHide={onHide} />
          </Formik>
        </Modal.Body>

      </Modal>
    </div>
  );
};
export default DialogThemMoiVatTu;
