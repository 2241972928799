import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { LOAI_PHIEU } from "../../utils/Constant";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";
import { DanhSachPhieuTuTrucInterface } from "../models/TongHopBoSungModel";
import { Column } from "react-table";

export const DanhSachPhieuColumns: ReadonlyArray<
  Column<DanhSachPhieuTuTrucInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "ngayTao",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.createdDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Tháng yêu cầu"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "reqMonth",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.reqMonth}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Loại yêu cầu"
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "reqType",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.reqType === 'RT1' ? "Đột xuất" : props.data[props.row.index]?.reqType === 'RT2' ? 'Kế hoạch' : '' }
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày tổng hợp"
          className=" text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "ngaYLenh2",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.doCreation)}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Khoa/phòng tổng hợp"
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "whIssueName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.whIssueName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Số phiếu dự trù"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "soPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.soPhieu}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="QĐ thầu"
          className="text-center text-white align-middle bg-pri min-w-120px"
        />
      ),
      id: "serialNumber",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index]?.serialNumber}
        />
      ),
    },
  ];

  export const PhieuDuTruColumns: ReadonlyArray<
  Column<DanhSachPhieuTuTrucInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="TT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "custom",
      Cell: ({ ...props }) => (
          <div className="text-center">
            <i className="bi bi-circle-fill" style={{color: props.data[props.row.index]?.color}}></i>
          </div>
        )
      ,
    },
    // {
    //   Header: (props) => (
    //     <TableCustomHeader<DanhSachPhieuTuTrucInterface>
    //       tableProps={props}
    //       title="Trạng thái"
    //       className="text-center text-white align-middle bg-pri min-w-80px"
    //     />
    //   ),
    //   id: "trangThai",
    //   Cell: ({ ...props }) => (
    //     <TableCustomCell
    //       className="text-left s-2"
    //       data={props.data[props.row.index]?.cabinetTypeName}
    //     />
    //   ),
    // },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày tạo"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "createdDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.createdDate)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Loại phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "loaiPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={LOAI_PHIEU.find((item) => item.code === props.data[props.row.index]?.type)?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Mã phiếu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Ngày nhập xuất"
          className=" text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "entryDate",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.entryDate)}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Nơi lập phiếu"
          className=" text-center text-white align-middle bg-pri min-w-40px"
        />
      ),
      id: "noiLapPhieu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.whReceiveName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Nơi thực hiện"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "noiThucHien",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.whIssueName}
        />
      ),
    },
  ];

  export const LstVTDuTruColumns: ReadonlyArray<
  Column<DanhSachPhieuTuTrucInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="STT"
          className="text-center text-light max-w-35"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Mã vật tư"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.item?.code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Tên vật tư"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.item?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Đơn vị"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "unitofMeasureName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={props.data[props.row.index]?.item?.unitofMeasureName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="SL yêu cầu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "requireQuantity",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.requireQuantity}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="SL duyệt"
          className="text-center text-white align-middle bg-pri min-w-40px"
        />
      ),
      id: "totalQuantity",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.totalQuantity)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Đơn giá"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "sellingPrice",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.sellingPrice)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Thành tiền"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "thanhTien",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-end s-2"
          data={formatMoney(props.data[props.row.index]?.totalAmount)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Số QĐ"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "serialNumber",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.serialNumber}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="SL duyệt khả dụng"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "iventory",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index]?.item?.iventory)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="SL chưa duyệt"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "SLChuaDuyet",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.requireQuantity - props.data[props.row.index]?.totalQuantity}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Tồn thầu"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "iventory2",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.item?.iventory}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachPhieuTuTrucInterface>
          tableProps={props}
          title="Khoa/phòng"
          className="text-center text-white align-middle bg-pri min-w-80px"
        />
      ),
      id: "department",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-left s-2"
          data={props.data[props.row.index]?.department}
        />
      ),
    },
  ];
