import { ReactNode, useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { CODE, DEFAULT_PAGE_INDEX, SELECTION_MODE } from "../../utils/Constant";
import { TablePagination } from "../../component/table/components/TablePagination";
import {
  handlePagesChange,
  handleRowsPerPageChange,
  rowsForPage,
} from "../../utils/PageUtils";
import { columnsDSVatTu } from "../const/Columns";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import TextField from "../../component/TextField";
import LabelRequired from "../../component/LabelRequired";
import AutocompleteObjectV2 from "../../component/AutocompleteObjectV2";
import { searchListKho, deleteKiemKe, searchKiemKe } from "../KiemKeServices";
import {
  formatDateFromDTOCustom,
  formatStringDateDTO,
  formatDateToDTOCustom
} from "../../utils/FormatUtils";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import DialogKiemKe from "./DialogKiemKe";
import { ConfirmDialog } from "../../component/ConfirmDialog";

interface TableDSVatTuProps {
  warehouseId: number | undefined;
  customTitle?: ReactNode;
}

const TableDSVatTu: React.FC<TableDSVatTuProps> = (props) => {
  const { warehouseId, customTitle } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dsVatTu, setDsVatTu] = useState([]);
  const [searchData, setSearchData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [listKho, setListKho] = useState<any[]>();
  const [dataPrint, setDataPrint] = useState<any>();
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<any>(false);
  const [dataEdit, setDataEdit] = useState<any>();
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [searchObj, setSearchObj] = useState<any>({
    doCreationFromDate: "",
    doCreationToDate: "",
    pageSize: 20,
    pageIndex: 1,
    type: "",
    warehouseId: "",
    itemId: "",
  });

  useEffect(() => {
    setDataPrint({});
    getListKho();
  }, []);
  const getListKho = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchListKho({
        pageIndex: 1,
        pageSize: 1000,
        // warehouseCode: values?.dieuChuyen?.requestWh,
      });
      setListKho(data?.data?.content);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeSearchData = (name?: any, value?: any) => {
    setPage(DEFAULT_PAGE_INDEX);
    setRowsPerPage(20);
    setSearchObj({
      ...searchObj,
      [name]: value,
    });
  };
  const ConvertDate = (value?: any, name?: any) => {
    if (name == "doCreationFromDate") {
      return formatDateFromDTOCustom(value);
    } else if (name == "doCreationToDate") {
      return formatDateToDTOCustom(value);
    }
    return "";
  };
  const updatePageData = async () => {
    try {
      setIsLoading(true);

      const { data } = await searchKiemKe({
        ...searchObj,
        pageIndex: page,
        type: 13,
        pageSize: rowsPerPage,
        doCreationFromDate: formatStringDateDTO(searchObj?.doCreationFromDate),
        doCreationToDate: formatStringDateDTO(searchObj?.doCreationToDate),
      });
      if (CODE.SUCCESS === data.code) {
        setDsVatTu(data.data?.content);
        setConfigTable({
          totalPages: data.data.totalPages,
          totalElements: data.data.totalElements,
          numberOfElements: data.data.numberOfElements,
        });
      } else {
        toast.error("Có lỗi xảy ra, vui lòng thử lại");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchObj?.warehouseId) {
      updatePageData();
    }
  }, [page, rowsPerPage, searchObj]);
  const handleChangeSelect = (options?: any) => {
    setPage(DEFAULT_PAGE_INDEX);
    setRowsPerPage(20);
    setSearchObj({
      ...searchObj,
      warehouseId: options?.id,
      code: options?.code,
    });
  };

  const handleOpenDialogAddNew = () => {
    if (!searchObj?.warehouseId)
      return toast.error("Vui lòng chọn Kho thuốc/Vật tư");
    setOpenDialogThemMoi(true);
  };
  const handlerCloseDialog = () => {
    setDataEdit({});
    updatePageData();
    setOpenDialogThemMoi(false);
  }
  const handleCloseDialogDelete = () => {
    updatePageData();
    setDataEdit({});
    setOpenDialogConfirmDelete(false);
    setOpenDialogThemMoi(false);
  };
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deleteKiemKe(dataEdit);
      if (CODE.SUCCESS === res.status) {
        toast.success("Xóa phiếu thành công");
        setOpenDialogConfirmDelete(false);
        updatePageData();
      }
    } catch (error: any) {
      const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
      toast.error(message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className="text-center text-light min-w-80px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">
        <>
          <CustomIconButton
            variant="edit"
            onClick={() => {
              setDataEdit(props.cell.row.original);
              setOpenDialogThemMoi(true);
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>
          {props.cell.row.original?.color != "#28A745" && (
            <CustomIconButton
              variant="delete"
              onClick={() => {
                setDataEdit(props.cell.row.original);
                setOpenDialogConfirmDelete(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          )}
        </>
      </div>
    ),
  };
  return (
    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex justify-content-between bg-white spaces width-100 "></div>
            <div className="d-flex  mt-1 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces px-8 bg-white  align-items-center mb-6">
                  <div className="d-flex spaces mt-6">
                    <LabelRequired
                      className="spaces min-w-120"
                      label={"Kho thuốc/Vật tư"}
                    />
                  </div>
                  <div className="d-flex">
                    <AutocompleteObjectV2
                      options={listKho || []}
                      isSearchDefauilt={true}
                      name={"WmsCode"}
                      onChange={(options: any) => handleChangeSelect(options)}
                      className="autocomplete-custom-renderform mt-6 pl-5 radius spaces min-w-200 h-29"
                    />
                  </div>
                  <div className="d-flex">
                    <Button
                      className="btn-fill mt-5 ml-10 spaces min-w-90px btn btn-primary"
                      onClick={handleOpenDialogAddNew}
                    >
                      Thêm
                    </Button>
                  </div>
                </div>
                <div className="d-flex spaces bg-white align-items-center mb-6">
                  <div className="d-flex spaces mx-10 mt-6">
                    <TextField
                      label="Từ ngày"
                      name="doCreationFromDate"
                      type="datetime-local"
                      value={searchObj?.doCreationFromDate || ""}
                      className="flex"
                      labelClassName="spaces mt-4 mr-5"
                      onChange={(e: any) =>
                        handleChangeSearchData(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="d-flex spaces mr-10 mt-6">
                    <TextField
                      label="Đến ngày"
                      name="doCreationToDate"
                      type="datetime-local"
                      className="flex"
                      value={searchObj?.doCreationToDate || ""}
                      labelClassName="spaces mt-4 mr-5"
                      onChange={(e: any) =>
                        handleChangeSearchData(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className=" spaces bg-white mb-0">
                  <TableCustom<any>
                    className="spaces h-calc-vh-180"
                    data={dsVatTu}
                    columns={columnsDSVatTu}
                    columnsActions={columnsActions}
                    verticalScroll={true}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDialogThemMoi && (
        <DialogKiemKe
          dataEdit={dataEdit}
          onHide={handlerCloseDialog}
          searchObj={searchObj}
          show={openDialogThemMoi}
        />
      )}
      {openDialogConfirmDelete && (
        <ConfirmDialog
          show={openDialogConfirmDelete}
          onCloseClick={handleCloseDialogDelete}
          onYesClick={handleDelete}
          title="Xóa danh mục"
          message={`Bạn chắc chắn muốn xoá bản kiểm kê này ?`}
          yes="Xóa"
          close="Hủy"
        />
      )}
    </div>
  );
};

export default TableDSVatTu;
